<!--
 * @Author: 候怀烨
 * @Date: 2020-12-03 14:21:23
 * @LastEditTime: 2021-04-06 16:50:53
 * @LastEditors: Please set LastEditors
 * @Description: 协议赠品
 * @FilePath: \sd-vue-admin\src\views\project\movablepin\predeposit\depositactivity\components\GiveawayDing.vue
-->
<template>
  <div>
    <el-dialog
      :modal="false"
      title="协议赠品"
      :visible.sync="Giveaway"
      width="50%"
    >
      <div>
        <!-- <el-autocomplete v-model="" placeholder=""></el-autocomplete> -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="Giveaway = !Giveaway">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  export default {
    props: {
      givelist: {
        type: new Array(),
        default() {
          return new Array()
        },
      },
    },
    data() {
      return {
        Giveaway: false,
        tableData: [{}],
      }
    },
    computed: {
      table() {
        var data = [
          ...this.tableData,
          ...JSON.parse(JSON.stringify(this.givelist)),
        ]
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.tableData = data
        return this.tableData.filter((list) => {
          list.goods_id == '' || list.goods_id == undefined
        })
      },
    },
    mounted() {
      this.$forceUpdate()
    },
    methods: {},
  }
</script>

<style></style>
