<template>
  <el-dialog
    :modal="false"
    title="限定品类、品牌"
    :visible.sync="showDialog"
    width="800px"
    top="15vh"
    :close-on-click-modal="false"
    destroy-on-close
    append-to-body
  >
    <div class="orderTest-container">
      <div style="display: flex; justify-content: space-around">
        <div style="width: 262px">
          <el-table
            ref="leftTable"
            v-loading="lTableLoding"
            stripe
            :data="classList"
            border
            height="500px"
          >
            <!-- 选择框 -->
            <el-table-column type="selection" align="center" width="50">
              <!-- <template #default="{ row }">
                <el-checkbox
                  v-model="row.is_rel"
                  :true-label="1"
                  :false-label="0"
                  @change="handleClassSelect($event, row)"
                ></el-checkbox>
              </template> -->
            </el-table-column>
            <el-table-column
              prop="class_name"
              label="品类"
              align="center"
            ></el-table-column>
          </el-table>
        </div>
        <div style="width: 380px">
          <el-table
            ref="rightTable"
            v-loading="rTableLoding"
            stripe
            :data="brandList"
            border
            height="500px"
            row-key="id"
            default-expand-all
            :indent="35"
            :tree-props="{
              children: 'children',
              hasChildren: 'hasChildren',
            }"
            @select="rightTableSelect"
          >
            >
            <!-- 选择框 -->
            <el-table-column type="selection" align="center" width="50">
              <!-- <template #default="{ row }">
                <el-checkbox
                  v-model="row.is_rel"
                  :true-label="1"
                  :false-label="0"
                  @change="handleBrandSelect($event, row)"
                ></el-checkbox>
              </template> -->
            </el-table-column>
            <el-table-column prop="brand_name" label="品牌" align="center">
              <template slot="header">
                <span style="cursor: pointer">品牌</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button type="primary" @click="handleSave">保存</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { classList, brandList } from '@/api/selectList'
  import { sort } from '@/api/depotManagement'

  export default {
    name: 'SetB',
    // props: ['fatherBrandIds', 'fatherClassIds'],
    components: {},
    data() {
      return {
        hasChildren: true,
        showDialog: false,
        lTableLoding: false,
        rTableLoding: false,
        classList: [],
        brandList: [],
        brandSelectList: [],
        classSelectList: [],
        brandIds: [],
        classIds: [],
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (val) {
          this.getBrandData().then(() => {
            this.getClassData().then(() => {
              this.initSelect()
            })
          })
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      async getBrandData() {
        this.rTableLoding = true
        let { data, msg, code } = await brandList()
        if (code == 200) {
          this.brandList = data
          this.getTreeData(this.brandList)
        } else {
          this.$message.error(msg)
        }
        this.rTableLoding = false
      },
      async getClassData() {
        this.lTableLoding = true

        let { data, msg, code } = await classList()
        if (code == 200) {
          console.log(data)
          this.classList = data
        } else {
          this.$message.error(msg)
        }
        this.lTableLoding = false
      },
      handleBrandSelect(val, row) {},
      handleCurrentChange(row) {
        console.log(row)
      },
      // 递归处理空children数组
      getTreeData(arr) {
        arr.forEach((item) => {
          if (item.children.length == 0) {
            item.children = undefined
          } else {
            this.getTreeData(item.children)
          }
        })
      },
      initSelect() {
        if (this.brandIds.length != 0) {
          this.brandIds.forEach((item) => {
            this.brandList.forEach((item2) => {
              if (item2.id == item.sort_id) {
                this.selectRT(item2)
                return item2
              } else {
                if (item2.children != undefined) {
                  item2.children.forEach((cItem) => {
                    if (cItem.id == item.sort_id) {
                      this.selectRT(cItem)
                      return cItem
                    } else {
                      if (cItem.children != undefined) {
                        cItem.children.forEach((ccItem) => {
                          if (ccItem.id == item.sort_id) {
                            this.selectRT(ccItem)
                            return ccItem
                          }
                        })
                      } else {
                        return false
                      }
                    }
                  })
                } else {
                  return false
                }
              }
            })
            // 默认勾上选中过的表格item
            // if (a) {
            //   this.$refs.rightTable.toggleRowSelection(a, true)
            // }
          })
        }
        if (this.classIds.length != 0) {
          this.classIds.forEach((idsItem) => {
            let a = this.classList.filter(
              (listItem) => listItem.id == idsItem.sort_id
            )[0]
            if (a) {
              this.$refs.leftTable.toggleRowSelection(a, true)
            }
          })
        }
      },
      selectRT(row) {
        if (row) {
          this.$refs.rightTable.toggleRowSelection(row, true)
        }
      },
      rightTableSelect(select, row) {
        console.log('右表格选中', select)
      },
      getRowKey(row) {
        return row.id
      },
      handleSave() {
        let leftTableSelect = JSON.parse(
          JSON.stringify(this.$refs.leftTable.selection)
        )
        let rightTableSelect = JSON.parse(
          JSON.stringify(this.$refs.rightTable.selection)
        )
        leftTableSelect.forEach((item) => {
          this.$set(item, 'sort_name', item.class_name)
          this.$set(item, 'sort_id', item.id)
          delete item.id
        })

        rightTableSelect.forEach((item) => {
          this.$set(item, 'sort_name', item.brand_name)
          this.$set(item, 'sort_id', item.id)
          delete item.id
        })
        this.$emit('set-brand-class', { leftTableSelect, rightTableSelect })
        this.showDialog = false
      },
      close() {
        this.showDialog = false
      },
    },
  }
</script>
<style lang="scss" scoped></style>
