<template>
  <div class="orderTest-container">
    <div style="text-aling: right">
      <el-button type="primary" @click="handleAdd">添加提成方案</el-button>
    </div>
    <el-table v-loading="loading" stripe :data="list" style="margin-top: 15px">
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <!-- 选择框 -->
      <el-table-column
        align="center"
        type="selection"
        width="50"
      ></el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      ></el-table-column>
      <el-table-column
        prop=""
        align="center"
        label="操作"
        fixed="right"
        min-width="160px"
      >
        <template #default="{ $index, row }">
          <el-button
            v-if="row.status == 1"
            type="text"
            @click.native.prevent="handleEdit($index, row)"
          >
            修改
          </el-button>
          <!-- <el-button
            v-if="row.status == 1"
            type="text"
            @click.native.prevent="handleDisable($index, row)"
          >
            停用
          </el-button> -->
          <el-button
            type="text"
            @click.native.prevent="handleEnable($index, row)"
          >
            <span v-if="row.status == 2">启用</span>
            <span v-if="row.status == 1">停用</span>
          </el-button>
          <el-button
            v-if="row.status == 2"
            type="text"
            @click.native.prevent="handleCheck($index, row)"
          >
            查看
          </el-button>
          <el-button
            v-if="row.status == 1"
            type="text"
            @click.native.prevent="handleAccounting($index, row)"
          >
            提成核算
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <edit ref="edit" @refresh="fetchData"></edit>
    <check ref="check" @refresh="fetchData"></check>
  </div>
</template>
<script>
  import _ from 'lodash'
  import Edit from './components/edit'
  import Check from './components/check'
  import { commissionList, changeStatus } from '@/api/performance'
  export default {
    name: 'CommissionSummary',
    components: { Edit, Check },
    data() {
      return {
        loading: false,
        checkList: ['方案名称', '生效时间', '上次核算时间', '状态'],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        list: [],
        form: {
          pageNo: 1,
          pageSize: 10,
        },
        columns: [
          {
            order: 1,
            label: '方案名称',
            prop: 'title',
            width: '',
          },
          {
            order: 2,
            label: '生效时间',
            prop: 'update_at',
            width: '',
          },
          {
            order: 3,
            label: '上次核算时间',
            prop: 'last_at',
            width: '',
          },
          {
            order: 4,
            label: '状态',
            prop: 'status_text',
            width: '',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {},
    created() {
      this.fetchData()
    },
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, msg, totalCount } = await commissionList(this.form)
        this.list = data
        this.total = totalCount
        this.loading = false
      },
      handleAdd() {
        this.$refs.edit.id = 0
        this.$refs.edit.title = '添加提成方案'
        this.$refs.edit.isEdit = false

        this.$refs.edit.showDialog = true
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },

      // table handle
      handleEdit(index, row) {
        console.log(row)
        this.$refs.edit.id = row.comm_id
        this.$refs.edit.title = '修改提成方案'
        this.$refs.edit.isEdit = true

        this.$refs.edit.showDialog = true
      },
      handleEnable(index, row) {
        changeStatus({ comm_id: row.comm_id }).then((res) => {
          this.fetchData()
          this.$message.success(res.msg)
        })
      },
      handleCheck(index, row) {
        this.$refs.check.id = row.comm_id
        this.$refs.check.isCheck = true
        this.$refs.check.showDialog = true
      },
      handleAccounting(index, row) {
        this.$refs.check.id = row.comm_id
        this.$refs.check.isCheck = false
        this.$refs.check.showDialog = true
      },
    },
  }
</script>
<style lang="scss" scoped></style>
