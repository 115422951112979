var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "editor-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "100px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "标题", prop: "title" } },
            [
              _c("el-input", {
                attrs: { maxlength: "20" },
                model: {
                  value: _vm.form.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "title", $$v)
                  },
                  expression: "form.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "所属模块", prop: "module" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.form.module,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "module", $$v)
                    },
                    expression: "form.module",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "新闻动态", value: "1" } }),
                  _c("el-option", { attrs: { label: "实时热点", value: "2" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "vab-quill-content",
              attrs: { label: "内容", prop: "content" },
            },
            [
              _c("vab-quill", {
                attrs: { "min-height": 400, options: _vm.options },
                model: {
                  value: _vm.form.content,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "content", $$v)
                  },
                  expression: "form.content",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSee } },
                [_vm._v("预览效果")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            "close-on-click-modal": false,
            visible: _vm.dialogTableVisible,
            title: "预览效果",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisible = $event
            },
          },
        },
        [
          _c("h1", { staticClass: "news-title" }, [
            _vm._v(_vm._s(_vm.form.title)),
          ]),
          _c("div", {
            staticClass: "news-content",
            domProps: { innerHTML: _vm._s(_vm.form.content) },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }