<template>
  <div class="orderTest-container">
    <div class="tip">
      <i class="el-icon-question"></i>
      <span style="margin-left: 5px">什么是分组</span>
    </div>
    <div class="input">
      <el-form inline :model="form">
        <el-form-item prop="name">
          <el-input
            v-model="form.name"
            placeholder="商品名称、商品分组名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="form.status"
            placeholder="请选择状态"
            :popper-class="'select-idx'"
          >
            <el-option
              v-for="item in statusList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="query">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="add">添加分组</el-button>
        </el-form-item>
      </el-form>
      <div class="table-wrapper">
        <el-table
          stripe
          :data="list"
          style="width: 100%; margin-top: 10px"
          row-key="id"
        >
          >
          <!-- 序号 -->
          <el-table-column
            align="center"
            type="index"
            width="50"
          ></el-table-column>
          <!-- 选择框 -->
          <el-table-column
            align="center"
            type="selection"
            width="50"
          ></el-table-column>
          <el-table-column
            v-for="(colItem, index) in finallyColumns"
            :key="index"
            :width="colItem.width"
            :prop="colItem.prop"
            :label="colItem.label"
            show-overflow-tooltip
            align="center"
          >
            <!-- 排序 -->
            <template v-if="colItem.label === '排序'" #default="{ row }">
              <!-- v-if="row.pid == 0" -->
              <el-input
                v-model.trim="row.sort"
                @change="blur_(row.id, row.sort)"
              ></el-input>
            </template>
            <template v-else #default="{ row }">
              <span>{{ row[colItem.prop] }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="120"
            align="center"
            fixed="right"
          >
            <template #default="{ $index, row }">
              <el-button type="text" @click="edit($index, row)">编辑</el-button>
              <el-button type="text" @click="edit($index, row)">停用</el-button>
              <el-button type="text" @click="edit($index, row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'
  export default {
    name: 'GoodsGroup',
    data() {
      return {
        form: {
          name: '',
          status: '',
        },
        statusList: [{}],
        // 表格
        list: [
          {
            name: '111',
            status: '222',
          },
        ],
        checkList: ['排序', '商品分组名称', '关联商品', '状态', '备注'],
        columnList: [
          {
            order: 0,
            prop: 'sort',
            label: '排序',
            width: '70px',
            type: '',
          },
          {
            order: 1,
            width: '150px',
            prop: 'name',
            label: '商品分组名称',
          },
          {
            order: 2,
            width: 'auto',
            prop: 'goods',
            label: '关联商品',
          },
          {
            order: 3,
            width: '110px',
            prop: 'status',
            label: '状态',
          },
          {
            order: 4,
            width: '160px',
            prop: 'remark',
            label: '备注',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let list = []
        this.checkList.forEach((checkItem) => {
          list.push(
            this.columnList.filter((item) => item.label == checkItem)[0]
          )
        })
        return _.sortBy(list, (item) => item.order)
      },
    },
    methods: {
      query() {},
      edit() {},
      add() {},
      // 排序输入框失去焦点 排序
      blur_(rowId, rowSort) {
        // refreshSort({
        //   id: rowId,
        //   sort: rowSort,
        // }).then((res) => {
        //   if (res.code == 200) {
        //     this.$message.success('排序成功')
        //     this.fetchData()
        //   }
        // })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .tip {
    font-size: 16px;
    text-decoration: underline;
  }
  .input {
    margin: 10px 0;
  }
</style>
