var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dynamic-title-container" },
    [
      _c(
        "el-button",
        {
          on: {
            click: function ($event) {
              return _vm.handleTitle(_vm.defaultTitle, "vab-demo")
            },
          },
        },
        [_vm._v(" 标题变更为 vab-demo ")]
      ),
      _c(
        "el-button",
        {
          on: {
            click: function ($event) {
              return _vm.handleTitle("vab-demo", _vm.defaultTitle)
            },
          },
        },
        [_vm._v(" 还原为默认标题 ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }