<template>
  <div class="wrapper">
    <el-form inline>
      <el-form-item>
        <el-select v-model="a" placeholder="预存货活动" style="width: 140px">
          <el-option
            v-for="item in alist"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="b" placeholder="打印状态" style="width: 140px">
          <el-option
            v-for="item in blist"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-autocomplete
          v-model="search"
          class="inline-input"
          :fetch-suggestions="querySearch"
          placeholder="存货商品"
          style="width: 140px"
          @select="handleSelect"
        ></el-autocomplete>
        <el-button
          icon="el-icon-search"
          circle
          class="icon-btn"
          @click="iconClick"
        ></el-button>
      </el-form-item>
      <el-form-item>
        <el-autocomplete
          v-model="search"
          class="inline-input"
          :fetch-suggestions="querySearch"
          placeholder="客户名称、简拼"
          style="width: 170px"
          @select="handleSelect"
        ></el-autocomplete>
        <el-button
          icon="el-icon-search"
          circle
          class="icon-btn"
          @click="iconClick"
        ></el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button>导出</el-button>
      </el-form-item>
    </el-form>

    <div class="table-wrapper">
      <!-- <div class="setFilter">
        <el-popover popper-class="custom-table-checkbox" trigger="hover">
          <el-checkbox-group v-model="checkList">
            <el-checkbox
              v-for="(item, index) in columns"
              :key="index"
              :label="item.label"
            ></el-checkbox>
          </el-checkbox-group>
          <el-button slot="reference">
            <vab-remix-icon icon="settings-line" />
          </el-button>
        </el-popover>
      </div> -->
      <el-table stripe :data="list">
        <!-- 序号 -->
        <el-table-column align="center" type="index" width="50">
          <template slot="header">
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <el-checkbox
                  v-for="(item, index) in columns"
                  :key="index"
                  :label="item.label"
                ></el-checkbox>
              </el-checkbox-group>
              <el-button slot="reference" type="text">
                <vab-remix-icon icon="settings-line" />
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
        <!-- 选择框 -->
        <el-table-column
          align="center"
          type="selection"
          width="50"
        ></el-table-column>
        <el-table-column
          v-for="(item, index) in finallyColumns"
          :key="index"
          align="center"
          width=""
          :label="item.label"
          :prop="item.prop"
        ></el-table-column>
        <el-table-column
          align="center"
          label="操作"
          fixed="right"
          show-overflow-tooltip
          width="190"
        >
          <template #default="{ $index, row }">
            <el-button type="text" @click="handleCheck($index, row)">
              查看
            </el-button>
            <el-button type="text" @click="handleReturnBack($index, row)">
              还货
            </el-button>
            <el-button type="text" @click="handleStop($index, row)">
              终止
            </el-button>
            <el-button type="text" @click="handlePrint($index, row)">
              打印
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <check ref="check"></check>
  </div>
</template>

<script>
  import _ from 'lodash'
  import Check from './components/check'
  export default {
    name: 'InventoryOrder',
    components: {
      Check,
    },
    data() {
      return {
        a: '',
        b: '',
        search: '',
        alist: [{}],
        blist: [{}],
        // 表格
        checkList: [
          '客户',
          '单号',
          '业务员',
          '签单金额',
          '已收款',
          '余货商品',
          '存货时间',
          '打印次数',
          '备注',
        ],
        columns: [
          {
            order: 1,
            label: '客户',
            width: '110',
            prop: 'cust_name',
            sortable: false,
          },
          {
            order: 2,
            label: '单号',
            width: '150',
            prop: 'bill_code',
            sortable: false,
          },

          {
            order: 4,
            label: '已收款',
            width: '100',
            prop: 'bill_type_text',
            sortable: false,
          },
          {
            order: 5,
            label: '签单金额',
            width: '100',
            prop: 'depot_name',
            sortable: false,
          },
          {
            order: 6,
            label: '业务员',
            width: '90',
            prop: 'staff_name',
            sortable: false,
          },
          {
            order: 7,
            label: '余货商品',
            width: '90',
            prop: 'deliver_name',
            sortable: false,
          },
          {
            order: 7.5,
            label: '存货时间',
            width: '90',
            prop: 'deliver_name',
            sortable: false,
          },
          {
            order: 8,
            label: '打印次数',
            width: '100',
            prop: 'total_amount',
            sortable: false,
          },
          {
            order: 9,
            label: '备注',
            width: '100',
            prop: 'sale_amount',
            sortable: false,
          },
        ],
        list: [{}],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        console.log(finallyArray)
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    methods: {
      async querySearch(val, cb) {
        let selectData = this.selectRes.data.client
        // let clientRes = await request({
        //   url: '/advance/clientSelect',
        //   method: 'get',
        // })
        // this.selectRes = clientRes.data.client
        console.log(selectData)
        var results = val
          ? selectData.filter(this.createStateFilter(val))
          : selectData
        cb(results)
      },
      createStateFilter(queryString) {
        return (state) => {
          return (
            state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
          )
        }
      },
      // client btn click
      iconClick() {
        console.log('click search')
      },
      handleSelect(val, e) {
        console.log(val)
      },
      // 表格行内按钮
      handleCheck(index, row) {
        console.log('查看')
        console.log(index, row)
      },
      handleReturnBack(index, row) {
        console.log('还货')
        console.log(index, row)
      },
      handleStop(index, row) {
        console.log('终止')
        console.log(index, row)
      },
      handlePrint(index, row) {
        console.log('打印')
        console.log(index, row)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .icon-btn {
    position: absolute;
    top: 2px;
    right: 1px;
    border: 0px solid transparent;
  }
</style>
