<!--
 * @Author: 候怀烨
 * @Date: 2020-12-29 18:24:07
 * @LastEditTime: 2020-12-30 16:07:39
 * @LastEditors: Please set LastEditors
 * @Description: 单据详情
 * @FilePath: \sd-vue-admin\src\views\project\sale\control\sale\components\components\DocumentDetails.vue
-->
<template>
  <div>
    <h5>{{ list.receiv_bk[0].type_text }}</h5>
    <el-table stripe :data="list.receiv_bk[0].list" style="width: 50%">
      <el-table-column
        label="客户名称"
        align="center"
        prop="cust_name"
      ></el-table-column>
      <el-table-column
        label="单号"
        align="center"
        prop="order_ori_code"
      ></el-table-column>
      <el-table-column
        label="应收"
        align="center"
        prop="receiv_money"
      ></el-table-column>
      <el-table-column
        label="收款"
        align="center"
        prop="real_receiv_money"
      ></el-table-column>
    </el-table>
    <h5>{{ list.receiv_bk[1].type_text }}</h5>
    <el-table stripe :data="list.receiv_bk[1].list" style="width: 50%">
      <el-table-column
        label="客户名称"
        align="center"
        prop="cust_name"
      ></el-table-column>
      <el-table-column
        label="单号"
        align="center"
        prop="order_ori_code"
      ></el-table-column>
      <el-table-column
        label="应收"
        align="center"
        prop="receiv_money"
      ></el-table-column>
      <el-table-column
        label="收款"
        align="center"
        prop="real_receiv_money"
      ></el-table-column>
    </el-table>
    <h5>{{ list.receiv_bk[2].type_text }}</h5>
    <el-table stripe :data="list.receiv_bk[2].list" style="width: 50%">
      <el-table-column
        label="客户名称"
        align="center"
        prop="cust_name"
      ></el-table-column>
      <el-table-column
        label="单号"
        align="center"
        prop="order_ori_code"
      ></el-table-column>
      <el-table-column
        label="应收"
        align="center"
        prop="receiv_money"
      ></el-table-column>
      <el-table-column
        label="收款"
        align="center"
        prop="real_receiv_money"
      ></el-table-column>
    </el-table>
    <h5>{{ list.receiv_bk[3].type_text }}</h5>
    <el-table stripe :data="list.receiv_bk[3].list" style="width: 50%">
      <el-table-column
        label="客户名称"
        align="center"
        prop="cust_name"
      ></el-table-column>
      <el-table-column
        label="单号"
        align="center"
        prop="order_ori_code"
      ></el-table-column>
      <el-table-column
        label="应收"
        align="center"
        prop="receiv_money"
      ></el-table-column>
      <el-table-column
        label="收款"
        align="center"
        prop="real_receiv_money"
      ></el-table-column>
    </el-table>
  </div>
</template>

<script>
  export default {
    props: {
      list: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        shoukuan: [],
      }
    },
    methods: {},
  }
</script>

<style></style>
