var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: _vm.title,
        center: "",
        visible: _vm.showadd,
        "close-on-click-modal": false,
        width: "800px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showadd = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "120px",
                inline: "",
                "label-suffix": "：",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户", prop: "cust_id" } },
                [
                  _c("client-search", {
                    attrs: {
                      keyword: _vm.form.keyword,
                      "popper-class": "selectDC",
                    },
                    on: {
                      "select-id": _vm.selectId,
                      "select-val-input": _vm.custInput,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "供应商", prop: "supp_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "220px" },
                      attrs: { placeholder: "供应商", clearable: "" },
                      model: {
                        value: _vm.form.supp_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "supp_id", $$v)
                        },
                        expression: "form.supp_id",
                      },
                    },
                    _vm._l(_vm.supplier, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.supp_name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "日期", prop: "funds_date" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      "value-format": "yyyy-MM-dd",
                      placeholder: "选择日期",
                    },
                    model: {
                      value: _vm.form.funds_date,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "funds_date", $$v)
                      },
                      expression: "form.funds_date",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "科目", prop: "funds_type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "220px" },
                      attrs: { placeholder: "销售费用科目", clearable: "" },
                      model: {
                        value: _vm.form.funds_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "funds_type", $$v)
                        },
                        expression: "form.funds_type",
                      },
                    },
                    _vm._l(_vm.department, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "结算方式", prop: "settle_type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "220px" },
                      attrs: { placeholder: "结算方式", clearable: "" },
                      model: {
                        value: _vm.form.settle_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "settle_type", $$v)
                        },
                        expression: "form.settle_type",
                      },
                    },
                    _vm._l(_vm.Typesof, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.form.settle_type == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "结算账户", prop: "account_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "220px" },
                          attrs: { placeholder: "结算账户", clearable: "" },
                          model: {
                            value: _vm.form.account_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "account_id", $$v)
                            },
                            expression: "form.account_id",
                          },
                        },
                        _vm._l(_vm.account, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "金额", prop: "money" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    attrs: { placeholder: "请输入金额" },
                    model: {
                      value: _vm.form.money,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "money", $$v)
                      },
                      expression: "form.money",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "经办人", prop: "user_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "220px" },
                      attrs: { placeholder: "经办人", clearable: "" },
                      model: {
                        value: _vm.form.user_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "user_id", $$v)
                        },
                        expression: "form.user_id",
                      },
                    },
                    _vm._l(_vm.areaList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.user_name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "530px" },
                    attrs: { placeholder: "备注", type: "textarea", rows: 2 },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.type == 3
            ? _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.check_log },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "type_text",
                      label: "操作记录",
                      width: "180",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "amount", label: "金额", width: "180" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "user_name", label: "操作人" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "create_at", label: "时间" },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showadd = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _vm.type == 1
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
                [_vm._v(" 确 定 ")]
              )
            : _vm._e(),
          _vm.type == 2
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleremsave },
                },
                [_vm._v(" 确 定 ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }