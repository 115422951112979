<!--
 * @Author: your name
 * @Date: 2021-02-18 14:44:55
 * @LastEditTime: 2021-02-18 14:55:08
 * @LastEditors: Please set LastEditors
 * @Description: 批量付款
 * @FilePath: \sd-vue-admin\src\views\project\finance\supplier\payable\components\BulkPayment.vue
-->
<template>
  <div>
    <el-dialog :modal="false" :visible.sync="receipt" title="批量付款" center>
      <div>
        <el-form>
          <el-form-item label="剩余应付款" prop="arrears_amount">
            <el-input
              v-model="form.arrears_amount"
              disabled
              placeholder="	剩余应付款"
              style="width: 220px"
            ></el-input>
          </el-form-item>
          <el-table stripe :data="tableData">
            <el-table-column
              v-for="(item, index) in celund"
              :key="index"
              :label="item.label"
              :align="item.align"
              :prop="item.prop"
            ></el-table-column>
          </el-table>
          <el-form-item
            v-show="showpay == '1'"
            label="本次付款"
            prop="pay_amount"
          >
            <el-input
              v-model="form.pay_amount"
              placeholder="	本次付款"
              style="width: 220px"
            ></el-input>
            <el-select v-model="form.user_id" placeholder="请选择付款人">
              <el-option
                v-for="list in userdata"
                :key="list.user_id"
                :label="list.user_name"
                :value="list.user_id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-show="showpay == '1'"
            label="付款账户"
            prop="create_at"
          >
            <el-select v-model="form.account_id" placeholder="请选择付款账户">
              <el-option
                v-for="list in account"
                :key="list.id"
                :label="list.name"
                :value="list.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-show="showpay == '1'" label="备注" prop="remark">
            <el-input
              v-model="form.remark"
              type="textarea"
              placeholder="备注"
              style="width: 420px"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          v-show="showpay == '1'"
          type="primary"
          @click="receipt = !receipt"
        >
          保 存
        </el-button>
        <el-button @click="receipt = !receipt">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        receipt: false,
        account: [],
        userdata: [],
        url: {
          supptable: '/cashAdmin/supp/payment-add-list',
          userlist: '/baseAdmin/common/user-list',
          account: '/baseAdmin/common/account',
          info: '/cashAdmin/supp/arrears-detail',
        },
      }
    },
    mounted() {
      this.hanlderuserlist()
      this.handleraccount()
    },
    methods: {
      // 收款账户
      handleraccount() {
        postAction(this.url.account, {})
          .then((res) => {
            console.log(res, '收款账户')
            this.account = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },

      //收款人
      hanlderuserlist() {
        postAction(this.url.userlist, {})
          .then((res) => {
            console.log('收款人', res)
            this.userdata = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
    },
  }
</script>

<style></style>
