var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.easyFlowVisible
    ? _c(
        "div",
        { staticStyle: { "min-width": "1500px", overflow: "hidden" } },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  { staticClass: "vab-tooltar" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { icon: "el-icon-plus" },
                        on: { click: _vm.zoomAdd },
                      },
                      [_vm._v("放大")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { icon: "el-icon-minus" },
                        on: { click: _vm.zoomSubtract },
                      },
                      [_vm._v("缩小")]
                    ),
                    _c(
                      "el-button",
                      { on: { click: _vm.downloadData } },
                      [
                        _c("vab-remix-icon", {
                          attrs: { icon: "download-2-line" },
                        }),
                        _vm._v(" 下载 "),
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { disabled: !_vm.activeElement.type },
                        on: { click: _vm.deleteElement },
                      },
                      [
                        _c("vab-remix-icon", {
                          attrs: { icon: "delete-bin-4-line" },
                        }),
                        _vm._v(" 删除 "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                height: "calc(100vh - 300px)",
                overflow: "hidden",
              },
            },
            [
              _c("node-menu", {
                ref: "nodeMenu",
                on: { "add-node": _vm.addNode },
              }),
              _c(
                "div",
                {
                  directives: [{ name: "flowDrag", rawName: "v-flowDrag" }],
                  ref: "efContainer",
                  staticClass: "container",
                  staticStyle: { overflow: "hidden" },
                  attrs: { id: "efContainer" },
                },
                [
                  _vm._l(_vm.data.nodeList, function (node) {
                    return [
                      _c("node", {
                        key: node.id,
                        attrs: {
                          id: node.id,
                          node: node,
                          "active-element": _vm.activeElement,
                        },
                        on: {
                          "change-node-site": _vm.changeNodeSite,
                          nodeRightMenu: _vm.nodeRightMenu,
                          "click-node": _vm.clickNode,
                        },
                      }),
                    ]
                  }),
                ],
                2
              ),
              _c(
                "div",
                { staticStyle: { "padding-top": "20px" } },
                [
                  _c("node-form", {
                    ref: "nodeForm",
                    on: {
                      "set-line-label": _vm.setLineLabel,
                      repaintEverything: _vm.repaintEverything,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }