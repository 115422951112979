<template>
  <el-dialog
    :modal="false"
    :title="title"
    :visible.sync="showDialog"
    width="1000px"
    top="5vh"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div v-loading="loading" class="orderTest-container">
      <el-form
        ref="form"
        :model="form"
        inline
        label-position="right"
        label-width="80px"
      >
        <div style="margin-left: 20px">
          <el-form-item label="方案名称:" prop="title">
            <el-input v-model="form.title" style="width: 200px"></el-input>
          </el-form-item>

          <br />
          <el-form-item label="提成规则:" prop="type">
            <el-select v-model="form.type" clearable placeholder="提成规则">
              <el-option
                v-for="(i, idx) in rulesList"
                :key="idx"
                :value="i.id"
                :label="i.name"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-table stripe :data="form.scheme_list">
          <el-table-column
            v-for="(item, tableIndex) in finallyColumns"
            :key="tableIndex"
            :prop="item.prop"
            :label="item.label"
            width=""
            align="center"
          >
            <template #default="{ row, $index }">
              <div v-if="item.prop == 'a'">
                <span>{{ row.more }}</span>
                &lt;数值&lt;
                <span>{{ row.less }}</span>
                <i
                  class="el-icon-edit-outline"
                  style="margin-left: 10px; font-size: 15px; cursor: pointer"
                  @click="changeNum(row, $index)"
                ></i>
              </div>
              <div v-else>
                <el-input v-model="row[item.prop]"></el-input>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template #default="{ $index, row }">
              <el-button
                type="text"
                @click.native.prevent="handleAddRow($index, row)"
              >
                添加
              </el-button>
              <el-button
                type="text"
                @click.native.prevent="handleDelete($index, row)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <div class="list">
        <div class="df">
          <div style="width: 150px">限定品类</div>
          <div style="width: 100%">
            <span
              v-for="(item, index) in form.class_list"
              :key="index"
              style="margin-right: 5px"
            >
              {{ item.sort_name }}
            </span>
          </div>
          <div style="width: 250px">
            <el-button type="text" @click="handleSetB">设置</el-button>
          </div>
        </div>
        <div class="df" style="border-top: 1px solid #ebeef5">
          <div style="width: 150px">限定品牌</div>
          <div style="width: 100%">
            <span v-for="(item, index) in form.brand_list" :key="index">
              {{ item.sort_name }}
            </span>
          </div>
          <div style="width: 250px">
            <el-button type="text" @click="handleSetB">设置</el-button>
          </div>
        </div>
      </div>
      <div v-if="form.user_list != undefined" style="margin-top: 20px">
        <span>限定员工:</span>
        <span style="margin: 0 10px; text-decoration: underline">
          {{ form.user_list.length }}
        </span>
        <span>
          <el-button type="primary" @click="setStaff">设置</el-button>
        </span>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
        style="margin-top: 15px; text-align: right"
      >
        <el-button type="primary" @click="handleSave">保存</el-button>
        <el-button @click="close">取 消</el-button>
      </div>
    </div>
    <el-dialog
      :modal="false"
      title="数值范围"
      :visible.sync="showDialog2"
      width="400px"
      top="18vh"
      :close-on-click-modal="false"
      destroy-on-close
      append-to-body
    >
      <div class="orderTest-container">
        <el-form
          ref="form"
          :model="form2"
          inline
          label-position="right"
          label-width="70px"
        >
          <el-form-item label="大于" prop="">
            <el-input v-model="form2.da"></el-input>
          </el-form-item>
          <el-form-item label="小于" prop="">
            <el-input v-model="form2.xiao"></el-input>
          </el-form-item>
        </el-form>

        <div
          slot="footer"
          class="dialog-footer"
          style="margin-top: 5px; text-align: right"
        >
          <el-button type="primary" @click="handleSaveNum">保存</el-button>
          <el-button @click="close2">取 消</el-button>
        </div>
      </div>
    </el-dialog>
    <set-b ref="setB" @set-brand-class="setBC"></set-b>
    <set-staff
      ref="setStaff"
      :zidingyi="false"
      :showbumen="false"
      :showzhineng="true"
      :title="'绑定员工'"
      @rtd="setStaffList"
    ></set-staff>
  </el-dialog>
</template>
<script>
  import _ from 'lodash'
  import { commissionDetail, commissionAdd } from '@/api/performance'
  import SetB from './setB.vue'
  // 限定业务员
  import SetStaff from '@/baseComponents/setStaff'

  export default {
    name: 'Edit',
    components: { SetB, SetStaff },
    data() {
      return {
        id: null,
        isEdit: false,
        loading: false,
        title: '标题',
        showDialog: false,
        showDialog2: false,
        activeRow: null,
        form2: {
          da: '',
          xiao: '',
        },
        // 提成规则1按销售2按实收3按件数4按利润
        rulesList: [
          {
            id: 1,
            name: '按销售额提成',
          },
          {
            id: 2,
            name: '按实收额提成',
          },
          {
            id: 3,
            name: '按件数提成',
          },
          {
            id: 4,
            name: '按毛利提成',
          },
        ],
        form: {
          scheme_list: [],
          class_list: [],
          brand_list: [],
          user_list: [],
        },
        list: [],
        checkList: [
          '数值范围',
          '销售提成（%）',
          '退货提成（%）',
          '送货提成（%）',
        ],
        columns: [
          {
            order: 1,
            label: '数值范围',
            prop: 'a',
            width: '',
          },
          {
            order: 2,
            label: '销售提成（%）',
            prop: 'sale_money',
            width: '',
          },
          {
            order: 3,
            label: '退货提成（%）',
            prop: 'back_money',
            width: '',
          },
          {
            order: 4,
            label: '送货提成（%）',
            prop: 'deliver_money',
            width: '',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      showDialog(val) {
        if (val) {
          if (this.id) {
            this.fetchData()
          } else {
            // 添加
            this.addEmptyRow()
          }
        } else {
          this.clearForm()
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data } = await commissionDetail({ comm_id: this.id })
        console.log(data)
        this.form = data
        this.loading = false
      },
      clearForm() {
        this.form = {
          scheme_list: [],
          class_list: [],
          brand_list: [],
          user_list: [],
        }
      },
      handleAddRow(index, row) {
        console.log(index)
        this.form.scheme_list.splice(index + 1, 0, {
          more: 0,
          less: 0,
          sale_money: 0,
          back_money: 0,
          deliver_money: 0,
        })
      },
      handleDelete(index, row) {
        if (this.form.scheme_list.length == 1) {
          this.$message.error('不能删除最后一行')
        } else {
          this.form.scheme_list.splice(index, 1)
        }
      },
      handleSaveNum() {
        if (this.activeRow !== null) {
          this.form2.da = Number(this.form2.da)
          this.form2.xiao = Number(this.form2.xiao)
          console.log(this.form2.da, this.form2.xiao)
          if (
            this.form2.da > this.form2.xiao ||
            this.form2.da == this.form2.xiao
          ) {
            this.$message.error('数值填写错误')
          } else {
            this.form.scheme_list[this.activeRow].more = this.form2.da
            this.form.scheme_list[this.activeRow].less = this.form2.xiao
            this.close2()
          }
        }
      },
      addEmptyRow() {
        this.form.scheme_list.push({
          more: '',
          less: '',
          sale_money: '',
          back_money: '',
          deliver_money: '',
        })
      },
      close() {
        this.showDialog = false
      },
      close2() {
        this.showDialog2 = false
      },
      changeNum(row, index) {
        this.form2.da = Number(row.more)
        this.form2.xiao = Number(row.less)
        this.activeRow = index
        console.log(this.activeRow)
        this.showDialog2 = true
      },
      // 子组件传过来的ids
      setBC(val) {
        let { leftTableSelect, rightTableSelect } = val
        console.log('setBC', leftTableSelect, rightTableSelect)
        this.form.class_list = leftTableSelect
        this.form.brand_list = rightTableSelect
        // leftTableSelect.forEach((item) => {
        //   this.form.class_list.push(item)
        // })
        // rightTableSelect.forEach((item) => {
        //   this.form.brand_list.push(item)
        // })
      },
      handleSetB() {
        this.$refs.setB.brandIds = this.form.brand_list
        this.$refs.setB.classIds = this.form.class_list
        this.$refs.setB.showDialog = true
      },
      setStaffList(val) {
        console.log('chuanguolaile', val)
        this.form.user_list = JSON.parse(JSON.stringify(val))
      },
      // 设置限定员工
      setStaff() {
        this.$refs.setStaff.rTableData = JSON.parse(
          JSON.stringify(this.form.user_list)
        )
        this.$refs.setStaff.showDialog = true
      },
      // 保存
      handleSave() {
        console.log(this.isEdit)
        console.log(this.form)
        let form = JSON.parse(JSON.stringify(this.form))
        form.brand_list.forEach((item) => {
          this.$set(item, 'brand_id', item.sort_id)
          this.$set(item, 'brand_name', item.sort_name)
        })
        form.class_list.forEach((item) => {
          this.$set(item, 'class_id', item.sort_id)
          this.$set(item, 'class_name', item.sort_name)
        })
        let endData = {}
        if (this.isEdit) {
          endData.comm_id = form.id
        }
        endData.title = form.title
        endData.type = form.type
        endData.scheme_data = form.scheme_list
        endData.brand_data = form.brand_list
        endData.class_data = form.class_list
        endData.user_data = form.user_list

        console.log(endData)
        commissionAdd(endData).then((res) => {
          this.showDialog = false
          this.$emit('refresh')
          this.$message.success(res.msg)
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .list {
    margin-top: 35px;
    border: 1px solid #ebeef5;
  }
  .df {
    display: flex;
    justify-content: space-between;
    line-height: 40px;
    text-align: center;
    &:hover {
      background: #f5f7fa;
    }
  }
</style>
