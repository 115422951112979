<template>
  <div class="index-container">
    <el-button style="margin-bottom: 20px" @click="handleradd">添加</el-button>
    <el-row>
      <el-table ref="table" stripe :data="tableData">
        <el-table-column
          v-for="(list, index) in Columns"
          :key="index"
          :label="list.label"
          :align="list.align"
          :prop="list.prop"
          width=""
        >
          <template #default="{ row }">
            <div v-if="list.label == '是否显示'">
              <span v-if="row[list.prop] == 1">是</span>
              <span v-else>否</span>
            </div>
            <div v-else>{{ row[list.prop] }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          fixed="right"
          width="160px"
        >
          <template #default="{ row }">
            <el-popconfirm title="确定要删除吗？" @confirm="handlerstorp(row)">
              <el-button slot="reference" style="margin-left: 10px" type="text">
                删除
              </el-button>
            </el-popconfirm>
            <el-button
              style="margin-left: 10px"
              type="text"
              @click="handleprint(row)"
            >
              编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row type="flex" class="row-bg" justify="end">
      <el-pagination
        background
        :current-page="form.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </el-row>
    <add ref="add" @getlist="handlerlist" />
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  import Add from './components/Add'

  export default {
    name: 'Index',
    components: { Add },
    data() {
      return {
        form: {
          pageNo: 1,
          pageSize: 10,
        },
        total: 0,
        tableData: [],
        Columns: [
          {
            order: 1,
            label: '数据ID',
            align: 'center',
            prop: 'id',
            width: '',
          },
          {
            order: 2,
            label: '名称',
            align: 'center',
            prop: 'name',
            width: '',
          },
          {
            order: 3,
            label: '排列',
            align: 'center',
            prop: 'sort_order',
            width: '',
          },
          {
            order: 4,
            label: '是否显示',
            align: 'center',
            prop: 'app_display',
            width: '',
          },
        ],
      }
    },
    mounted() {
      this.handlerlist()
    },
    methods: {
      handleradd() {
        this.$refs.add.showadd = true
        this.$refs.add.type = 1
      },
      handleprint(row) {
        this.$refs.add.showadd = true
        this.$refs.add.type = 2
        this.$refs.add.handlerinfo(row)
      },
      handlerstorp(row) {
        postAction('/cashAdmin/subject/delete', { id: row.id }).then((res) => {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.handlerlist()
        })
      },
      handlerlist() {
        postAction('/cashAdmin/subject/funds-list', {})
          .then((res) => {
            console.log(res, 'tablelie')
            this.tableData = res.data

            this.total = Number(res.totalCount)
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`)
        this.form.pageSize = val
        this.handlerlist()
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`)
        this.form.pageNo = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
