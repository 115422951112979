var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "loading-container" },
    [
      _c("el-divider", { attrs: { "content-position": "left" } }, [
        _vm._v("传统loading"),
      ]),
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              return _vm.handleLoading()
            },
          },
        },
        [_vm._v("默认效果")]
      ),
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              return _vm.handleLoading(1)
            },
          },
        },
        [_vm._v("效果1")]
      ),
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              return _vm.handleLoading(2)
            },
          },
        },
        [_vm._v("效果2")]
      ),
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              return _vm.handleLoading(3)
            },
          },
        },
        [_vm._v("效果3")]
      ),
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              return _vm.handleLoading(4)
            },
          },
        },
        [_vm._v("效果4")]
      ),
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              return _vm.handleLoading(5)
            },
          },
        },
        [_vm._v("效果5")]
      ),
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              return _vm.handleLoading(6)
            },
          },
        },
        [_vm._v("效果6")]
      ),
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              return _vm.handleLoading(7)
            },
          },
        },
        [_vm._v("效果7")]
      ),
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              return _vm.handleLoading(8)
            },
          },
        },
        [_vm._v("效果8")]
      ),
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              return _vm.handleLoading(9)
            },
          },
        },
        [_vm._v("效果9")]
      ),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("el-divider", { attrs: { "content-position": "left" } }, [
        _vm._v("多彩loading"),
      ]),
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              return _vm.handleColorfullLoading(1)
            },
          },
        },
        [_vm._v(" 效果1 ")]
      ),
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              return _vm.handleColorfullLoading(2)
            },
          },
        },
        [_vm._v(" 效果2 ")]
      ),
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              return _vm.handleColorfullLoading(3)
            },
          },
        },
        [_vm._v(" 效果3 ")]
      ),
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              return _vm.handleColorfullLoading(4)
            },
          },
        },
        [_vm._v(" 效果4 ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }