<!--
 * @Author: your name
 * @Date: 2021-01-29 16:27:17
 * @LastEditTime: 2021-01-29 16:33:27
 * @LastEditors: Please set LastEditors
 * @Description: 权限设置
 * @FilePath: \sd-vue-admin\src\views\setting\featuresManagement\rolesSetting\components\roles.vue
-->
<template>
  <div>
    <el-dialog
      :modal="false"
      :visible.sync="roles"
      title="权限设置"
      center
      width="80%"
    >
      <div></div>
      <div slot="footer" class="dialog-footer">
        <el-button v-show="info == 1" type="primary" @click="handlersave">
          保 存
        </el-button>
        <el-button v-show="info == 2" type="primary" @click="handlerinfo">
          保 存
        </el-button>
        <el-button @click="showcoupon = !showcoupon">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        roles: false,
      }
    },
  }
</script>

<style></style>
