var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            "close-on-click-modal": false,
            title: "选择商品",
            top: "15vh",
            visible: _vm.tableInputWindow,
            width: "1050px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.tableInputWindow = $event
            },
            close: _vm.close,
          },
        },
        [
          _c("el-input", {
            staticStyle: { width: "250px", margin: "5px 10px 5px 0" },
            attrs: { placeholder: "输入关键字进行查询" },
            model: {
              value: _vm.filterText,
              callback: function ($$v) {
                _vm.filterText = $$v
              },
              expression: "filterText",
            },
          }),
          _c(
            "el-button",
            {
              staticStyle: { "margin-right": "10px" },
              on: { click: _vm.filter },
            },
            [_vm._v("查询")]
          ),
          _c(
            "el-checkbox",
            {
              model: {
                value: _vm.checked,
                callback: function ($$v) {
                  _vm.checked = $$v
                },
                expression: "checked",
              },
            },
            [_vm._v("只显示有库存商品")]
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "tit" }, [_vm._v("商品类别")]),
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c("el-tree", {
                      ref: "tree",
                      staticClass: "filter-tree",
                      attrs: {
                        data: _vm.data,
                        props: _vm.defaultProps,
                        "default-expand-all": "",
                        "filter-node-method": _vm.filterNode,
                      },
                      on: { "node-click": _vm.treeClick },
                    }),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 18 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-table",
                      {
                        attrs: { stripe: "", data: _vm.list, height: "310" },
                        on: {
                          "selection-change": _vm.setSelectRows,
                          "cell-click": _vm.cellClick,
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            type: "selection",
                            width: "60",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "",
                            align: "center",
                            label: "序号",
                            "min-width": "50px",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (ref) {
                                var $index = ref.$index
                                return [
                                  _c("span", [_vm._v(_vm._s($index + 1))]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "goods_name",
                            align: "center",
                            label: "商品名称",
                            "min-width": "auto",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "code",
                            align: "center",
                            label: "小单位条码",
                            "min-width": "120px",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "classes",
                            align: "center",
                            label: "类别",
                            "min-width": "auto",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "brand",
                            align: "center",
                            label: "品牌",
                            "min-width": "auto",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "inventory",
                            align: "center",
                            label: "可用库存",
                            "min-width": "auto",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "units",
                            align: "center",
                            label: "单位换算",
                            "min-width": "auto",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "status",
                            align: "center",
                            label: "状态",
                            "min-width": "auto",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确认")]
              ),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }