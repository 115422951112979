<template>
  <div>
    <el-form ref="form" :model="form" inline>
      <el-form-item label="" prop="cust_id">
        <client-search
          ref="clientSearch"
          :popper-class="'selectDC'"
          @select-id="clientSelect"
        ></client-search>
      </el-form-item>
      <el-form-item label="" prop="staff_id">
        <el-select
          v-model="form.staff_id"
          clearable
          placeholder="请选择业务员"
          style="width: 140px"
        >
          <el-option
            v-for="item in staffList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="model_id">
        <el-select
          v-model="form.model_id"
          clearable
          style="width: 140px"
          placeholder="选择陈列活动"
        >
          <el-option
            v-for="(i, idx) in activeSelect"
            :key="idx"
            :value="i.model_id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width: 250px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="handleQuery">查询</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="warning" @click="handleReset">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" stripe :data="list">
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      >
        <template v-if="item.label == '实兑付/预兑付'" #default="{ row }">
          {{ row.expected }}/{{ row.expect }}
        </template>
      </el-table-column>
      <el-table-column prop="" align="center" label="操作" min-width="110px">
        <template #default="{ $index, row }">
          <el-button
            v-if="row.is_check == 1"
            type="text"
            @click.native.prevent="handleReview($index, row)"
          >
            核对
          </el-button>
          <el-button
            v-else
            type="text"
            @click.native.prevent="handleView($index, row)"
          >
            查看
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>
<script>
  import _ from 'lodash'
  import { getStaffList } from '@/api/setPrice'
  import ClientSearch from '@/baseComponents/clientSearch'
  import { activeList, duifuList } from '@/api/payListErr'
  export default {
    name: 'Duifu',
    components: { ClientSearch },
    data() {
      return {
        loading: false,
        staffList: [], // 业务员下拉列表
        activeSelect: [], // 活动下拉
        statusSelect: [],
        time: [],
        form: {
          pageNo: 1, //
          pageSize: 10, //
          sort: '', //
          order: '', //
          model_id: '',
          is_end: '', //状态
          staff_id: '', //业务员
          cust_id: '',
          keyword: '', //客户关键词
          start_time: '', //开始时间
          end_time: '', //结束时间
        },
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        list: [],
        checkList: [
          '单号',
          '客户名称',
          '业务员',
          '陈列活动',
          '参加时间',
          '异常期数',
          '应兑付时间',
          '实兑付/预兑付',
          '状态',
        ],
        columns: [
          {
            order: 1,
            label: '单号',
            prop: 'no',
            width: '200',
          },
          {
            order: 2,
            label: '客户名称',
            prop: 'cust_name',
            width: '',
          },
          {
            order: 3,
            label: '业务员',
            prop: 'staff_name',
            width: '',
          },
          {
            order: 4,
            label: '陈列活动',
            prop: 'display_name',
            width: '',
          },
          {
            order: 5,
            label: '参加时间',
            prop: 'create_at',
            width: '',
          },
          {
            order: 6,
            label: '异常期数',
            prop: 'cash_title',
            width: '',
          },
          {
            order: 7,
            label: '应兑付时间',
            prop: 'time',
            width: '',
          },
          {
            order: 7.5,
            label: '实兑付/预兑付',
            prop: '',
            width: '',
          },
          {
            order: 8,
            label: '状态',
            prop: 'is_check_text',
            width: '',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {
      this.fetchData(),
        // 获取业务员下拉
        getStaffList().then((res) => {
          if (res.code == 200) this.staffList = res.data
        })
      // 活动下拉
      activeList().then((res) => {
        if (res.code == 200) this.activeSelect = res.data
      })
    },
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, msg, code, totalCount } = await duifuList(this.form)
        console.log(data, totalCount)
        ;(this.list = data), (this.total = totalCount)
        this.loading = false
      },
      clientSelect(val) {
        this.form.cust_id = val
      },
      handleQuery() {
        this.form.pageSize = 10
        this.form.pageNo = 1
        this.fetchData()
      },
      handleReset() {
        this.$refs.form.resetFields()
        this.$refs.clientSearch.resetForm()
        this.time = []
      },
      handleReview(index, row) {},
      handleView(index, row) {},
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
    },
  }
</script>
<style lang="scss" scoped></style>
