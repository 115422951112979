var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "markdown-editor-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { lg: 12, md: 24, sm: 24, xl: 12, xs: 24 } },
            [
              _c("vab-markdown-editor", {
                ref: "mde",
                on: { "show-html": _vm.handleShowHtml },
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              }),
              _c("el-button", { on: { click: _vm.handleAddText } }, [
                _vm._v("增加文本"),
              ]),
              _c("el-button", { on: { click: _vm.handleAddImg } }, [
                _vm._v("增加图片"),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 12, md: 24, sm: 24, xl: 12, xs: 24 } },
            [
              _c("el-card", { attrs: { shadow: "hover" } }, [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("span", [_vm._v("markdown转换html实时演示区域")]),
                ]),
                _c("div", { domProps: { innerHTML: _vm._s(_vm.html) } }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }