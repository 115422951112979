var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            "close-on-click-modal": false,
            title: "部门数据权限",
            visible: _vm.dialogVisible,
            width: "70%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "heard" },
              [
                _c(
                  "el-row",
                  [_c("el-col", [_vm._v("部门：" + _vm._s(_vm.lists.text))])],
                  1
                ),
                _c(
                  "el-row",
                  {
                    staticClass: "row-bg",
                    staticStyle: { padding: "30px 0px" },
                    attrs: { type: "flex" },
                  },
                  [
                    _vm._v(
                      " 提示：默认使用部门权限，在此处单独设置员工权限后，按此处限定赋予员工权限 "
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "cont" },
              [
                _c(
                  "el-col",
                  { staticClass: "bor" },
                  [
                    _c("el-row", { staticClass: "text-qx" }, [
                      _vm._v("仓库权限"),
                    ]),
                    _c(
                      "el-row",
                      { staticClass: "gundong" },
                      [
                        _c("check-box", {
                          ref: "check",
                          attrs: { depot: _vm.form.depot },
                          on: { checdid: _vm.checkboxid },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticClass: "bor" },
                  [
                    _c("el-row", { staticClass: "text-qx" }, [
                      _vm._v("商品权限"),
                    ]),
                    _c(
                      "el-col",
                      {
                        staticClass: "gundong row-bg",
                        attrs: { type: "flex", justify: "center" },
                      },
                      [
                        _c(
                          "el-form",
                          { ref: "form", attrs: { model: _vm.form } },
                          [
                            _c(
                              "el-row",
                              { staticStyle: { padding: "20px" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      "collapse-tags": "",
                                      multiple: "",
                                      filterable: "",
                                      placeholder: "请选择商品品类",
                                    },
                                    model: {
                                      value: _vm.form.goods_class,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "goods_class", $$v)
                                      },
                                      expression: "form.goods_class",
                                    },
                                  },
                                  _vm._l(_vm.pinleis, function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.class_name,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              { staticStyle: { padding: "20px" } },
                              [
                                _c("el-cascader", {
                                  attrs: {
                                    options: _vm.pinpais,
                                    placeholder: "请选择商品品牌",
                                    props: {
                                      value: "id",
                                      label: "brand_name",
                                      multiple: true,
                                      checkStrictly: true,
                                    },
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.brand,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "brand", $$v)
                                    },
                                    expression: "form.brand",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticClass: "bor" },
                  [
                    _c("el-row", { staticClass: "text-qx" }, [
                      _vm._v("客户权限"),
                    ]),
                    _c(
                      "el-row",
                      { staticClass: "gundong" },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "gundong row-bg",
                            attrs: { type: "flex", justify: "center" },
                          },
                          [
                            _c(
                              "el-form",
                              { ref: "form", attrs: { model: _vm.form } },
                              [
                                _c(
                                  "el-row",
                                  { staticStyle: { padding: "20px" } },
                                  [
                                    _c("el-cascader", {
                                      attrs: {
                                        options: _vm.quyu,
                                        placeholder: "请选择区域名称",
                                        props: {
                                          value: "id",
                                          label: "area_name",
                                          multiple: true,
                                          checkStrictly: true,
                                        },
                                        clearable: "",
                                      },
                                      on: { change: _vm.handleChange },
                                      model: {
                                        value: _vm.form.area,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "area", $$v)
                                        },
                                        expression: "form.area",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  { staticStyle: { padding: "20px" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          multiple: "",
                                          filterable: "",
                                          "collapse-tags": "",
                                          placeholder: "请选择渠道类型",
                                        },
                                        model: {
                                          value: _vm.form.channel,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "channel", $$v)
                                          },
                                          expression: "form.channel",
                                        },
                                      },
                                      _vm._l(_vm.qudao, function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.channel_name,
                                            value: item.id,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  { staticStyle: { padding: "20px" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          filterable: "",
                                          multiple: "",
                                          "collapse-tags": "",
                                          placeholder: "请选择客户等级",
                                        },
                                        model: {
                                          value: _vm.form.customer_class,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "customer_class",
                                              $$v
                                            )
                                          },
                                          expression: "form.customer_class",
                                        },
                                      },
                                      _vm._l(_vm.dengji, function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.class_name,
                                            value: item.id,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlertijiao },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }