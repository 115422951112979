var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: "",
        visible: _vm.showDialog,
        width: "1000px",
        top: "15vh",
        "close-on-click-modal": false,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c("div", { staticClass: "orderTest-container" }, [
        _c(
          "div",
          { staticClass: "tip" },
          [
            _c(
              "el-select",
              {
                attrs: { placeholder: "往期提成", clearable: "" },
                on: { change: _vm.selectChange },
                model: {
                  value: _vm.phase_value,
                  callback: function ($$v) {
                    _vm.phase_value = $$v
                  },
                  expression: "phase_value",
                },
              },
              _vm._l(_vm.phaseList, function (item) {
                return _c("el-option", {
                  key: item.phase_id,
                  attrs: { label: item.value, value: item.key },
                })
              }),
              1
            ),
            _c("span", { staticStyle: { "margin-left": "20px" } }, [
              _vm._v("提成周期:"),
            ]),
            _c("span", [_vm._v(_vm._s(_vm.tip))]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "table" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                attrs: { stripe: "", data: _vm.list },
              },
              [
                _vm._l(_vm.finallyColumns, function (item, tableIndex) {
                  return _c("el-table-column", {
                    key: tableIndex,
                    attrs: {
                      prop: item.prop,
                      label: item.label,
                      width: "",
                      align: "center",
                    },
                  })
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "",
                    align: "center",
                    label: "操作",
                    fixed: "right",
                    "min-width": "110px",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var $index = ref.$index
                        var row = ref.row
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleCheckRow($index, row)
                                },
                              },
                            },
                            [_vm._v(" 查看明细 ")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          !_vm.isCheck
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleAccounting },
                },
                [_vm._v(" 核算本期提成 ")]
              )
            : _vm._e(),
          _c("el-button", [_vm._v("打印")]),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
        ],
        1
      ),
      _c("detail", { ref: "detail" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }