var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "change-log-container" },
    [
      _c(
        "el-alert",
        {
          attrs: {
            closable: false,
            title:
              "由于未购买用户未加入github团队无法访问github更新日志页面，故该页面使用录屏代替，不代表最新更新进度， 已购买用户请访问https://github.com/vue-admin-beautiful/vue-admin-beautiful-pro/releases获取更新日志",
            "show-icon": "",
            type: "success",
          },
        },
        [
          _c("el-tag", { attrs: { type: "success" } }, [
            _vm._v("最近一次部署时间:" + _vm._s(_vm.updateTime)),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, md: 20, lg: 20, xl: 19 } },
            [
              _c("vab-player-mp4", {
                attrs: { config: _vm.config1 },
                on: {
                  player: function ($event) {
                    _vm.Player1 = $event
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }