var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contextmenu-container" },
    [
      _c(
        "Vab-contextmenu",
        { ref: "contextmenu", attrs: { theme: _vm.theme } },
        [
          _c(
            "Vab-contextmenu-item",
            [
              _c("vab-remix-icon", { attrs: { icon: "24-hours-fill" } }),
              _vm._v(" 菜单1 "),
            ],
            1
          ),
          _c("Vab-contextmenu-item", { attrs: { divider: "" } }),
          _c(
            "Vab-contextmenu-item",
            [
              _c("vab-remix-icon", { attrs: { icon: "4k-line" } }),
              _vm._v(" 菜单2 "),
            ],
            1
          ),
          _c("Vab-contextmenu-item", { attrs: { divider: "" } }),
          _c(
            "Vab-contextmenu-submenu",
            { attrs: { title: "子菜单" } },
            [
              _c(
                "Vab-contextmenu-item",
                [
                  _c("vab-remix-icon", { attrs: { icon: "a-b" } }),
                  _vm._v(" 菜单4 "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-button",
        {
          directives: [
            {
              name: "contextmenu",
              rawName: "v-contextmenu:contextmenu",
              arg: "contextmenu",
            },
          ],
        },
        [_vm._v("右键点击此按钮")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }