var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "pay-top-content" },
        [
          _c("vab-remix-icon", {
            staticClass: "pay-success",
            attrs: { icon: "checkbox-circle-line" },
          }),
          _c("p", [_vm._v("支付成功")]),
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "pay-bottom",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "100px" },
        },
        [
          _c("el-form-item", { attrs: { label: "付款账户" } }, [
            _vm._v(" " + _vm._s(_vm.infoData.payAccount) + " "),
          ]),
          _c("el-form-item", { attrs: { label: "收款账户" } }, [
            _vm._v(" " + _vm._s(_vm.infoData.gatheringAccount) + " "),
          ]),
          _c("el-form-item", { attrs: { label: "收款人姓名" } }, [
            _vm._v(" " + _vm._s(_vm.infoData.gatheringName) + " "),
          ]),
          _c("el-form-item", { attrs: { label: "转账金额" } }, [
            _c("strong", [_vm._v("￥" + _vm._s(_vm.infoData.price) + "元")]),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pay-button-group" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlePrev } },
            [_vm._v("再转一笔")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }