import request from '@/utils/request'

//const serverURL = "https://vab-unicloud-3a9da9.service.tcloudbase.com";
//const apiURL = "/notice/getList";

// export function getList() {
//   return request({
//     url: '/system/notice/index',
//     method: 'get',
//   })
// }
export function getList() {
  return request({
    url: '/baseAdmin/depot-info/get-driver',
    method: 'post',
  })
}
