/*
 * @Author: 侯怀烨
 * @Date: 2021-04-28 16:23:42
 * @LastEditTime: 2021-04-29 09:51:44
 * @LastEditors: Please set LastEditors
 * @Description: 自定义按钮指令
 * @FilePath: \sd-vue-admin\src\components\btnPermissionsArr\has.js
 */
// export default {
//   inserted(el)
// }
import Vue from 'vue'

/**权限指令**/
const has = Vue.directive('has', {
  inserted: function (el, binding, vnode) {
    // 获取页面按钮权限
    let btnPermissionsArr = ''
    console.log(binding, '按钮')
    // 如果指令传值，获取指令参数，根据指令参数和当前登录人按钮权限做比较。
    btnPermissionsArr = binding.value
    let status = eval(
      'vnode.context.$route.meta.btnPermissions.' + btnPermissionsArr
    )
    // 否则获取路由中的参数，根据路由的btnPermissionsArr和当前登录人按钮权限做比较。
    btnPermissionsArr = vnode.context.$route.meta.btnPermissions
    // console.log(vnode.context, '路由')
    if (status == false) {
      el.parentNode.removeChild(el)
    }
  },
})
export { has }
