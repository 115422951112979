var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "909px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "upload" },
        [
          _c("el-alert", {
            attrs: {
              closable: false,
              title:
                "支持jpg、jpeg、png格式，单次可最多选择" +
                _vm.limit +
                "张图片，每张不可大于" +
                _vm.size +
                "M，如果大于" +
                _vm.size +
                "M会自动为您过滤",
              type: "info",
            },
          }),
          _c("br"),
          _c(
            "el-upload",
            {
              ref: "upload",
              staticClass: "upload-content",
              attrs: {
                action: _vm.action,
                "auto-upload": false,
                "close-on-click-modal": false,
                data: _vm.data,
                "file-list": _vm.fileList,
                headers: _vm.headers,
                limit: _vm.limit,
                multiple: true,
                name: _vm.name,
                "on-change": _vm.handleChange,
                "on-error": _vm.handleError,
                "on-exceed": _vm.handleExceed,
                "on-preview": _vm.handlePreview,
                "on-progress": _vm.handleProgress,
                "on-remove": _vm.handleRemove,
                "on-success": _vm.handleSuccess,
                accept: "image/png, image/jpeg",
                "list-type": "picture-card",
              },
            },
            [
              _c("i", {
                staticClass: "el-icon-plus",
                attrs: { slot: "trigger" },
                slot: "trigger",
              }),
              _c(
                "el-dialog",
                {
                  attrs: {
                    modal: false,
                    "close-on-click-modal": false,
                    visible: _vm.dialogVisible,
                    "append-to-body": "",
                    title: "查看大图",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogVisible = $event
                    },
                  },
                },
                [
                  _c("div", [
                    _c("img", {
                      attrs: {
                        src: _vm.dialogImageUrl,
                        alt: "",
                        width: "100%",
                      },
                    }),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: {
            position: "relative",
            "padding-right": "15px",
            "text-align": "right",
          },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _vm.show
            ? _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    top: "10px",
                    left: "15px",
                    color: "#999",
                  },
                },
                [
                  _vm._v(
                    " 正在上传中... 当前上传成功数:" +
                      _vm._s(_vm.imgSuccessNum) +
                      "张 当前上传失败数:" +
                      _vm._s(_vm.imgErrorNum) +
                      "张 "
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleClose } },
            [_vm._v("关闭")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "10px" },
              attrs: { loading: _vm.loading, size: "small", type: "success" },
              on: { click: _vm.submitUpload },
            },
            [_vm._v(" 开始上传 ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }