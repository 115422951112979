var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "el-form",
        { attrs: { inline: "" } },
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { placeholder: "预存货活动" },
                  model: {
                    value: _vm.a,
                    callback: function ($$v) {
                      _vm.a = $$v
                    },
                    expression: "a",
                  },
                },
                _vm._l(_vm.alist, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { placeholder: "打印状态" },
                  model: {
                    value: _vm.b,
                    callback: function ($$v) {
                      _vm.b = $$v
                    },
                    expression: "b",
                  },
                },
                _vm._l(_vm.blist, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-autocomplete", {
                staticClass: "inline-input",
                staticStyle: { width: "140px" },
                attrs: {
                  "fetch-suggestions": _vm.querySearch,
                  placeholder: "存货商品",
                },
                on: { select: _vm.handleSelect },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
              _c("el-button", {
                staticClass: "icon-btn",
                attrs: { icon: "el-icon-search", circle: "" },
                on: { click: _vm.iconClick },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-autocomplete", {
                staticClass: "inline-input",
                staticStyle: { width: "170px" },
                attrs: {
                  "fetch-suggestions": _vm.querySearch,
                  placeholder: "客户名称、简拼",
                },
                on: { select: _vm.handleSelect },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
              _c("el-button", {
                staticClass: "icon-btn",
                attrs: { icon: "el-icon-search", circle: "" },
                on: { click: _vm.iconClick },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [_c("el-button", { attrs: { type: "primary" } }, [_vm._v("查询")])],
            1
          ),
          _c("el-form-item", [_c("el-button", [_vm._v("导出")])], 1),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-wrapper" },
        [
          _c(
            "el-table",
            { attrs: { stripe: "", data: _vm.list } },
            [
              _c(
                "el-table-column",
                { attrs: { align: "center", type: "index", width: "50" } },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            "popper-class": "custom-table-checkbox",
                            trigger: "hover",
                          },
                        },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.checkList,
                                callback: function ($$v) {
                                  _vm.checkList = $$v
                                },
                                expression: "checkList",
                              },
                            },
                            _vm._l(_vm.columns, function (item, index) {
                              return _c("el-checkbox", {
                                key: index,
                                attrs: { label: item.label },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { slot: "reference", type: "text" },
                              slot: "reference",
                            },
                            [
                              _c("vab-remix-icon", {
                                attrs: { icon: "settings-line" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c("el-table-column", {
                attrs: { align: "center", type: "selection", width: "50" },
              }),
              _vm._l(_vm.finallyColumns, function (item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    align: "center",
                    width: "",
                    label: item.label,
                    prop: item.prop,
                  },
                })
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "操作",
                  fixed: "right",
                  "show-overflow-tooltip": "",
                  width: "190",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var $index = ref.$index
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleCheck($index, row)
                              },
                            },
                          },
                          [_vm._v(" 查看 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleReturnBack($index, row)
                              },
                            },
                          },
                          [_vm._v(" 还货 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleStop($index, row)
                              },
                            },
                          },
                          [_vm._v(" 终止 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handlePrint($index, row)
                              },
                            },
                          },
                          [_vm._v(" 打印 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c("check", { ref: "check" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }