var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-form",
            { ref: "form", attrs: { inline: "", model: _vm.form } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "keyword" } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "客户名称、简拼、电话",
                    },
                    model: {
                      value: _vm.form.cust_keywords,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "cust_keywords", $$v)
                      },
                      expression: "form.cust_keywords",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "depart_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "180px" },
                      attrs: {
                        placeholder: "销售费用科目",
                        multiple: "",
                        "collapse-tags": "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.form.funds_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "funds_type", $$v)
                        },
                        expression: "form.funds_type",
                      },
                    },
                    _vm._l(_vm.department, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "user_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { placeholder: "供应商", clearable: "" },
                      model: {
                        value: _vm.form.supp_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "supp_id", $$v)
                        },
                        expression: "form.supp_id",
                      },
                    },
                    _vm._l(_vm.supplier, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.supp_name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "bill_status" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { placeholder: "经办人", clearable: "" },
                      model: {
                        value: _vm.form.user_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "user_id", $$v)
                        },
                        expression: "form.user_id",
                      },
                    },
                    _vm._l(_vm.areaList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.user_name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "print_status" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { placeholder: "结算方式", clearable: "" },
                      model: {
                        value: _vm.form.settle_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "settle_type", $$v)
                        },
                        expression: "form.settle_type",
                      },
                    },
                    _vm._l(_vm.Typesof, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "cust_area_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { placeholder: "状态", clearable: "" },
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    _vm._l(_vm.Customertype, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "keyword" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "备注" },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "Time" } },
                [
                  _c(
                    "el-date-picker",
                    {
                      attrs: {
                        clearable: "",
                        type: "datetimerange",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                        "value-format": "yyyy-MM-dd HH:mm:ss",
                        "default-time": ["00:00:00", "23:59:59"],
                      },
                      model: {
                        value: _vm.form.Time,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "Time", $$v)
                        },
                        expression: "form.Time",
                      },
                    },
                    [_vm._v(" > ")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handlersver },
                    },
                    [_vm._v("查 询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleradd },
                    },
                    [_vm._v("添加销售费用")]
                  ),
                  _c("el-button", { on: { click: _vm.handleImport } }, [
                    _vm._v("导 入"),
                  ]),
                  _c("el-button", { on: { click: _vm.handleExport } }, [
                    _vm._v("导 出"),
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "warning" },
                      on: { click: _vm.handlerReset },
                    },
                    [_vm._v(" 费用科目设置 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("import-tpl", { ref: "importTpl", on: { refresh: _vm.handlersver } }),
      _c(
        "el-row",
        [
          _c(
            "el-table",
            {
              ref: "table",
              attrs: {
                stripe: "",
                data: _vm.tableData,
                "show-summary": "",
                "summary-method": _vm.summaryFunction,
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "序号", align: "center", type: "index" },
              }),
              _vm._l(_vm.Columns, function (list, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    label: list.label,
                    align: list.align,
                    prop: list.prop,
                    width: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            list.label == "原始单号"
                              ? _c("div", [
                                  row.order_ori_type == 1
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "text-decoration": "underline",
                                            cursor: "pointer",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleCheckOrder(row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(row[list.prop]) + " "
                                          ),
                                        ]
                                      )
                                    : _c("span", [
                                        _vm._v(_vm._s(row[list.prop])),
                                      ]),
                                ])
                              : _c("div", [_vm._v(_vm._s(row[list.prop]))]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: "center",
                  fixed: "right",
                  width: "160px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handlerlink(row)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: row.status != 1,
                                expression: "row.status != 1",
                              },
                            ],
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handlerexamine(row)
                              },
                            },
                          },
                          [_vm._v(" 审核 ")]
                        ),
                        _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "确定要作废吗？" },
                            on: {
                              confirm: function ($event) {
                                return _vm.handlerstorp(row)
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { slot: "reference", type: "text" },
                                slot: "reference",
                              },
                              [_vm._v(" 作废 ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleprint(row)
                              },
                            },
                          },
                          [_vm._v(" 打印 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "row-bg", attrs: { type: "flex", justify: "end" } },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.form.pageNo,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.form.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("add-sale", {
        ref: "add",
        attrs: {
          department: _vm.department,
          supplier: _vm.supplier,
          "area-list": _vm.areaList,
        },
        on: { getlist: _vm.handlerlist },
      }),
      _c("ruilang-display-dialog", {
        ref: "RuilangDisplayDialog",
        attrs: { bill_type: "FY", data_id: _vm.data_id, type: 1 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }