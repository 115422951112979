<template>
  <div class="upload-container">
    <el-alert
      :closable="false"
      title="演示环境可能无法模拟上传"
      show-icon
      type="success"
    ></el-alert>
    <vab-upload
      ref="vabUpload"
      :limit="50"
      :size="2"
      name="file"
      url="/upload"
    ></vab-upload>
    <el-button type="primary" @click="handleShow({ key: 'value' })">
      模拟上传
    </el-button>
  </div>
</template>

<script>
  import VabUpload from '@/extra/VabUpload'

  export default {
    name: 'Upload',
    components: {
      VabUpload,
    },
    data() {
      return {}
    },
    methods: {
      handleShow(data) {
        this.$refs['vabUpload'].handleShow(data)
      },
    },
  }
</script>
