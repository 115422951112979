<template>
  <el-dialog
    :modal="false"
    :title="title"
    center
    :visible.sync="showadd"
    :close-on-click-modal="false"
    width="800px"
  >
    <div>
      <el-form
        ref="form"
        :model="form"
        label-width="120px"
        inline
        label-suffix="："
        :rules="rules"
      >
        <el-form-item label="客户" prop="cust_id">
          <client-search
            :keyword="form.keyword"
            :popper-class="'selectDC'"
            @select-id="selectId"
            @select-val-input="custInput"
          ></client-search>
        </el-form-item>
        <el-form-item label="供应商" prop="supp_id">
          <el-select
            v-model="form.supp_id"
            placeholder="供应商"
            clearable
            style="width: 220px"
          >
            <el-option
              v-for="(item, index) in supplier"
              :key="index"
              :label="item.supp_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期" prop="funds_date">
          <el-date-picker
            v-model="form.funds_date"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="科目" prop="funds_type">
          <el-select
            v-model="form.funds_type"
            placeholder="销售费用科目"
            clearable
            style="width: 220px"
          >
            <el-option
              v-for="(item, index) in department"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="结算方式" prop="settle_type">
          <el-select
            v-model="form.settle_type"
            placeholder="结算方式"
            clearable
            style="width: 220px"
          >
            <el-option
              v-for="(item, index) in Typesof"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="form.settle_type == 2"
          label="结算账户"
          prop="account_id"
        >
          <el-select
            v-model="form.account_id"
            placeholder="结算账户"
            clearable
            style="width: 220px"
          >
            <el-option
              v-for="(item, index) in account"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="金额" prop="money">
          <el-input
            v-model="form.money"
            placeholder="请输入金额"
            style="width: 220px"
          />
        </el-form-item>
        <el-form-item label="经办人" prop="user_id">
          <el-select
            v-model="form.user_id"
            placeholder="经办人"
            clearable
            style="width: 220px"
          >
            <el-option
              v-for="(item, index) in areaList"
              :key="index"
              :label="item.user_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model="form.remark"
            placeholder="备注"
            type="textarea"
            :rows="2"
            style="width: 530px"
          />
        </el-form-item>
      </el-form>
      <el-table v-if="type == 3" :data="check_log" style="width: 100%">
        <el-table-column
          prop="type_text"
          label="操作记录"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="amount"
          label="金额"
          width="180"
        ></el-table-column>
        <el-table-column prop="user_name" label="操作人"></el-table-column>
        <el-table-column prop="create_at" label="时间"></el-table-column>
      </el-table>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="showadd = false">取 消</el-button>
      <el-button v-if="type == 1" type="primary" @click="handlersave">
        确 定
      </el-button>
      <el-button v-if="type == 2" type="primary" @click="handleremsave">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ClientSearch from '@/baseComponents/clientSearch'
  import { postAction } from '@/api/Employee'
  export default {
    name: 'AddSale',
    components: {
      ClientSearch,
    },
    props: {
      department: {
        type: Array,
        default() {
          return []
        },
      },
      supplier: {
        type: Array,
        default() {
          return []
        },
      },
      areaList: {
        type: Array,
        default() {
          return []
        },
      },
    },
    data() {
      return {
        showadd: false,
        title: '创建销售费用',
        type: 1, //1修改，添加2审核3查看
        form: {
          id: 0,
          user_id: '',
          money: '',
          funds_type: '',
          supp_id: '',
          funds_date: '',
          remark: '',
          settle_type: 1,
          account_id: '',
          cust_id: '',
          keyword: '',
        },
        check_log: [],
        rules: {
          cust_id: [
            { required: true, message: '请选择客户', trigger: 'change' },
          ],
          funds_date: [
            { required: true, message: '请选择日期', trigger: 'change' },
          ],
          funds_type: [
            { required: true, message: '请选择科目', trigger: 'change' },
          ],
          money: [{ required: true, message: '请输入金额', trigger: 'change' }],
        },
        Typesof: [
          {
            id: 1,
            name: '账期',
          },
          {
            id: 2,
            name: '现结',
          },
        ],
        account: [],
      }
    },
    computed: {
      ...mapGetters({ user_id: 'user/user_id' }),
    },
    watch: {
      showadd(val) {
        if (!val) {
          this.form = {
            id: 0,
            user_id: '',
            money: '',
            funds_type: '',
            supp_id: '',
            funds_date: '',
            remark: '',
            settle_type: 1,
            account_id: '',
            cust_id: '',
            keyword: '',
          }
          this.type = 1
          this.title = '创建销售费用'
        }
      },
    },
    mounted() {
      this.form.user_id = this.user_id
      postAction('baseAdmin/common/account', {}).then((res) => {
        this.account = res.data
      })
    },
    methods: {
      handleremsave() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            postAction('/cashAdmin/funds/sale-funds-check', {
              ...this.form,
            }).then((res) => {
              console.log(res)
              this.$emit('getlist')
              this.$message({
                type: 'success',
                message: res.msg,
              })
              this.showadd = false
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      handlersave() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            postAction('/cashAdmin/funds/sale-funds-save', {
              ...this.form,
            }).then((res) => {
              console.log(res)
              this.$emit('getlist')
              this.$message({
                type: 'success',
                message: res.msg,
              })
              this.showadd = false
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      custInput(v) {
        this.form.keyword = v
      },
      selectId(val) {
        console.log(val, 'selectId')
        if (val) {
          this.form.cust_id = val
          // this.form.keyword = ''
        } else {
          this.form.cust_id = ''
          // this.form.keyword = ''
        }
      },
      handlerinfo(row) {
        postAction('/cashAdmin/funds/sale-funds-detail', { id: row.id }).then(
          (res) => {
            this.check_log = res.data.check_log
            this.form = {
              id: row.id,
              user_id: res.data.user_id,
              money: res.data.money,
              funds_type: res.data.funds_type,
              supp_id: res.data.supp_id,
              funds_date: res.data.funds_date,
              remark: res.data.remark,
              settle_type: res.data.settle_type,
              account_id: res.data.account_id,
              cust_id: res.data.cust_id,
              keyword: res.data.cust_name,
            }
          }
        )
      },
    },
  }
</script>

<style scoped></style>
