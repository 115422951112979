import Vue from 'vue'
const lh = Vue.directive('lh', {
  bind: (el, binding, vnode) => {
    console.log('bind el', el)
    console.log('bind binding', binding)
    console.log('bind vnode', vnode)
    el.style.cssText +=
      ';cursor:pointer;top:0;position:relative;display:inline-block;'
    el.onmousedown = (e) => {
      el.style.cursor = 'move'
      const eleX = e.offsetX
      const eleY = e.offsetY
      console.log('x', eleX)
      console.log('y', eleY)
      el.onmousemove = (e) => {}
      el.onmouseup = (e) => {
        el.style.cursor = 'pointer'
      }
    }
  },
})

export { lh }
