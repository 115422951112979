<!--
 * @Author: 数据权限
 * @Date: 2020-11-20 19:08:53
 * @LastEditTime: 2021-03-31 10:31:25
 * @LastEditors: Please set LastEditors
 * @Description: 部门数据权限
 * @FilePath: \sd-vue-admin\src\views\project\baseOrder\aboutSatff\departmentDetail\components\Authority.vue
-->
<template>
  <div>
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="部门数据权限"
      :visible.sync="dialogVisible"
      width="70%"
      center
    >
      <div>
        <div class="heard">
          <el-row>
            <el-col>部门：{{ lists.text }}</el-col>
          </el-row>
          <el-row type="flex" class="row-bg" style="padding: 30px 0px">
            提示：默认使用部门权限，在此处单独设置员工权限后，按此处限定赋予员工权限
          </el-row>
        </div>
        <div class="cont">
          <el-col class="bor">
            <el-row class="text-qx">仓库权限</el-row>
            <el-row class="gundong">
              <check-box
                ref="check"
                :depot="form.depot"
                @checdid="checkboxid"
              ></check-box>
            </el-row>
          </el-col>
          <el-col class="bor">
            <el-row class="text-qx">商品权限</el-row>
            <el-col class="gundong row-bg" type="flex" justify="center">
              <el-form ref="form" :model="form">
                <el-row style="padding: 20px">
                  <el-select
                    v-model="form.goods_class"
                    collapse-tags
                    multiple
                    filterable
                    placeholder="请选择商品品类"
                  >
                    <el-option
                      v-for="item in pinleis"
                      :key="item.value"
                      :label="item.class_name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-row>
                <el-row style="padding: 20px">
                  <el-cascader
                    v-model="form.brand"
                    :options="pinpais"
                    placeholder="请选择商品品牌"
                    :props="{
                      value: 'id',
                      label: 'brand_name',
                      multiple: true,
                      checkStrictly: true,
                    }"
                    clearable
                  ></el-cascader>
                </el-row>
              </el-form>
            </el-col>
          </el-col>
          <el-col class="bor">
            <el-row class="text-qx">客户权限</el-row>
            <el-row class="gundong">
              <el-col class="gundong row-bg" type="flex" justify="center">
                <el-form ref="form" :model="form">
                  <el-row style="padding: 20px">
                    <el-cascader
                      v-model="form.area"
                      :options="quyu"
                      placeholder="请选择区域名称"
                      :props="{
                        value: 'id',
                        label: 'area_name',
                        multiple: true,
                        checkStrictly: true,
                      }"
                      clearable
                      @change="handleChange"
                    ></el-cascader>
                  </el-row>
                  <el-row style="padding: 20px">
                    <el-select
                      v-model="form.channel"
                      multiple
                      filterable
                      collapse-tags
                      placeholder="请选择渠道类型"
                    >
                      <el-option
                        v-for="item in qudao"
                        :key="item.value"
                        :label="item.channel_name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-row>
                  <el-row style="padding: 20px">
                    <el-select
                      v-model="form.customer_class"
                      filterable
                      multiple
                      collapse-tags
                      placeholder="请选择客户等级"
                    >
                      <el-option
                        v-for="item in dengji"
                        :key="item.value"
                        :label="item.class_name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-row>
                </el-form>
              </el-col>
            </el-row>
          </el-col>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handlertijiao">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import CheckBox from './checkbox'
  import { postAction } from '@/api/Employee'
  import errorLog from '@/store/modules/errorLog'
  export default {
    components: {
      CheckBox,
    },
    props: {
      lists: {
        type: Object,
        default() {
          return {}
        },
      },
    },

    data() {
      return {
        form: {
          goods_class: [],
          depot: [],
          brand: [],
          area: [],
          channel: [],
          customer_class: [],
        },
        depart_id: '',
        cities: [],
        dialogVisible: false,
        pinleis: [],
        pinpais: [],
        quyu: [],
        qudao: [],
        dengji: [],
        url: {
          pinlei: 'baseAdmin/common/goods-class-option',
          pinpai: '/baseAdmin/brand/index',
          quyu: '/baseAdmin/dealer-area/index',
          qudao: '/baseAdmin/customer-channel/index',
          dengji: '/baseAdmin/customer-class/index',
          xiangqing: 'baseAdmin/depart/rel-list',
          tishuju: '/baseAdmin/depart/rel',
        },
      }
    },
    computed: {},
    mounted() {
      this.handlerbumen()
    },
    methods: {
      handlertijiao() {
        console.log(this.form, 'kakakka')
        var b = []
        for (let i = 0; i < this.form.area.length; i++) {
          console.log(this.form.area[i][this.form.area[i].length - 1])
          b.push(this.form.area[i][this.form.area[i].length - 1])
        }
        this.form.area = b
        var s = []
        for (let i = 0; i < this.form.brand.length; i++) {
          console.log(this.form.brand[i][this.form.brand[i].length - 1])
          s.push(this.form.brand[i][this.form.brand[i].length - 1])
        }
        this.form.brand = s
        this.form = {
          goods_class_data: JSON.stringify(this.form.goods_class),
          depot_data: JSON.stringify(this.form.depot),
          channel_data: JSON.stringify(this.form.channel),
          brand_data: JSON.stringify(this.form.brand),
          area_data: JSON.stringify(this.form.area),
          customer_class_data: JSON.stringify(this.form.customer_class),
        }
        this.form.depart_id = this.depart_id
        console.log(this.form, '')
        postAction(this.url.tishuju, this.form)
          .then((res) => {
            console.log(res, '提交成功')
            if (res.code == 200) {
              this.$message({
                message: res.msg,
                type: 'success',
              })
              this.dialogVisible = false
            } else {
              this.$message({
                message: res.msg,
                type: 'success',
              })
            }
          })
          .catch((err) => {
            console.log(err, '失败')
          })
      },
      handleChange(value) {
        console.log(value, this.form.area)
      },
      //仓库那的id
      checkboxid(row) {
        this.form.depot = row
        console.log(row, this.form.depot, '仓库那的id')
      },
      //数据权限详情
      handlerxiangqi(row) {
        postAction(this.url.xiangqing, { depart_id: row.id })
          .then((res) => {
            console.log(res, '详情')
            this.form = res.data
            this.form.goods_class
            this.form.goods_class.forEach((list) => {
              list = parseInt(list)
            })
            var channel = new Array()
            this.form.channel.forEach((item) => {
              channel.push(parseInt(item))
            })
            this.form.channel = channel
            var classd = new Array()
            this.form.customer_class.forEach((list) => {
              classd.push(parseInt(list))
            })
            this.form.customer_class = classd
            this.$refs.check.checkedCities = this.form.depot
            console.log(this.$refs.check.checkedCities, 'lalal')
          })
          .catch((err) => {})
      },
      async handlerbumen() {
        await postAction(this.url.pinlei, {})
          .then((res) => {
            console.log(res, '品类')
            this.pinleis = res.data
          })
          .catch((err) => {})
        await postAction(this.url.pinpai, { is_close: 0 })
          .then((res) => {
            console.log(res, '品牌')
            this.pinpais = res.data.rows
          })
          .catch((err) => {})
        await postAction(this.url.quyu, {})
          .then((res) => {
            console.log(res, '区域')
            this.quyu = res.data
          })
          .catch((err) => {})
        await postAction(this.url.qudao, {})
          .then((res) => {
            console.log(res, '渠道')
            this.qudao = res.data
          })
          .catch((err) => {})
        await postAction(this.url.dengji, {})
          .then((res) => {
            console.log(res, '等级')
            this.dengji = res.data
          })
          .catch((err) => {})
      },
      //数据
      handlerData(row) {
        console.log(row, '部门数据')
        this.handlerxiangqi(row)
        this.depart_id = row.id
      },
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then((_) => {
            done()
          })
          .catch((_) => {})
      },
    },
  }
</script>

<style>
  .heard {
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .cont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 400px;
  }
  .text-qx {
    align-items: center;
    width: 100%;
    padding: 10px;
    font-size: 20px;
    text-align: center;
    background: #cccccc;
  }
  .bumen-col {
    display: flex;
    flex-direction: column;
  }
  .bor {
    border: #cccccc 1px solid;
  }
  .gundong {
    height: 90%;
    padding: 20px;
    overflow: scroll;
  }
</style>
