<template>
  <div class="orderTest-container">
    <el-alert :closable="false" :title="titleTip" type="success"></el-alert>
    <!--      <div class="orderStatus">{{ orderStatus }}</div>-->
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="80px"
      :inline="true"
    >
      <el-form-item label="购货方" prop="cust_name">
        <!-- <el-input
            v-model.trim="form.cust_name"
            prefix-icon="el-icon-search"
            placeholder="客户名称、简拼、电话"
          ></el-input> -->
        <el-autocomplete
          v-model="form.cust_name"
          class="inline-input"
          popper-class="selectDC"
          :popper-append-to-body="false"
          value-key="cust_name"
          :fetch-suggestions="querySearch"
          @select="tableSearch"
        >
          <i
            slot="suffix"
            class="el-icon-search el-input__icon"
            @click="ghficonClick()"
          ></i>
          <template slot-scope="{ item }">
            <div class="wrap">
              <div class="name">
                {{ item.cust_name }}
              </div>
              <div class="addr">{{ item.boss }} {{ item.mobile }}</div>
            </div>
          </template>
          <!-- <template slot-scope="{ item }">
              <div class="wrap" style="display: flex">
                <div class="name" style="width: 50px; margin-right: 120px">
                  {{ item.cust_name }}
                </div>
                <span class="addr">{{ item.boss }} {{ item.mobile }}</span>
              </div>
            </template> -->
        </el-autocomplete>
      </el-form-item>
      <el-form-item label="联系人" prop="boss">
        <el-input v-model.trim="form.boss" disabled></el-input>
      </el-form-item>
      <el-form-item label="地址" prop="address" class="dz">
        <el-input
          v-model.trim="form.address"
          disabled
          style="width: 512px; margin-right: 130px"
        ></el-input>
      </el-form-item>
      <!-- :disabled="isDepot" -->

      <el-form-item label="仓库" prop="allot_depot_name">
        <el-select
          v-model="form.depot_id"
          placeholder="仓库"
          @change="changeDepot"
        >
          <el-option
            v-for="item in allSelectList.depot"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item v-if="btnShow" label="送货人" prop="deliver_name">
        <el-select
          v-model="form.deliver_id"
          placeholder="送货人"
          :disabled="showBtnma"
          clearable
          style="width: 186px"
        >
          <el-option
            v-for="item in shrList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="业务员" prop="staff_name">
<!--        <el-input v-model.trim="form.staff_name"></el-input>-->
        <el-select v-model="form.staff_id" placeholder="业务员" clearable>
          <el-option
            v-for="item_ in allSelectList.staff"
            :key="item_.id"
            :label="item_.name"
            :value="item_.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="调拨单" prop="allot_code">
        <el-input v-model.trim="form.allot_code" disabled></el-input>
      </el-form-item>
      <!-- <el-form-item label="商品条码:" prop="bill_code">
          <el-input
            v-model.trim="form.bill_code"
            placeholder="使用扫码枪扫码添加商品"
            style="width: 220px"
          ></el-input>
        </el-form-item> -->
      <el-form-item v-if="followuser.length != 0" label="随车人">
        <el-select
          v-model="form.follow_users"
          placeholder="随车人员"
          clearable
          multiple
        >
          <el-option
            v-for="item_ in followuser"
            :key="item_.id"
            :label="item_.user_name"
            :value="item_.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="扫码录入"
        prop="goods_code"
        style="margin-left: 20px"
      >
        <el-input
          v-model.trim="form.goods_code"
          :disabled="discode"
          clearable
          placeholder="使用扫码枪扫码添加商品"
          @keyup.enter.native="handlerkey"
        ></el-input>
      </el-form-item>
    </el-form>
    <!-- <el-button icon="el-icon-plus" type="primary" @click="addRow">
        添加
      </el-button> -->
    <!-- 表格 -->
    <el-table
      ref="tableSort"
      v-loading="listLoading"
      stripe
      :data="list"
      show-summary
      :summary-method="getSummaries"
      height="600px"
      :row-class-name="tableRowClassName"
      @selection-change="setSelectRows"
      @cell-click="handlercellclick"
    >
      <!-- 选择框 -->
      <el-table-column
        align="center"
        type="selection"
        width="90"
      ></el-table-column>
      <!-- 序号 -->
      <el-table-column prop="" align="center" label="序号" min-width="50px">
        <template #default="{ $index }">
          <span>{{ $index + 1 }}</span>
        </template>
      </el-table-column>

      <!-- 类型 -->
      <el-table-column
        prop="type"
        align="center"
        label="类型"
        min-width="130px"
      >
        <template #default="{ row, $index }">
          <template
            v-if="row.index === tabClickIndex && tabClickLabel === '类型'"
          >
            <!-- <el-input v-model="row.type" style="width: 100px" /> -->
            <el-select
              v-model="row.goods_type"
              placeholder="选择类型"
              style="width: 100px"
              :disabled="
                (row.goods_type != 1 &&
                  row.goods_type != 2 &&
                  row.goods_type != 3 &&
                  row.goods_type != 10 &&
                  row.goods_type != 11 &&
                  row.goods_type != 6 &&
                  row.goods_type != 17 &&
                  row.goods_type != 5 &&
                  row.goods_type != 9) ||
                row.activity_type != 0
              "
              @change="
                utilsF(), handleTypeChange($event, row)
                moneyN(row, $index)
              "
            >
              <el-option
                v-for="item_ in row.arr_goods_type"
                :key="item_.id"
                :label="item_.name"
                :value="item_.id"
                :disabled="item_.disabled"
              ></el-option>
            </el-select>
          </template>
          <div v-else>
            {{ row.goods_type_text }}
            <i
              v-show="!row.goods_type_text"
              class="el-icon-caret-right trsher"
            />
          </div>
        </template>
      </el-table-column>
      <!-- 商品名称 -->
      <el-table-column
        prop="goods_name_print"
        align="center"
        label="商品名称"
        min-width="210px"
      >
        <template #default="{ $index, row }">
          <template
            v-if="
              row.index === tabClickIndex &&
              tabClickLabel === '商品名称' &&
              (row.activity_type == 0 || row.activity_type == 4)
            "
          >
            <goods-search
              :f-key="row.goods_name"
              :goods-id="row.goods_id"
              :kouwei="row.child"
              :rowf="row"
              :depot-id="form.depot_id"
              :cust-id="form.cust_id"
              @add-rows="addRows"
              @select-goods-all="selectGoods($event, row, $index)"
              @change-kouwei="changeKouwei($event, row)"
            ></goods-search>
          </template>
          <span v-else>
            {{ row.goods_name }}
            <i
              v-show="!row.goods_name"
              class="el-icon-search"
              style="text-align: right"
            />
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="specs"
        align="center"
        label="规格"
        min-width="150px"
      ></el-table-column>
      <el-table-column
        prop="unit_code"
        align="center"
        label="条码"
        min-width="110px"
      >
        <template #default="{ row }">
          {{ currUnitCode(row) }}
        </template>
      </el-table-column>
      <!-- 单位 -->
      <el-table-column prop="type" align="center" label="单位" width="140px">
        <template #default="{ row }">
          <template
            v-if="
              row.index === tabClickIndex &&
              tabClickLabel === '单位' &&
              (row.activity_type == 0 || row.activity_type == 4)
            "
          >
            <!-- <el-input v-model="row.type" style="width: 100px" /> -->
            <el-select
              v-model="row.unit_id"
              placeholder="选择单位"
              style="width: 70px"
              :disabled="row.goods_name_print == ''"
              @change="unitChange($event, row), utilsF()"
            >
              <el-option
                v-for="item in row.arr_unit"
                :key="item.id"
                :label="item.unit_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </template>
          <div v-else>
            {{ row.unit_name }}
            <i v-show="!row.unit_name" class="el-icon-caret-right trsher" />
          </div>
        </template>
        <!-- <template #default="{ row }">
            {{ row.units }}
          </template> -->
      </el-table-column>
      <!-- 单价 -->
      <el-table-column
        prop="goods_price"
        align="center"
        label="单价"
        min-width="110px"
      >
        <template #default="{ row, $index }">
          <template
            v-if="
              row.index === tabClickIndex &&
              tabClickLabel === '单价' &&
              (row.activity_type == 0 ||
                row.activity_type == 2 ||
                row.activity_type == 3 ||
                row.activity_type == 4)
            "
          >
            <el-input
              v-model="row.goods_price"
              style="width: 80px"
              :disabled="row.goods_name_print == '' || isEditPrice == 0"
              onkeyup="value=value.replace(/[^\d.]/g,'') "
              @input="utilsF(), moneyN(row, $index)"
            />
          </template>
          <div v-else>
            {{ row.goods_price }}
            <i v-show="!row.goods_price" class="el-icon-caret-right trsher" />
          </div>
        </template>
      </el-table-column>
      <!-- 订购数量 -->
      <el-table-column
        prop="quantity"
        align="center"
        label="订购数量"
        min-width="110px"
      >
        <template #default="{ row, $index }">
          <template
            v-if="
              row.index === tabClickIndex &&
              tabClickLabel === '订购数量' &&
              (row.activity_type == 0 ||
                row.activity_type == 2 ||
                row.activity_type == 3 ||
                row.activity_type == 4)
            "
          >
            <!--              @input="utilsF()"-->
            <el-input
              v-model="row.pre_quantity"
              :disabled="row.goods_name_print == ''"
              style="width: 80px"
              @input="moneyN(row, $index),utilsF()"
              @focus="inputfocus(row)"
            />

            <!-- <el-select
                v-model="row.quantity"
                placeholder="选择单位"
                style="width: 70px"
              >
                <el-option
                  v-for="item in quantityList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select> -->
            <!-- <div
                class="cell-btn-wrap"
                style="display: flex; flex-wrap: nowrap"
              >
                <el-button
                  class="cancel-btn"
                  type="success"
                  @click="saveCellEdit(row)"
                >
                  保存
                </el-button>
                <el-button
                  class="cancel-btn"
                  type="warning"
                  @click="cancelCellEdit(row)"
                >
                  取消
                </el-button>
              </div> -->
          </template>
          <div v-else>
            {{ row.pre_quantity }}
            <i v-show="!row.pre_quantity" class="el-icon-caret-right trsher" />
          </div>
        </template>
        <!-- <template #default="{ row }">
            {{ row.quantity }}
          </template> -->
      </el-table-column>
      <!-- 可用库存 -->
      <el-table-column
        prop="depot_stock_num"
        align="center"
        label="可用库存"
        min-width="110px"
      >
        <template #default="{ row }">
          {{ row.depot_stock_num_text }}
        </template>
      </el-table-column>
      <!-- 实际库存 -->
      <el-table-column
        prop="balance_count"
        align="center"
        label="实际库存"
        min-width="110px"
      >
        <template #default="{ row }">
          {{ row.balance_count_text }}
        </template>
      </el-table-column>
      <!-- 金额 -->
      <el-table-column
        prop="goods_money"
        align="center"
        label="金额"
        min-width="90px"
      >
        <template #default="{ row, $index }">
          <div
            v-if="
              row.goods_type == 2 ||
              row.goods_type == 10 ||
              row.goods_type == 11 ||
              row.goods_type == 3 ||
              row.goods_type == 12 ||
              row.goods_type == 13 ||
              row.goods_type == 16
            "
          >
            0
          </div>
          <div v-else>
            <template
              v-if="
                row.index === tabClickIndex &&
                tabClickLabel === '金额' &&
                row.pre_quantity > 0
              "
            >
              <el-input
                ref="input"
                v-model="row.goods_money"
                :disabled="
                  row.goods_name == '' ||
                  isEditPrice == 0 ||
                  row.pre_quantity == 0
                "
                style="width: 80px"
                onkeyup="value=value.replace(/[^\d.]/g,'') "
                @input="moneyreverse(row, $index),utilsF()"
              />
              <!--
                  onkeyup="value=value.replace(/[^\-?\d.]/g,'')"

                  -->
            </template>
            <div v-else>
              {{ row.goods_money }}
              <i v-show="!row.goods_money" class="el-icon-caret-right trsher" />
            </div>
          </div>
        </template>
      </el-table-column>
      <!-- 生产日期 -->
      <el-table-column
        prop="goods_production_date"
        align="center"
        label="生产日期"
        min-width="140px"
      >
        <template #default="{ row }">
          <!-- <el-input v-model="row.goods_production_date"></el-input> -->
          <template
            v-if="
              row.index === tabClickIndex &&
              tabClickLabel === '生产日期' &&
              row.activity_type == 0
            "
          >
            <el-date-picker
              v-model="row.goods_production_date"
              value-format="yyyy-MM-dd"
              style="width: 130px"
            ></el-date-picker>
          </template>
          <div v-else>
            {{ row.goods_production_date }}
            <i
              v-show="!row.goods_production_date"
              class="el-icon-caret-right trsher"
            />
          </div>
        </template>
      </el-table-column>
      <!-- 备注 -->
      <el-table-column
        prop="info"
        align="center"
        label="备注"
        min-width="260px"
      >
        <template #default="{ row }">
          <template
            v-if="
              row.index === tabClickIndex &&
              tabClickLabel === '备注' &&
              row.activity_type == 0
            "
          >
            <el-input v-model="row.info"></el-input>
          </template>
          <div v-else>
            {{ row.info }}
            <i v-show="!row.info" class="el-icon-caret-right trsher" />
          </div>
        </template>
      </el-table-column>

      <el-table-column align="center" label="操作" fixed="right" width="160">
        <template #default="{ $index, row }">
          <!-- <el-button type="text" @click.native.prevent="addRow(row)">
              添加
            </el-button> -->
          <div v-if="row.activity_type == 0||(row.activity_id==0&&row.activity_type==4)||(row.activity_id==0&&row.activity_type==5)">
            <el-button type="text" @click.native.prevent="copyRow($index, row)">
              复制
            </el-button>
            <el-button
              type="text"
              @click.native.prevent="deleteRow($index, row)"
            >
              删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 表格结束 -->

    <!-- 底部文字 -->
    <el-form
      ref="form"
      :model="form"
      label-width="120px"
      :inline="true"
      style="margin-top: 15px"
    >
      <!--        <div v-if="style == 1">-->
      <el-form-item label="备注" prop="remark" style="width: 100%">
        <el-input v-model="form.remark" style="width: 530px"></el-input>
      </el-form-item>
      <el-form-item label="下单时间" prop="remark" style="width: 100%">
        <el-date-picker
          v-model="form.create_at"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择下单时间"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="订购总额" prop="total_amount">
        <el-input v-model.trim="form.total_amount" disabled></el-input>
      </el-form-item>
      <el-form-item label="退货金额" prop="refund_goods_amount">
        <el-input v-model.trim="form.refund_goods_amount" disabled></el-input>
      </el-form-item>
      <el-form-item label="销售金额" prop="sale_amount">
        <el-input v-model.trim="form.sale_amount" disabled></el-input>
      </el-form-item>
      <el-form-item label="优惠" prop="discount_amount">
        <el-input
          v-model.trim="form.discount_amount"
          @input="yhInputF"
        ></el-input>
      </el-form-item>
      <el-form-item label="优惠后" prop="discounted_amount">
        <el-input
          v-model.trim="form.discounted_amount"
          :min="0"
          :max="1000000000"
          type="number"
          style="width: 186.25px"
          @input="yhhInputF"
        ></el-input>
      </el-form-item>
      <el-form-item label="退货退款" prop="refund_amount">
        <el-input v-model.trim="form.refund_amount" disabled></el-input>
      </el-form-item>
      <el-form-item label="退货方式" prop="refund_type_text">
        <el-select
          v-model="form.refund_type"
          placeholder="退货方式"
          style="width: 186px"
          @change="utilsF"
        >
          <el-option
            v-for="item in allSelectList.arr_refund_type"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="form.is_fun" label="费用抵扣" prop="thtk">
        <el-input v-model.trim="form.support_amount"></el-input>
      </el-form-item>
      <el-form-item v-if="form.is_fun">
        <el-select
          v-model="form.fun_ids"
          :placeholder="'请选费用' + `（合计${funds_zong}）`"
          style="width: 186px"
          multiple
          collapse-tags
          @change="handlerfydk"
        >
          <el-option
            v-for="(item_, index) in funselect"
            :key="index"
            :label="item_.funds_type + `(金额${item_.left_amount})`"
            :value="item_.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="style == 2" label="兑奖现金:" prop="prize_amount">
        <el-input
          v-model.trim="form.prize_amount"
          @input="rehandler"
        ></el-input>
      </el-form-item>
      <el-form-item v-if="style == 3" label="兑付现金:" prop="cash_amount">
        <el-input v-model.trim="form.cash_amount" @input="rehandler"></el-input>
      </el-form-item>
      <el-form-item label="应收款" prop="receiv_money">
        <el-input v-model.trim="form.receiv_money" disabled></el-input>
      </el-form-item>

      <!--          <el-form-item label="使用预存款" prop="deposit_amount">
            <el-input v-model.trim="form.deposit_amount" disabled></el-input>
          </el-form-item>
          <el-form-item label="还货金额" prop="return_amount">
            <el-input v-model.trim="form.return_amount" disabled></el-input>
          </el-form-item>-->

      <el-form-item label="结算方式" prop="account_type" style="width: 60%">
        <el-select
          v-model="form.account_type"
          placeholder="结算方式"
          style="width: 186px"
          @change="jsfsChange"
        >
          <el-option
            v-for="item in accounttype"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-for="(item1, index) in xssk"
        :key="index"
        label="销售收款"
      >
        <div style="display: flex">
          <el-input
            v-model.trim="item1.money"
            style="width: 90px; margin-right: 20px"
          ></el-input>
          <el-select v-model="item1.pay_type" style="width: 90px">
            <el-option
              v-for="item2 in allSelectList.arr_pay_type"
              :key="item2.id"
              :label="item2.name"
              :value="item2.id"
            ></el-option>
          </el-select>
        </div>
        <i
          v-if="index == 0"
          class="el-icon-circle-plus addbtn"
          @click="addInput"
        ></i>
      </el-form-item>
      <!--        </div>
        <div v-else-if="style == 2">
          <el-form-item label="备注" prop="remark" style="width: 100%">
            <el-input v-model="form.remark" style="width: 530px"></el-input>
          </el-form-item>
          <el-form-item label="兑奖现金:" prop="prize_amount">
            <el-input v-model.trim="form.prize_amount"></el-input>
          </el-form-item>
        </div>
        <div v-else-if="style == 3">
          <el-form-item label="兑付现金:" prop="cash_amount">
            <el-input v-model.trim="form.cash_amount"></el-input>
          </el-form-item>
          <el-form-item v-if="form.sale_type == 4" label="备注" prop="remark">
            <div style="display: flex">
              <el-input
                v-model="form.remark"
                style="margin-right: 10px; width: 530px"
              ></el-input>
            </div>
          </el-form-item>
        </div>-->
    </el-form>
    <el-table stripe :data="bottomTable">
      <el-table-column
        prop="pay_type_name"
        label="收款方式"
        width=""
        align="center"
      ></el-table-column>
      <el-table-column
        prop="amount"
        label="金额"
        width=""
        align="center"
      ></el-table-column>
      <el-table-column
        prop="user_name"
        label="收款人"
        width=""
        align="center"
      ></el-table-column>
      <el-table-column
        prop="create_at"
        label="时间"
        width=""
        align="center"
      ></el-table-column>
    </el-table>
    <!-- 底部按钮 -->
    <div slot="footer" class="dialog-footer">
      <!-- <el-button type="warning" @click="saveAlloct">
          保存并生成调拨单
        </el-button> -->
      <el-button v-if="!isCopy" type="primary" @click="save(1)">保存</el-button>
      <el-button v-else type="primary" @click="save(2)">复制</el-button>

      <el-button @click="close">取 消</el-button>
    </div>
    <!-- 表格输入框弹窗 -->
    <!-- <table-input-window
      ref="tableInputWindow"
      @add-rows="addRows"
    ></table-input-window> -->
    <input-window
      ref="inputWindow"
      @change-client="changeClient"
    ></input-window>
  </div>
</template>

<script>
  import {
    getAdvanceOrderEditTableList,
    getAdvanceOrderClientSelectList,
    goodsSelect,
    editRow,
    driverList,
    getAllSelect,
  } from '@/api/advanceOrder'
  import { getInputList } from '@/api/goodsSearchInput'
  import { getList } from '@/api/chooseClientDialog'
  // import TableInputWindow from '@/baseComponents/goodsChooseDialog'
  import InputWindow from './components/inputWindow'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import { yhhInput, utils, yhInput, receiv } from '@/utils/AOform.js'
  import { postAction } from '@/api/Employee'
  import { mapActions } from 'vuex'
  export default {
    name: 'OrderTableEdit',
    components: {
      // TableInputWindow,
      GoodsSearch,
      InputWindow,
    },
    // props: {
    //   btnShow: {
    //     type: [Boolean, Object],
    //     default: () => false,
    //   },
    // },
    data() {
      return {
        btnShow: false,
        tabClickIndex: null,
        tabClickLabel: '',
        funselect: [],
        funds_zong: '',
        followuser: [],
        edit: true,
        res: [], // 购货人下拉
        goods_res: [], // 表格商品下拉
        id: 0,
        style: 1,
        selectRow: {},
        accounttype: [
          {
            id: 1,
            name: '货到付款',
          },
          {
            id: 2,
            name: '账期结算',
          },
        ],
        form: {
          // ghf: '老牛烧烤2',
          // dz: '河北省石家庄市裕华区XXXXXXXXXX',
          // lxr: '牛老板 15533315521',
          // ywy: '张三 15566321123',
          // chck: '',
          // shr: '',
          // cust_name: '',
          // goods_code: '',
          create_at: '',
          fun_ids: [],
        },
        chckList: [
          // {
          //   label: '总仓',
          //   value: '总仓',
          // },
          // {
          //   label: 'A仓',
          //   value: 'A仓',
          // },
          // {
          //   label: 'B仓',
          //   value: 'B仓',
          // },
        ],
        shrList: [
          // {
          //   label: '张司机',
          //   value: '张司机',
          // },
          // {
          //   label: '李司机',
          //   value: '李司机',
          // },
          // {
          //   label: 'XXX',
          //   value: 'XXX',
          // },
        ],
        rules: {
          //   title: [{ required: true, trigger: 'blur', message: '请输入标题' }],
          //   author: [{ required: true, trigger: 'blur', message: '请输入作者' }],
        },
        isCopy: false,
        title: '编辑订单',
        orderStatus: '状态',
        dialogFormVisible: false,
        addRowDialog: false,
        // 表格
        checkList: [
          '序号',
          '商品名称',
          '规格',
          '条码',
          '单位',
          '单价',
          '订购数量',
          '库存',
          '金额',
          '生产日期',
          '备注',
        ],
        columns: [
          {
            order: 1,
            label: '序号',
            width: '100',
            prop: '',
            sortable: false,
          },
          {
            order: 2,
            label: '商品名称',
            width: '210px',
            prop: 'goods_name_print',
            sortable: false,
          },
          {
            order: 2.5,
            label: '规格',
            width: 'auto',
            prop: 'specs',
            sortable: false,
          },
          {
            order: 3.2,
            label: '条码',
            width: '110px',
            prop: 'unit_code',
            sortable: false,
          },
          {
            order: 3,
            label: '单位',
            width: '110', //'auto',
            prop: 'units',
            sortable: false,
          },
          {
            order: 4,
            label: '单价',
            width: 'auto',
            prop: 'goods_price',
            sortable: false,
          },
          {
            order: 5,
            label: '订购数量',
            width: '100',
            prop: 'pre_quantity',
            sortable: false,
          },
          {
            order: 6,
            label: '库存',
            width: '100',
            prop: 'balance_count',
            sortable: false,
          },
          {
            order: 7,
            label: '金额',
            width: '90',
            prop: 'sale_amount',
            sortable: false,
          },
          {
            order: 8,
            label: '生产日期',
            width: '140',
            prop: 'production_date',
            sortable: false,
          },
          {
            order: 9,
            label: '备注',
            width: '90',
            prop: 'remark',
            sortable: false,
          },
        ],
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        selectRows: '',
        // 所有下拉
        allSelectList: {},
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          title: '',
        },

        xssk: [],

        bottomTable: [],
      }
    },

    computed: {
      discode() {
        if (this.form.cust_id != '') {
          return false
        } else {
          return true
        }
      },
      currUnitCode() {
        return (row) => {
          // if (row.arr_unit && row.arr_unit.length > 0) {
          //   let a = row.arr_unit.filter(
          //     (i) => (i.unit_id || i.id) == row.unit_id
          //   )[0].unit_code
          //   return a
          // } else {
          //   return ''
          // }
          if (row.child && row.child.length > 0) {
            let a = row.child.filter((i) => i.id == row.goods_child_id)[0]
              .small_unit_code
            return a
          } else {
            if (row.arr_unit && row.arr_unit.length > 0) {
              let a = row.arr_unit.filter(
                (i) => (i.unit_id || i.id) == row.unit_id
              )[0].unit_code
              return a
            } else {
              return ''
            }
          }
        }
      },
      showBtnma() {
        if (this.btnShow) {
          if (
            this.btnShow.pre_process_door == 0 &&
            this.btnShow.distribute_only == 1
          ) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      },
      isEditPrice() {
        return localStorage.getItem('isEditPrice')
      },
      // 是否可选择仓库
      isDepot() {
        if (
          this.list.length == 0 ||
          (this.list.length == 1 && this.list[0].goods_name_print == '')
        ) {
          return false
        } else {
          return true
        }
      },
      titleTip() {
        return (
          '单号:' +
          this.form.bill_code +
          ' 下单时间： ' +
          this.form.sale_time +
          ' 原单: ' +
          this.form.old_code +
          ' 修改人: ' +
          this.form.edit_name +
          ' 修改时间: ' +
          this.form.edit_time
        )
      },
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      // 'form.receiv_money'(val) {
      //   let c = JSON.parse(JSON.stringify(this.form.receiv_money))
      //   if (this.form.account_type == 1) {
      //     this.xssk[0].money = Number(c).toFixed(2)
      //   } else {
      //     this.xssk[0].money = 0
      //   }
      // },
      dialogFormVisible(n) {
        if (n) {
          this.getAllSelectList()

          this.getTableList()
          // 购货人下拉获取
          getList({ pageSize: -1, pageNo: 1 }).then((res) => {
            if (res.code == 200) {
              this.res = res.data
              console.log(this.res)
            } else {
              this.$message.error(res, msg)
            }
          })
          // 表格商品下拉获取
          goodsSelect({ pageNo: 1, pageSize: -1 }).then((res) => {
            if (res.code == 200) {
              console.log('wadasdwads')
              console.log(res.data)
              this.goods_res = res.data
            } else {
            }
          })
        } else {
          this.list = []
        }
      },
      'form.ghf'(val) {
        console.log(1, val)
        if (val === '') {
          this.edit = false
        } else {
          this.edit = true
        }
      },
      'form.support_amount'(val) {
        utils(this.list, this, 3)
      },
    },
    created() {
      // this.getTableList()
      // 客户下拉菜单数据获取
      // getAdvanceOrderClientSelectList().then((res) => {
      //   this.selectRes = res
      // })
      // 获取出货仓库，退货方式	付款方式	结算方式	下拉
    },
    mounted() {
      console.log(this.$route.params.row)
      if (this.$route.params.row) {
        this.btnShow = this.$route.params.btnShow
        this.showEdit(this.$route.params.row)
        this.id = this.$route.params.row.id
        this.isCopy = false
        this.dialogFormVisible = true
      }
      this.handlerfollowuserlist()
    },
    updated() {
      // 在vue渲染结束后重新绘制表格,解决合计行不显示的问题
      this.$nextTick(() => {
        this.$refs.tableSort.doLayout();
      })
    },
    methods: {
      ...mapActions({
        delVisitedRoute: 'tabsBar/delVisitedRoute',
      }),
      /*// 表格合计
      getSummaries(param) {
        const { columns, data } = param
        console.log(columns, data)
        const sums = []
        // this.calcForm2(data)
        console.log('变类型')

        data.forEach((list, index) => {
          console.log(list, '每一列')
          columns.forEach((column, index) => {
            if (index === 0) {
              sums[index] = '合计'
            }
            if (column.label == '订购数量') {
              if (isNaN(sums[index])) {
                sums[index] = 0
              }
              if (
                list.goods_type != 6 &&
                list.goods_type != 10 &&
                list.goods_type != 11 &&
                list.goods_type != 19
              ) {
                sums[index] += Number(list.quantity)
              }
            }
            if (column.label == '金额') {
              sums[index] = this.form.total_amount
            }
          })
        })
        console.log(sums)
        return sums
      },*/
      rehandler() {
        receiv(this)
      },
      async handlerkey() {
        if (this.form.goods_code) {
          const { data } = await getInputList({
            goods_code: this.form.goods_code,
            depot_id: this.form.depot_id,
            custom_id: this.form.id,
          })
          if (data.length > 0) {
            let goodsdata = data.map((item, index) => {
              item.activity_type = 0
              item.balance_count_text = item.balance_count_read
              try {
                item.arr_unit.forEach((unit) => {
                  if (unit.is_default == 2) {
                    item.unit_id = unit.id
                    item.goods_price = unit.sell_price
                    item.unit_name = unit.unit_name
                  }
                })
                item.goods_type = 1
                item.goods_type_text = '销常'
              } catch (err) {}
              return item
            })
            if (this.list.length <= 1 && this.list[0].goods_name == '') {
              goodsdata[0].quantity += 1
              goodsdata[0].pre_quantity = goodsdata[0].quantity
              this.list.splice(this.list.length - 1, 0, goodsdata[0])
            } else {
              var goodstow = false
              this.list.forEach((list) => {
                if (list.goods_name != '') {
                  if (list.param_code == goodsdata[0].param_code) {
                    list.quantity += 1
                    list.pre_quantity = list.quantity
                    goodstow = true
                  }
                }
              })
              if (!goodstow) {
                goodsdata[0].quantity += 1
                goodsdata[0].pre_quantity = goodsdata[0].quantity
                this.list.splice(this.list.length - 1, 0, goodsdata[0])
              }
            }
          }
          this.form.goods_code = ''
        }
      },
      async handlerfollowuserlist() {
        return await postAction('saleAdmin/order-pre/option', {}).then(
          (res) => {
            this.followuser = res.data.order_follow_users
          }
        )
      },
      showEdit() {
        this.dialogFormVisible = true
      },
      // 计算金额数
      moneyNum(item) {
        let num = 0
        if (Number(item.quantity) && Number(item.goods_price)) {
          num = Number(item.quantity) * Number(item.goods_price)
        }
        return num.toFixed(2)
      },

      moneyreverse(item, index) {
        let num = 0
        if (Number(item.pre_quantity) && Number(item.goods_money)) {
          num = Number(item.goods_money) / Number(item.pre_quantity)
        }
        this.$set(this.list[index], 'goods_price', num.toFixed(2))
      },
      // 表格内单位切换
      unitChange(e, row) {
        console.log(e, row)
        let price = ''
        if (
          row.goods_type == 6 ||
          row.goods_type == 10 ||
          row.goods_type == 11 ||
          row.goods_type == 13 ||
          row.goods_type == 14
        ) {
          price = row.arr_unit.filter((item) => item.id == e)[0].return_price
        } else {
          price = row.arr_unit.filter((item) => item.id == e)[0].sell_price
        }
        let unit = row.arr_unit.filter((item) => item.id == e)[0].unit_name
        row.goods_price = price
        row.unit_name = unit
      },
      //获取费用抵扣列表
      handlerfydk(v) {
        let fydk = 0
        console.log(v, '选中', this.form.fun_ids)
        if (v.length > 0) {
          v.forEach((list) => {
            fydk += Number(
              this.funselect.filter((item) => item.id == list)[0].left_amount
            )
          })
          this.form.support_amount = fydk.toFixed(2)
        } else {
          this.form.support_amount = 0
        }
      },
      // 获取表格数据
      async getTableList() {
        this.listLoading = true
        const { data } = await getAdvanceOrderEditTableList({
          id: this.id,
        })
        if (data.order_info.is_fun == 1) {
          await postAction('/saleAdmin/order-pre/funds-option', {
            cust_id: data.order_info.cust_id,
            order_id: this.id,
          }).then((res) => {
            this.funselect = res.data.bill_list
            this.funds_zong = res.data.funds_zong
          })
        }

        console.log(data, '詳情列表-------------------------')
        this.data = data
        if (data.order_info.check_status == 0) {
          this.orderStatus = data.order_info.bill_status_text
        } else {
          this.orderStatus = data.order_info.check_status_text
        }
        // this.orderStatus = data.order_info.check_status_text

        console.log(data)
        this.listLoading = false
        this.list = data.order_detail_info.map((v) => {
          console.log(v)
          this.$set(v, 'edit', true)
          return v
        })
        this.selectRes = this.data.order_detail_info
        this.jsfsList = this.data.arr_account_type
        this.pay_styleList = this.data.arr_pay_type
        this.form = data.order_info
        this.form.staff_id =String(this.form.staff_id)
        if (this.form.follow_users != '') {
          this.form.follow_users = data.order_info.follow_users.split(',')
        } else {
          this.form.follow_users = []
        }
        this.bottomTable = data.arr_collect
        // this.form.account_type = this.form.pay_way
        if (this.form.refund_type == 0) {
          this.form.refund_type = ''
        }
        if (this.form.account_type == 0) {
          this.form.account_type = ''
        }
        this.shrList = this.data.arr_deliver
        if (this.form.deliver_id == 0) {
          this.form.deliver_id = ''
        }
        this.thfsList = data.arr_refund_type
        // this.orderCode = data.
        // this.orderTime = data.
        this.xssk = [
          {
            money: 0,
            pay_type: '',
          },
        ]
        this.list.push({
          activity_code: '',
          activity_id: 0,
          activity_type: 0,
          balance_count: 0,
          depot_stock_num: 0,
          edit: true,
          goods_child_id: null,
          goods_id: null,
          goods_money: '',
          goods_name: '',
          goods_name_print: '',
          goods_price: '',
          goods_production_date: '',
          goods_type: '',
          info: '',
          money: '',
          quantity: '',
          specs: '',
          unit_id: '',
        })
        // style样式选择
        if (
          this.form.sale_type == '1' ||
          this.form.sale_type == '2' ||
          this.form.sale_type == '3'
        ) {
          console.log('1样式')
          this.style = 1
        } else if (this.form.sale_type == '5' || this.form.sale_type == '6') {
          console.log('兑奖样式')
          this.style = 2
        } else if (this.form.sale_type == '4') {
          console.log('兑付样式')
          this.style = 3
        } else {
          this.style = 1
        }
        if (data.order_info.fun_ids == '') {
          this.form.fun_ids = []
        } else {
          this.form.fun_ids = data.order_info.fun_ids.split(',')
        }
      },
      changeClient(row) {
        Object.assign(this.form, row)
      },
      // yhhInput(val) {
      //   console.log(val)
      //   let v = Number(val)
      //   if (v < 0 || v == NaN) {
      //     this.form.discounted_amount = ''
      //   }
      //   console.log(v)

      //   if (val != '') {
      //     this.form.receiv_money = v - this.form.refund_amount
      //   } else {
      //     this.form.receiv_money =
      //       this.form.sale_amount - this.form.refund_amount
      //   }
      // },
      jsfsChange(val) {
        if (val == 1) {
          this.xssk[0].money = this.form.receiv_money
        } else {
          this.xssk[0].money = 0
        }
      },

      // 计算金额数
      moneyN(item, index) {
        let num = 0
        if (Number(item.pre_quantity) && Number(item.goods_price)) {
          num = Number(item.pre_quantity) * Number(item.goods_price)
        }
        // this.list[index].goods_money = num.toFixed(2)
        console.log(num.toFixed(2), '计算的金额')
        this.$set(this.list[index], 'goods_money', num.toFixed(2))
        this.utilsF()
      },
      utilsF() {
        utils(this.list, this, 3)
      },
      yhhInputF(val) {
        yhhInput(val, this)
      },
      yhInputF(val) {
        console.log(val)
        yhInput(val, this)
      },
      // 表格合计
      getSummaries(param) {
        const { columns, data } = param
        console.log(columns, data)
        const sums = []
        columns.forEach((item) => {
          sums.push('')
        })
        let nums = 0
        sums[0] = '总价'
        data.forEach((item) => {
          if (item.goods_type != '') {
            let num = Number(
              (Number(item.goods_price) * Number(item.quantity)).toFixed(2)
            )
            nums += num
          }
        })
        sums[11] = nums + '元'
        return sums
      },
      // 计算form2input
      // calcForm2(data, o = true) {
      //   console.log(data)
      //   let total_amount = 0,
      //     xsje = 0,
      //     thje = 0,
      //     yhh = 0,
      //     thtk = 0,
      //     ysk = 0
      //   data.forEach((item) => {
      //     let num = Number(
      //       (Number(item.goods_price) * Number(item.quantity)).toFixed(2)
      //     )
      //     // 订购总额
      //     // 销常
      //     if (item.goods_type == 1) {
      //       total_amount += num
      //       xsje += num
      //       yhh += num
      //     } else if (item.goods_type == 6) {
      //       // 退货金额
      //       thje += num
      //       // this.form2.thje =
      //     } else if (item.goods_type == 7) {
      //       thje += num
      //       // this.form2.thje =
      //     }
      //   })
      //   this.form.total_amount = total_amount.toFixed(2)
      //   this.form.sale_amount = xsje.toFixed(2)
      //   // this.form.refund_amount = thje
      //   if (o) {
      //     this.form.discounted_amount = yhh.toFixed(2)
      //   } else {
      //     this.form.discounted_amount = this.form.discounted_amount.toFixed(2)
      //   }
      //   // if (this.form.yhh == this.form.xsje || this.form.yhh == 0) {
      //   //   this.form.yhh = yhh
      //   // }

      //   // 应收款
      //   if (yhh == 0 || yhh == '') {
      //     console.log('this.form.sale_amount', this.form.sale_amount)
      //     console.log('this.form.refund_amount', this.form.refund_amount)
      //     this.form.receiv_money = (
      //       this.form.sale_amount - this.form.refund_amount
      //     ).toFixed(2)
      //   } else {
      //     this.form.receiv_money =
      //       Number(yhh).toFixed(2) - Number(this.form.refund_amount).toFixed(2)
      //   }
      //   // 退货退款
      //   if (this.form.refund_type == 1) {
      //     console.log('thje', thje)
      //     this.form.refund_amount = Number(thje).toFixed(2)
      //   } else if (this.form.refund_type != 1) {
      //     console.log('退货退款不是 1 ')
      //     this.form.refund_amount = 0
      //   }
      //   console.log('优惠后', yhh)
      // },
      // 出货仓库改变
      changeDepot(val) {
        console.log('val')
        console.log(val)
        driverList({ depot_id: val }).then((res) => {
          if (res.code == 200) {
            console.log(res.data)
            this.shrList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      handleTypeChange(v, row) {
        console.log('v', v, row)
        let unitId = row.arr_unit.filter((i) => i.id == row.unit_id)
        if (v == 6 || v == 10 || v == 11 || v == 13 || v == 14) {
          if (unitId.length > 0) {
            row.goods_price = unitId[0].return_price
          }
        } else {
          row.goods_price = unitId[0].sell_price
        }
        if (row.arr_goods_type != undefined) {
          row.goods_type_text = row.arr_goods_type.filter(
            (list) => list.id == v
          )[0].name
        }
      },
      //
      async getAllSelectList() {
        let { data, code, msg } = await getAllSelect()
        if (code == 200) {
          console.log('所有下拉列表')
          console.log(data)
          this.allSelectList = data
        }
      },
      // 表格内商品名称输入框搜索
      createFilter(queryString) {
        return (state) => {
          return (
            state.cust_name.toLowerCase().indexOf(queryString.toLowerCase()) !==
            -1
          )
        }
      },
      // 商品搜索下拉
      async fetchData(ky = '') {
        let { data, code, msg } = await getInputList({
          pageNo: 1,
          pageSize: -1,
          keyword: ky,
          depot_id: this.form.depot_id,
        })
        console.log(data)
        if (code == 200) {
          return data
        } else {
          this.$message.error(msg)
        }
      },
      handleSelect(index, row, $event) {
        console.log(index, row, $event)
        this.list[index] = $event
        // this.list[index].arr_unit = $event.arr_unit
        // this.list[index].arr_goods_type = $event.arr_goods_type
        this.list[index].goods_type = 1
        this.list[index].unit_id = ''
        // this.list[index].unit_name = '0'

        console.log(this.list)
      },
      async querySearch(val, cb) {
        let selectData = this.res
        var results = val
          ? selectData.filter(this.createFilter(val))
          : selectData
        cb(results)
      },
      // 表格内商品名称输入框搜索
      querySearch2(queryString, cb) {
        if (queryString == '') {
          cb([])
        } else {
          this.fetchData(queryString).then((val) => {
            console.log(val)
            cb(val)
          })
        }
      },
      selectGoods(val, row, index) {
        console.log(val)
        console.log(row)
        row.goods_name = val.goods_name
        if (val.goods_id != -1) {
          this.$set(row, 'pre_quantity', val.quantity)
          this.$set(row, 'goods_price', val.goods_price)
          Object.assign(row, val, { goods_type: 1 })

          // balance_count 实时库存
          row.balance_count_text = row.balance_count
          // 可用库存
          // row.depot_stock_num_text = row.balance_count
          row.id = ''
          if (val.arr_unit.length != 0) {
            console.log(
              val.arr_unit.filter((item) => item.id == val.unit_id)[0].unit_name
            )
            row.unit_name = row.arr_unit.filter(
              (item) => item.id == val.unit_id
            )[0].unit_name
          }
          row.goods_type_text = row.arr_goods_type.filter(
            (list) => list.id == row.goods_type
          )[0].name
          this.utilsF()
          // row.arr_unit.forEach((unit) => {
          //   if (unit.is_default == 2) {
          //     row.unit_id = unit.id
          //   }
          // })
          if (this.list.length == index + 1) {
            this.list.push({
              activity_code: '',
              activity_id: 0,
              activity_type: 0,
              balance_count: 0,
              depot_stock_num: 0,
              edit: true,
              goods_child_id: null,
              goods_id: null,
              goods_money: '',
              goods_name: '',
              goods_name_print: '',

              goods_price: '',
              goods_production_date: '',
              goods_type: '',
              info: '',
              money: '',
              quantity: '',
              specs: '',
              unit_id: '',
              unit_name: '',
            })
          }
        }

        // this.addRow(this.list.length - 1, row, '1')
      },
      tableSearch(a) {
        console.log(a)
        Object.assign(this.form, a)
        this.form.cust_id=a.id
        this.form.account_type = Number(a.pay_way)
      },
      addInput() {
        this.xssk.push({ money: '', pay_type: '' })
        console.log(this.form)
      },
      inputfocus(row) {
        if (row.pre_quantity == 0) {
          row.pre_quantity = ''
        }
      },
      // 添加行
      addRow(index, row, $event) {
        console.log(index, $event)
        if ($event == '') {
          return false
        }
        if (this.list.length == index + 1) {
          this.list.push({
            activity_code: '',
            activity_id: 0,
            activity_type: 0,
            balance_count: 0,
            depot_stock_num: 0,
            edit: true,
            goods_child_id: null,
            goods_id: null,
            goods_money: '',
            goods_name: '',
            goods_name_print: '',

            goods_price: '',
            goods_production_date: '',
            goods_type: '',
            info: '',
            money: '',
            quantity: '',
            specs: '',
            unit_id: '',
          })
        }
      },
      tableRowClassName({ row, rowIndex }) {
        // 把每一行的索引放进row
        row.index = rowIndex
      },
      // 单击cell事件
      handlercellclick(row, column, cell, event) {
        // console.log(row, column, cell, event)
        this.tabClickIndex = row.index
        this.tabClickLabel = column.label
      },

      iconClick(index, row) {
        console.log(index, row)
        // this.tableInputWindow = true
        this.$refs['tableInputWindow'].showDialog = true
        this.$refs['tableInputWindow'].depotId = this.form.depot_id
      },
      ghficonClick() {
        console.log(1)
        this.$refs['inputWindow'].showDialog = true
      },
      addRowSave() {
        console.log(this.addRowFrom)
        let data = this.addRowFrom
        Object.assign(data, { id: this.list.length + 1 })
        this.list.push(data)
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.getTableList()
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.getTableList()
      },
      showDialog(row) {
        this.dialogFormVisible = true
        // // 获取表格数据
        // getAdvanceOrderEditTableList().then((res) => {
        //   this.list = res
        // })
      },
      copyRow(index, row) {
        let rowS = JSON.parse(JSON.stringify(row))
        let rowa = Object.assign(rowS, { id: row.id++, edit: false })
        this.list.splice(index, 0, rowa)
        this.utilsF()
      },
      deleteRow(index, row) {
        if (this.list.length == 1) {
          this.$message.error('不能删除最后一条数据')
        } else {
          this.list.splice(index, 1)
          this.utilsF()
        }
      },
      // 勾选表格触发事件
      setSelectRows(val) {
        console.log(val)
        this.selectRows = val
      },
      close() {
        // this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false

        this.delVisitedRoute(this.$route.fullPath)
        this.$router.push('advanceOrder')
      },
      closeRow() {
        this.addRowDialog = false
        // this.$refs['addRowFrom'].resetFields() // 重置表单
      },
      deletelast(arr) {
        return arr.slice(0, arr.length - 1)
      },
      addRows(val) {
        console.log('添加rows')
        console.log(val)
        if (val.length !== 0) {
          val.forEach((item) => {
            item.id = ''
            // item.activity_type = 0
            this.$set(item, 'pre_quantity', 0)
            this.$set(item, 'activity_type', 0)
            item.goods_type_text = item.arr_goods_type.filter(
              (list) => list.id == item.goods_type
            )[0].name
            item.unit_name = item.arr_unit.filter(
              (list) => list.id == item.unit_id
            )[0].unit_name
            item.arr_unit.forEach((unit) => {
              if (unit.is_default == 2) {
                item.unit_id = unit.id
                item.unit_name = unit.unit_name
              }
            })

            this.list.splice(this.list.length - 1, 0, item)
          })
          this.$message.success('添加成功')
        }
      },
      changeKouwei(val, row) {
        console.log('改变口味', val)
        row.goods_child_id = val.id
        row.goods_name = val.goods_name + ` (${val.attr})`
      },
      save(type = 1) {
        // let tabD = this.deletelast(this.list)
        let tabD = []
        this.list.forEach((item) => {
          item.quantity = item.pre_quantity

          if (item.goods_name_print) {
            tabD.push(item)
          }
        })
        let form = JSON.parse(JSON.stringify(this.form))
        if (type != 1) {
          form.pay_status_time = ''
        }
        if (form.refund_type == '') {
          form.refund_type = 0
        }
        if (form.account_type == '') {
          form.account_type = 0
        }
        let xssk = JSON.parse(JSON.stringify(this.xssk))
        let payInfo = []
        xssk.forEach((item) => {
          if ((!item.money || item.money != 0) && item.pay_type != '') {
            payInfo.push(item)
          }
        })
        payInfo = payInfo.concat(this.bottomTable)
        let followUsers = null
        if (
          this.form.follow_users == null ||
          this.form.follow_users == undefined
        ) {
          followUsers = []
        } else {
          followUsers = JSON.parse(JSON.stringify(this.form.follow_users))
        }
        console.log(payInfo)
        form.fun_ids = form.fun_ids.join(',')
        let endData = {
          order_info: JSON.stringify(
            Object.assign(form, {
              id: this.id,
              follow_users: followUsers.join(','),
            })
          ),
          goods_info: JSON.stringify(tabD),
          pay_info: payInfo.length == 0 ? '' : JSON.stringify(payInfo),
        }
        // endData.order_info.fun_ids = endData.order_info.fun_ids.join(',')
        if (type == 1) {
          editRow(endData).then((res) => {
            console.log(res)
            if (res.code == 200) {
              console.log('succ')
              this.$message.success(res.msg)
              this.dialogFormVisible = false
              this.$emit('refresh')

              this.delVisitedRoute(this.$route.fullPath)
              this.$router.push('advanceOrder')
            } else {
            }
          })
        } else {
          // 复制接口
          postAction('/saleAdmin/order-pre/do-bill-order', {
            ...endData,
            ...{
              cust_id: this.form.cust_id,
            },
          }).then((res) => {
            console.log(res)
            if (res.code == 200) {
              console.log('succ')
              this.$message.success(res.msg)
              this.dialogFormVisible = false
              this.$emit('refresh')
              this.delVisitedRoute(this.$route.fullPath)
              this.$router.push('advanceOrder')
            } else {
            }
          })
        }
      },
      saveAlloct() {
        let form = JSON.parse(JSON.stringify(this.form))
        if (form.refund_type == '') {
          form.refund_type = 0
        }
        if (form.account_type == '') {
          form.account_type = 0
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-form-item__content {
    position: relative;
    margin: 5px 0;
  }
  .el-input__inner {
    padding-right: 30px;
  }
  .addbtn {
    position: absolute;
    top: 0;
    right: -50px;
    margin-left: 15px;
    font-size: 32px;
    color: #84bc39;
  }
  .wrap {
    display: flex;
    justify-content: space-between;
    .name {
      width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
</style>
<style lang="scss">
  .trsher {
    position: absolute;
    top: 0px;
    right: 0px;
    transform: rotate(-45deg);
  }
</style>
