<template>
  <el-dialog
    :modal="false"
    title="查看采购入库单"
    :visible.sync="showDialog"
    width="1000px"
    top="5vh"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div class="orderTest-container">
      <el-form :model="form" inline>
        <el-form-item label="供应商:" prop="supp_id">
          <el-select
            v-model="form.supp_id"
            clearable
            placeholder="请选择供应商"
            style="width: 180px"
          >
            <el-option
              v-for="(item, index) in gysList"
              :key="index"
              :label="item.supp_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="经办人:" prop="operator_id">
          <el-select
            v-model="form.operator_id"
            clearable
            placeholder="请选择经办人"
            style="width: 180px"
          >
            <el-option
              v-for="(item, index) in jbrList"
              :key="index"
              :label="item.user_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="入库仓库:" prop="depot_id">
          <el-select
            v-model="form.depot_id"
            style="width: 180px"
            clearable
            placeholder="入库仓库"
          >
            <el-option
              v-for="(item, index) in ckList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="">
          <el-input
            placeholder="使用扫码器添加商品"
            style="width: 303px"
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-checkbox v-model="minUnit">按小单位采购</el-checkbox>
        </el-form-item>
        <el-form-item prop="">
          <el-input placeholder="备注" style="width: 303px"></el-input>
        </el-form-item>
      </el-form>
      <!-- 表格 -->
      <el-table
        ref="tableSort"
        v-loading="loading"
        stripe
        :data="list"
        height="360px"
      >
        <!-- 序号 -->
        <el-table-column align="center" type="index" width="50">
          <template slot="header">
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <el-checkbox
                  v-for="(item, index) in columns"
                  :key="index"
                  :label="item.label"
                ></el-checkbox>
              </el-checkbox-group>
              <el-button slot="reference" type="text">
                <vab-remix-icon icon="settings-line" />
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in finallyColumns"
          :key="index"
          :label="item.label"
          :prop="item.prop"
          width=""
          align="center"
        >
          <!-- 类型 -->
          <template v-if="item.prop == 'goods_type'" #default="{ row }">
            <el-select v-model="row.goods_type">
              <el-option
                v-for="(typeItem, index) in typeSelect"
                :key="index"
                :label="typeItem.value"
                :value="typeItem.id"
              ></el-option>
            </el-select>
          </template>
          <!-- 商品名称 -->
          <template v-else-if="item.prop == 'goods_name'" #default="{ row }">
            <goods-search
              ref="goodsSearch"
              :f-key="row.goods_name"
              @add-rows="addRows"
              @select-goods="selectGoods($event, row)"
            ></goods-search>
          </template>
          <!-- 单位 -->
          <template v-else-if="item.prop == 'unit_name'" #default="{ row }">
            <!-- <el-select v-model="row.">
              <el-option></el-option>
            </el-select> -->
            {{ row.unit_name }}
          </template>
          <!-- 生产日期 -->
          <template
            v-else-if="item.prop == 'goods_production_date'"
            #default="{ row }"
          >
            <el-date-picker
              v-model="row.goods_production_date"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              style="width: 180px"
            ></el-date-picker>
          </template>
          <!-- 采购价 -->
          <template v-else-if="item.prop == 'goods_price'" #default="{ row }">
            <el-input v-model="row.goods_price"></el-input>
          </template>
          <!-- 采购数量 -->
          <template v-else-if="item.prop == 'goods_num'" #default="{ row }">
            <el-input v-model="row.goods_num"></el-input>
          </template>
          <!-- 备注 -->
          <template v-else-if="item.prop == 'remark'" #default="{ row }">
            <el-input v-model="row.remark"></el-input>
          </template>
        </el-table-column>

        <el-table-column align="center" label="操作" fixed="right" width="100">
          <template #default="{ $index, row }">
            <!-- <el-button type="text" @click.native.prevent="addRow(row)">
              添加
            </el-button> -->
            <el-button type="text" @click.native.prevent="copyRow($index, row)">
              复制
            </el-button>
            <el-button
              type="text"
              @click.native.prevent="deleteRow($index, row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>
<script>
  import { jbrSelect, gysSelect, purchaseInsetDetail } from '@/api/purchase'
  import { getAllSelect } from '@/api/advanceOrder'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  export default {
    name: 'Check',
    components: {
      GoodsSearch,
    },
    data() {
      return {
        id: 0,
        showDialog: true,
        loading: false,
        minUnit: false,
        jbrList: [],
        gysList: [],
        typeSelect: [
          {
            id: 1,
            value: '采购',
          },
          {
            id: 2,
            value: '赠送',
          },
        ],
        form: {
          supp_id: '',
          operator_id: '', //经办人id
          supp_id: '', //经销商id
          depot_id: '', //入库id
          bill_code: '', //单号
          remark: '', //备注
        },
        ckList: [],
        // 表格
        checkList: [
          '类型',
          '商品名称',
          '规格',
          '条形码',
          '生产日期',
          '单位换算',
          '单位',
          '采购价',
          '采购数量',
          '金额',
          '备注',
        ],
        columns: [
          {
            order: 1,
            label: '类型',
            width: '100',
            prop: 'goods_type',
          },
          {
            order: 2,
            label: '商品名称',
            width: '220',
            prop: 'goods_name',
          },
          {
            order: 3,
            label: '规格',
            width: '90',
            prop: 'goods_specs',
          },
          {
            order: 4,
            label: '条形码',
            width: '160',
            prop: 'goods_code',
          },
          {
            order: 5,
            label: '生产日期',
            width: '200',
            prop: 'goods_production_date',
          },
          {
            order: 6,
            label: '单位换算',
            width: '100',
            prop: 'goods_uint_change',
          },
          {
            order: 7,
            label: '单位',
            width: '110', //'110',
            prop: 'unit_name',
          },
          {
            order: 8,
            label: '采购价',
            width: '140',
            prop: 'goods_price',
          },
          {
            order: 9,
            label: '采购数量',
            width: '90',
            prop: 'goods_num',
          },
          {
            order: 10,
            label: '金额',
            width: '90',
            prop: 'sum_money',
          },
          {
            order: 11,
            label: '备注',
            width: '220',
            prop: 'remark',
          },
        ],
        list: [
          {
            id: 1111,
            goods_type: 1,
            goods_type_text: '采购',
            goods_name: '蒙牛',
            goods_specs: '500ml',
            goods_code: '654456645',
            goods_uint_change: '1件=10箱',
            goods_production_date: '2020.04.23',
            unit_id: '1',
            unit_name: '瓶',
            goods_price: '111.00',
            goods_num: '11',
            sum_money: '2000.00',
            remark: '备注',
          },
          {
            id: 2222,
            goods_type: 2,
            goods_type_text: '采购',
            goods_name: '蒙牛12',
            goods_specs: '500ml',
            goods_code: '654456645',
            goods_uint_change: '1件=10箱',
            goods_production_date: '2020.04.23',
            unit_id: '1',
            unit_name: '瓶',
            goods_price: '222.00',
            goods_num: '220',
            sum_money: '2000.00',
            remark: '备注22222',
          },
        ],
        list2: [],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      showDialog(val) {
        if (val) {
          this.fetchData()
        }
      },
    },
    created() {
      // 经办人下拉
      jbrSelect().then((res) => {
        this.jbrList = res.data.rows
      })
      // 供应商下拉
      gysSelect().then((res) => {
        this.gysList = res.data.rows
      })
      // 仓库下拉
      getAllSelect().then((res) => {
        this.ckList = res.data.depot
      })
    },
    mounted() {},
    methods: {
      async fetchData() {
        let { data, msg, code } = await purchaseInsetDetail({
          order_id: this.id,
        })
        console.log(data)
      },

      // 商品搜索添加rows
      addRows(val) {},
      // 商品选中
      selectGoods(val, row) {
        console.log(val, row)
        row.goods_id = val.goods_id
        row.goods_name = val.goods_name
        row.goods_uint_change = val.unit_cv
        row.goods_specs = val.specs
        row.goods_production_date = val.goods_production_date
      },
    },
  }
</script>
<style lang="scss" scoped></style>
