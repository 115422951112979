var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "step-form-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            {
              attrs: {
                xs: 24,
                sm: { span: 20, offset: 2 },
                md: { span: 20, offset: 2 },
                lg: { span: 12, offset: 6 },
                xl: { span: 10, offset: 7 },
              },
            },
            [
              _c(
                "el-steps",
                {
                  staticClass: "steps",
                  attrs: { active: _vm.active, "align-center": "" },
                },
                [
                  _c("el-step", { attrs: { title: "填写转账信息" } }),
                  _c("el-step", { attrs: { title: "确认转账信息" } }),
                  _c("el-step", { attrs: { title: "完成" } }),
                ],
                1
              ),
              _vm.active === 1
                ? _c("step1", { on: { "change-step": _vm.handleSetStep } })
                : _vm._e(),
              _vm.active === 2
                ? _c("step2", {
                    attrs: { "info-data": _vm.form },
                    on: { "change-step": _vm.handleSetStep },
                  })
                : _vm._e(),
              _vm.active === 3
                ? _c("step3", {
                    attrs: { "info-data": _vm.form },
                    on: { "change-step": _vm.handleSetStep },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }