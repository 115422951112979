var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { attrs: { shadow: "hover" } },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c(
            "span",
            [
              _c("vab-remix-icon", { attrs: { icon: "road-map-line" } }),
              _vm._v(" 中国地图 "),
            ],
            1
          ),
          _c(
            "el-tag",
            { staticClass: "card-header-tag", attrs: { type: "warning" } },
            [_vm._v(" 我爱你中国，亲爱的母亲 ")]
          ),
        ],
        1
      ),
      _c("vab-chart", {
        staticStyle: { height: "320px" },
        attrs: {
          autoresize: true,
          options: _vm.options,
          theme: "vab-echarts-theme",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }