var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h5", [_vm._v("销售收款：")]),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { stripe: "", data: _vm.paisonglist.mx_list },
        },
        [
          _c("el-table-column", {
            attrs: { label: "客户名称", align: "center", prop: "cust_name" },
          }),
          _c("el-table-column", {
            attrs: { label: "商品名称", align: "center", prop: "goods_name" },
          }),
          _c("el-table-column", {
            attrs: { label: "单位", align: "center", prop: "unit_name" },
          }),
          _c("el-table-column", {
            attrs: { label: "数量", align: "center", prop: "goods_num" },
          }),
          _c("el-table-column", {
            attrs: { label: "价格", align: "center", prop: "goods_price" },
          }),
          _c("el-table-column", {
            attrs: { label: "金额", align: "center", prop: "goods_money" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }