var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-drag-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-left-panel",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.sort } },
                [_vm._v("重置顺序")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.reload } },
                [_vm._v("重载页面")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "vab-draggable",
            _vm._b(
              { attrs: { list: _vm.iconList } },
              "vab-draggable",
              _vm.dragOptions,
              false
            ),
            _vm._l(_vm.iconList, function (item, index) {
              return _c(
                "el-col",
                {
                  key: item.color + index,
                  attrs: { lg: 3, md: 3, sm: 6, xl: 3, xs: 12 },
                },
                [
                  _c(
                    "el-card",
                    { staticClass: "icon-panel", attrs: { shadow: "hover" } },
                    [
                      _c("vab-remix-icon", {
                        style: { color: item.color },
                        attrs: { icon: item.icon },
                      }),
                      _c("p", [_vm._v("按住拖拽")]),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }