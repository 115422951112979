import store from '@/store'
import { wsUrl } from '@/config'
import { postAction } from '@/api/Employee'
import Vue from 'vue'
import router from '@/router'
// ws
console.log('开始ws代码')
const token = store.getters['user/accessToken']
const userId = store.getters['user/user_id']
const userName = store.getters['user/username']
console.log(userName, userId, token)
router.beforeEach(async (to, from, next) => {
  if (to.fullPath == '/register' || to.fullPath.indexOf('/login') != -1) {
    if (store.getters['socket/ws']) {
      // 断开ws
      store.commit('socket/closeWs')
    }
  } else {
    if (!store.getters['socket/ws']) {
      console.log('连接wsssss', store.getters['socket/ws'])
      initWs()
    }
  }

  // if (store.getters['user/accessToken'] && !store.getters['socket/ws']) {
  // } else {
  //   if (store.getters['socket/ws']) {
  //   }
  // }
  next()
})
function initWs() {
  console.log('初始化ws')
  if (typeof WebSocket === 'undefined') {
    alert('您的浏览器不支持socket')
  } else if (1) {
    console.log('wsUrl', store.getters['socket/ws'])
    // // 实例化socket
    // // this.ws = new WebSocket(wsUrl)
    store.commit('socket/setWs', wsUrl)
    // // 监听socket连接
    store.getters['socket/ws'].onopen = function () {
      console.log('ws连接成功')
    }
    // // 监听socket错误信息
    store.getters['socket/ws'].onerror = error()
    // // 监听socket消息
    store.getters['socket/ws'].onmessage = async function (msg) {
      let res = JSON.parse(msg.data)
      if (res.type && res.type == 'bind') {
        console.log('绑定bind')
        let hasToken = store.getters['user/accessToken']
        postAction('/baseAdmin/push/bind', {
          token: hasToken,
          client_id: res.client_id,
        }).then((r) => {
          console.log('r', r)
        })
      } else {
        // store.commit('socket/msg', msg)
        console.log('new ws msg', JSON.parse(msg.data))
        let msgObj = JSON.parse(msg.data)
        if (msgObj.type == 20) {
          sendMsg(msgObj.msg)
        } else if (msgObj.auto_print == 1 && msgObj.print_url != '') {
          // 打印功能
          dayin(msgObj.print_url)
        } else {
          // 消息提醒
        }
        // store.dispatch('socket/getMessage', msg)
        // Vue.prototype.getWsMsg(msg)
      }
    }
    store.getters['socket/ws'].onclose = close()
  }
}
function error() {
  console.log('ws error')
}

function close() {
  console.log('socket已经关闭')
}

function sendMsg(msg, title = '通知') {
  Vue.prototype.$notify({
    title,
    message: msg,
  })
}

function dayin(curl) {
  console.log('打印')
  let token = store.getters['user/accessToken']
  let base = ''
  if (process.env.NODE_ENV == 'development') {
    base = 'http://test.admin.shangdan.cn/'
  } else {
    base = 'https://admin.api.shangdan.cn/'
  }
  let url = base + curl + '&token=' + token
  console.log('url', url, token)
  Vue.prototype.$notify({
    message: '<iframe src="' + url + '" frameborder="0"></iframe>',
    title: '打印',
    dangerouslyUseHTMLString: true,
  })
}
