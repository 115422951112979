var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "sendOrder",
      },
      [
        _c(
          "el-alert",
          { attrs: { closable: false, title: _vm.titleTip, type: "success" } },
          [
            _vm._v(
              " " +
                _vm._s(_vm.data.order_info && _vm.data.order_info.cancle_str) +
                " "
            ),
          ]
        ),
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              model: _vm.form,
              "label-width": "80px",
              inline: true,
              rules: _vm.rules,
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "购货方", prop: "cust_name" } },
              [
                _c("el-input", {
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.form.cust_name,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.form,
                        "cust_name",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.cust_name",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "联系人", prop: "lxr" } },
              [
                _c("el-input", {
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.form.lxr,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.form,
                        "lxr",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.lxr",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticClass: "dz",
                staticStyle: { width: "50%" },
                attrs: { label: "地址", prop: "address" },
              },
              [
                _c("el-input", {
                  staticStyle: { width: "350px" },
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.form.address,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.form,
                        "address",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.address",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "仓库", prop: "depot_name" } },
              [
                _c("el-input", {
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.form.depot_name,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.form,
                        "depot_name",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.depot_name",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "业务员", prop: "staff_name" } },
              [
                _c("el-input", {
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.form.staff_name,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.form,
                        "staff_name",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.staff_name",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "送货人", prop: "deliver_id" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "送货人" },
                    on: { change: _vm.chooseDeliver },
                    model: {
                      value: _vm.form.deliver_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "deliver_id", $$v)
                      },
                      expression: "form.deliver_id",
                    },
                  },
                  _vm._l(_vm.data.arr_deliver, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
                _c(
                  "span",
                  {
                    staticStyle: {
                      display: "inline-block",
                      width: "170px",
                      "padding-left": "15px",
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.carCode) + " ")]
                ),
              ],
              1
            ),
            _vm.followuser.length != 0
              ? _c(
                  "el-form-item",
                  { attrs: { label: "随车人" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "随车人员",
                          clearable: "",
                          multiple: "",
                        },
                        model: {
                          value: _vm.form.follow_users,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "follow_users", $$v)
                          },
                          expression: "form.follow_users",
                        },
                      },
                      _vm._l(_vm.followuser, function (item_) {
                        return _c("el-option", {
                          key: item_.id,
                          attrs: { label: item_.user_name, value: item_.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-form-item",
              { attrs: { label: "调拨单", prop: "allot_code" } },
              [
                _c("el-input", {
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.form.allot_code,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.form,
                        "allot_code",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.allot_code",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "派单时间", prop: "delivery_time" } },
              [
                _c("el-date-picker", {
                  attrs: {
                    type: "datetime",
                    "value-format": "yyyy-MM-dd HH:mm:ss",
                    format: "yyyy-MM-dd HH:mm:ss",
                    placeholder: "选择日期时间",
                  },
                  model: {
                    value: _vm.form.delivery_time,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "delivery_time", $$v)
                    },
                    expression: "form.delivery_time",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
            ref: "tableSort",
            staticStyle: { "margin-bottom": "15px" },
            attrs: { stripe: "", data: _vm.list },
          },
          [
            _c("el-table-column", {
              attrs: { align: "center", type: "selection", width: "55" },
            }),
            _vm._l(_vm.finallyColumns, function (item, index) {
              return _c("el-table-column", {
                key: index,
                attrs: {
                  align: "center",
                  label: item.label,
                  sortable: item.sortable,
                  width: "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var $index = ref.$index
                        var row = ref.row
                        return [
                          item.label === "序号"
                            ? _c("span", [_vm._v(_vm._s($index + 1))])
                            : item.label === "类型"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "80px" },
                                      attrs: { disabled: "" },
                                      model: {
                                        value: row.goods_type,
                                        callback: function ($$v) {
                                          _vm.$set(row, "goods_type", $$v)
                                        },
                                        expression: "row.goods_type",
                                      },
                                    },
                                    _vm._l(
                                      row.arr_goods_type,
                                      function (item2) {
                                        return _c("el-option", {
                                          key: item2.id,
                                          attrs: {
                                            label: item2.name,
                                            value: item2.id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c("span", [_vm._v(_vm._s(row[item.prop]))]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
          ],
          2
        ),
        _c(
          "el-form",
          {
            ref: "form2",
            attrs: { model: _vm.form, "label-width": "120px", inline: true },
          },
          [
            _c(
              "el-form-item",
              {
                staticStyle: { width: "100%" },
                attrs: { label: "下单时间", prop: "remark" },
              },
              [
                _c("el-date-picker", {
                  attrs: {
                    type: "datetime",
                    "value-format": "yyyy-MM-dd HH:mm:ss",
                    format: "yyyy-MM-dd HH:mm:ss",
                    placeholder: "选择下单时间",
                  },
                  model: {
                    value: _vm.form.create_at,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "create_at", $$v)
                    },
                    expression: "form.create_at",
                  },
                }),
              ],
              1
            ),
            _vm.style == 1
              ? _c(
                  "div",
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { label: "备注", prop: "remark" },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "530px" },
                          model: {
                            value: _vm.form.remark,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "remark", $$v)
                            },
                            expression: "form.remark",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "100%", "font-weight": "600" },
                        attrs: { label: "订购总额", prop: "total_amount" },
                      },
                      [
                        _c("el-input", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.form.total_amount,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "total_amount",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.total_amount",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "销售金额", prop: "sale_amount" } },
                      [
                        _c("el-input", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.form.sale_amount,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "sale_amount",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.sale_amount",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "使用预存款", prop: "deposit_amount" },
                      },
                      [
                        _c("el-input", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.form.deposit_amount,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "deposit_amount",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.deposit_amount",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "还货金额", prop: "return_amount" } },
                      [
                        _c("el-input", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.form.return_amount,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "return_amount",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.return_amount",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "优惠后", prop: "discounted_amount" } },
                      [
                        _c("el-input", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.form.discounted_amount,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "discounted_amount",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.discounted_amount",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "退货金额",
                          prop: "refund_goods_amount",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.form.refund_goods_amount,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "refund_goods_amount",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.refund_goods_amount",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "退货方式", prop: "refund_type_text" },
                      },
                      [
                        _c("el-input", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.form.refund_type_text,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "refund_type_text", $$v)
                            },
                            expression: "form.refund_type_text",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "30%" },
                        attrs: { label: "退货退款", prop: "refund_amount" },
                      },
                      [
                        _c("el-input", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.form.refund_amount,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "refund_amount",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.refund_amount",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "费用抵扣", prop: "support_amount" } },
                      [
                        _c("el-input", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.form.support_amount,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "support_amount",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.support_amount",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "应收款", prop: "receiv_money" } },
                      [
                        _c("el-input", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.form.receiv_money,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "receiv_money",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.receiv_money",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "60%" },
                        attrs: { label: "结算方式", prop: "account_type_text" },
                      },
                      [
                        _c("el-input", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.form.account_type_text,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "account_type_text", $$v)
                            },
                            expression: "form.account_type_text",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm.style == 2
              ? _c(
                  "div",
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "兑奖现金:", prop: "prize_amount" } },
                      [
                        _c("el-input", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.form.prize_amount,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "prize_amount",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.prize_amount",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm.style == 3
              ? _c(
                  "div",
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "兑付现金:", prop: "cash_amount" } },
                      [
                        _c("el-input", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.form.cash_amount,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "cash_amount",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.cash_amount",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _vm.btnShow
          ? _c(
              "div",
              {
                staticClass: "dialog-footer",
                staticStyle: { "text-align": "right" },
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _vm.btnShow.pre_process_door == 0 &&
                _vm.btnShow.distribute_only == 0
                  ? [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "warning" },
                          on: {
                            click: function ($event) {
                              return _vm.save(1)
                            },
                          },
                        },
                        [_vm._v(" 派单并生成调拨单 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.save()
                            },
                          },
                        },
                        [_vm._v("派单")]
                      ),
                    ]
                  : _vm.btnShow.pre_process_door == 0 &&
                    _vm.btnShow.distribute_only == 1
                  ? [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "warning" },
                          on: {
                            click: function ($event) {
                              return _vm.save(1)
                            },
                          },
                        },
                        [_vm._v(" 派单并生成调拨单 ")]
                      ),
                    ]
                  : _vm._e(),
                _vm.btnShow.pre_process_door == 1
                  ? [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.save()
                            },
                          },
                        },
                        [_vm._v("派单")]
                      ),
                    ]
                  : _vm._e(),
                _c("el-button", { on: { click: _vm.close } }, [
                  _vm._v("取 消"),
                ]),
              ],
              2
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }