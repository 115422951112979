<!--
 * @Author: 候怀烨
 * @Date: 2020-12-29 10:57:09
 * @LastEditTime: 2021-02-24 15:07:20
 * @LastEditors: Please set LastEditors
 * @Description: 对账交账
 * @FilePath: \sd-vue-admin\src\views\project\sale\control\sale\components\components\AccountReconciliation.vue
-->
<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="10">
        <h5 class="text">订单金额汇总</h5>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-form inline>
            <el-form-item
              v-for="(lists, index) in account.huizong2.list"
              :key="index"
              :label="lists.key"
            >
              <el-input
                :value="lists.value"
                disabled
                style="width: 120px"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-row>
        <h5 class="text">结算汇总</h5>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-form
            ref="form"
            inline
            :label-position="labelPosition"
            class="demo-dynamic"
          >
            <el-form-item
              v-for="(lists, index) in account.huizong1.list"
              :key="index"
              :label="lists.key"
            >
              <el-input
                :value="lists.value"
                disabled
                style="width: 120px"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-row>
      </el-col>
      <el-col :span="12">
        <el-table stripe :data="account.mx_receiv" height="500">
          <el-table-column
            label="收款方式"
            align="center"
            prop="name"
          ></el-table-column>
          <el-table-column
            label="金额"
            align="center"
            prop="money"
          ></el-table-column>
        </el-table>
        <!-- <h5>入账记录：</h5>
        <el-table stripe :data="tableData" height="250">
          <el-table-column
            label="入账金额"
            align="center"
            prop="money"
          ></el-table-column>
          <el-table-column
            label="入账账户"
            align="center"
            prop=""
          ></el-table-column>
          <el-table-column
            label="时间"
            align="center"
            prop="add_time"
          ></el-table-column>
        </el-table> -->
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <el-row>
      <el-col :span="15">
        <el-form inline>
          <el-form-item label="应交账：">
            <el-input
              :value="account.res_account.account_payable"
              disabled
              style="width: 120px"
            ></el-input>
          </el-form-item>
          <el-form-item label="已入账：">
            <el-input
              :value="account.res_account.account_paid"
              disabled
              style="width: 120px"
            ></el-input>
          </el-form-item>
          <el-form-item label="待入账：">
            <el-input
              :value="account.res_account.account_unpaid"
              disabled
              style="width: 120px"
            ></el-input>
          </el-form-item>

          <!-- <el-form-item label="入账金额：">
            <el-input v-model="pay_data.money" style="width: 120px"></el-input>
          </el-form-item>
          <el-form-item label="入账账户：">
            <el-select v-model="pay_data.pay_type" placeholder="请选择">
              <el-option
                v-for="item in pay_type"
                :key="item.value"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <i class="el-icon-circle-plus icon" @click="ruzhangtijao"></i>
          </el-form-item> -->
          <h5>入账记录：</h5>
          <el-table stripe :data="tableData">
            <el-table-column label="入账金额" align="center" prop="money">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.money"
                  placeholder="请输入入账金额"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="入账账户" align="center" prop="">
              <template slot-scope="scope">
                <el-select v-model="scope.row.id" placeholder="请选择">
                  <el-option
                    v-for="item in paydata"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              label="时间"
              align="center"
              prop="add_time"
            ></el-table-column>
            <el-table-column label="操作" align="center" prop="money">
              <template slot-scope="scope">
                <el-button type="text" @click="handlerAdd(scope.$index)">
                  添 加
                </el-button>
                <el-button type="text" @click="handlerRemove(scope.$index)">
                  删 除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { dateForm } from '@/utils/Time'
  import { postAction } from '@/api/Employee'
  export default {
    props: {
      paydata: {
        type: Array,
        default: () => [],
      },
      account: {
        type: Object,
        default: () => {},
      },
      row: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        a: '',
        labelPosition: 'right',

        url: {
          // paydata: '/baseAdmin/common/account',
          paydata: '/saleAdmin/duizhang/add',
        },
        tableData: [
          {
            id: '',
            money: '',
            add_time: dateForm(new Date()),
          },
        ],
      }
    },
    computed: {
      // tableData() {
      //   console.log('this.paydata[0].id', this.paydata[0].id)
      //   return {
      //     id: this.paydata[0].id,
      //     money: '',
      //     add_time: dateForm(new Date()),
      //   }
      // },
    },
    methods: {
      handlerRemove(index) {
        if (this.tableData.length > 1) {
          this.tableData.splice(index, 1)
        } else {
          this.$message({
            message: '不能删除最后一条哦',
            type: 'warning',
          })
        }
      },
      handlerAdd(index) {
        var a = {
          id: '',
          money: '',
          add_time: dateForm(new Date()),
        }
        this.tableData.splice(index + 1, 0, a)
      },
      ruzhangtijao() {
        var data = new Date()

        postAction(this.url.paydata, {
          end_time: dateForm(data),
          user_id: this.row.id,
          pay_data: JSON.stringify(this.tableData),
        })
          .then((res) => {
            console.log(res, '')
            postAction('/saleAdmin/duizhang/ruzhang-list', {
              duizhang_id: res.data.id,
            })
              .then((res) => {
                console.log(res, '')
                this.tableData = res.data
              })
              .catch((err) => {
                console.log(err)
              })
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handlerlist(row) {
        if (row.length > 0) {
          this.tableData = row
        } else {
        }
      },
    },
  }
</script>

<style scoped>
  .text {
    text-align: center;
  }
  .icon {
    font-size: 30px;
    color: #9bf804;
  }
</style>
