<template>
  <div class="orderTest-container">
    <el-form :model="form" inline>
      <el-form-item prop="class_id">
        <el-select
          v-model="form.class_id"
          clearable
          :popper-class="'select-idx'"
          placeholder="请选择品类"
          style="width: 120px"
        >
          <el-option
            v-for="(item, index) in classlist"
            :key="index"
            :label="item.class_name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="brand_id">
        <el-select
          v-model="form.brand_id"
          clearable
          placeholder="请选择品牌"
          style="width: 120px"
        >
          <el-option
            v-for="(item, index) in brandlist"
            :key="index"
            :label="item.brand_name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="keyword">
        <goodslist
          :f-key="form.keyword"
          @select-goods-all="selectGoods($event)"
          @choose-goods-keyword="keyname"
        ></goodslist>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerQuery">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleraddreturn">添加</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="tabledata"
      :row-key="getRowKey"
      @selection-change="setSelectRows"
    >
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <!-- 选择框 -->
      <el-table-column
        align="center"
        :reserve-selection="true"
        type="selection"
        width="50"
      ></el-table-column>
      <el-table-column
        v-for="(item, index) in finallyColumns"
        :key="index"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :sortable="item.sortable"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label == '关联商品1'">
            <goods-search
              :goods_keyword="row.goods_name1"
              :f-key="row.goods_name1"
              :goods-id="row.goods_id1"
              :search="false"
              @select-goods-all="handlerselectGoods($event, row, 1)"
            />
            <!--          @add-rows="handleraddRows"-->
          </div>
          <div v-else-if="item.label == '关联商品2'">
            <goods-search
              :goods_keyword="row.goods_name2"
              :f-key="row.goods_name2"
              :goods-id="row.goods_id2"
              :search="false"
              @select-goods-all="handlerselectGoods($event, row, 2)"
            />
            <!--          @add-rows="handleraddRows"-->
          </div>
          <div v-else-if="item.label == '关联商品3'">
            <goods-search
              :goods_keyword="row.goods_name3"
              :f-key="row.goods_name3"
              :goods-id="row.goods_id3"
              :search="false"
              @select-goods-all="handlerselectGoods($event, row, 3)"
            />
            <!--          @add-rows="handleraddRows"-->
          </div>
          <div v-else-if="item.label == '关联商品4'">
            <goods-search
              :goods_keyword="row.goods_name4"
              :f-key="row.goods_name4"
              :goods-id="row.goods_id4"
              :search="false"
              @select-goods-all="handlerselectGoods($event, row, 4)"
            />
            <!--          @add-rows="handleraddRows"-->
          </div>
          <div v-else-if="item.label == '关联商品5'">
            <goods-search
              :goods_keyword="row.goods_name5"
              :f-key="row.goods_name5"
              :goods-id="row.goods_id5"
              :search="false"
              @select-goods-all="handlerselectGoods($event, row, 5)"
            />
            <!--          @add-rows="handleraddRows"-->
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column v-slot="{ row }" label="操作" align="center">
        <el-button type="text" @click="handlerdelete(row)">删除</el-button>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <goods-search-dialog
      ref="goodsSearchDialog"
      @add-rows="addRows"
      @change-goods="setInput"
    ></goods-search-dialog>
  </div>
</template>

<script>
  import goodslist from '@/components/goodslist/index.vue'
  import { classList } from '@/api/selectList'
  import { postAction } from '@/api/Employee'
  import GoodsSearchDialog from '@/baseComponents/goodsChooseDialog'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import _ from 'lodash'

  export default {
    name: 'index.vue',
    components: {
      GoodsSearch,
      goodslist,
      GoodsSearchDialog,
    },
    data() {
      return {
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        form: {
          class_id: null,
          brand_id: null,
          keyword: '',
          goods_id: '',
          pageNo: 1,
          pageSize: 10,
        },
        brandlist: [],
        classlist: [],
        tabledata: [],
        columns: [
          {
            order: 1,
            width: '140',
            label: '退货商品',
            prop: 'goods_name',
          },
          {
            order: 2,
            width: '120',
            label: '规格',
            prop: 'specs',
          },
          {
            order: 3,
            width: '',
            label: '关联商品1',
            prop: 'supp_contact',
          },
          {
            order: 4,
            width: '',
            label: '关联商品2',
            prop: 'supp_phone',
          },
          {
            order: 5,
            width: '',
            label: '关联商品3',
            prop: 'brand_count',
          },
          {
            order: 6,
            width: '',
            label: '关联商品4',
            prop: 'is_close',
          },
          {
            order: 7,
            width: '',
            label: '关联商品5',
            prop: 'is_close',
          },
        ],
        checkList: [
          '退货商品',
          '规格',
          '关联商品1',
          '关联商品2',
          '关联商品3',
          '关联商品4',
          '关联商品5',
        ],
        selectRows: [],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    async mounted() {
      await classList().then((res) => {
        this.classlist = res.data
      })
      await postAction('/baseAdmin/common/brand-list', {}).then((res) => {
        this.brandlist = res.data
      })
      await this.handlerlist()
    },
    methods: {
      handlerdelete(val) {
        console.log(val)
        postAction('/saleAdmin/return-goods/rel-delete', {
          goods_id: val.goods_id,
        }).then((res) => {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.handlerlist()
        })
      },
      handlerselectGoods(val, row, type) {
        if (val.id) {
          console.log(val, row, type)
          if (type == 1) {
            row.goods_id1 = val.goods_id
            row.goods_name1 = val.goods_name
            row.goods_specs1 = val.specs
          } else if (type == 2) {
            row.goods_id2 = val.goods_id
            row.goods_name2 = val.goods_name
            row.goods_specs2 = val.specs
          } else if (type == 3) {
            row.goods_id3 = val.goods_id
            row.goods_name3 = val.goods_name
            row.goods_specs3 = val.specs
          } else if (type == 4) {
            row.goods_id4 = val.goods_id
            row.goods_name4 = val.goods_name
            row.goods_specs4 = val.specs
          } else if (type == 5) {
            row.goods_id5 = val.goods_id
            row.goods_name5 = val.goods_name
            row.goods_specs5 = val.specs
          }
          var data = [
            {
              id: 0,
              goods_id: val.goods_id,
              goods_name: val.goods_name,
              goods_specs: val.specs,
            },
          ]
          data = JSON.stringify(data)
          postAction('/saleAdmin/return-goods/rel-goods-set', {
            goods_id: row.goods_id,
            sort: type,
            goods: data,
          }).then((res) => {
            this.$message({
              type: 'success',
              message: res.msg,
            })
          })
        }
      },
      handleraddreturn() {
        this.$refs.goodsSearchDialog.showDialog = true
      },
      handlerQuery() {
        this.form.pageNo = 1
        this.handlerlist()
      },
      handlerlist() {
        postAction('/saleAdmin/return-goods/rel-list', this.form).then(
          (res) => {
            this.tabledata = res.data
            this.total = Number(res.totalCount)
          }
        )
      },
      keyname(val) {
        console.log(val, '商品名称')
        this.form.keyword = val
      },
      selectGoods(row) {
        console.log('商品', row)
        if (row.goods_id == undefined) {
          this.form.goods_id = -1
        } else {
          this.form.goods_id = row.goods_id
        }
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.handlerlist()
      },
      async addRows(val) {
        console.log(val[0].goods_id)
        await postAction('/saleAdmin/return-goods/rel-goods-set', {
          goods_id: val[0].goods_id,
        }).then((res) => {
          console.log(res)
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.handlerlist()
        })
      },
      setInput(val) {
        console.log(val)
      },
      // 勾选表格触发事件
      setSelectRows(val) {
        console.log(val)
        this.selectRows = val
      },
      getRowKey(row) {
        return row.goods_id
      },
    },
  }
</script>

<style scoped></style>
