var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "icon-selector-container" },
    [
      _c("el-alert", {
        attrs: {
          closable: false,
          title: "图标选择器演示",
          "show-icon": "",
          type: "success",
        },
      }),
      _c(
        "el-popover",
        {
          attrs: {
            "popper-class": "icon-selector-popper",
            width: "292",
            trigger: "hover",
          },
        },
        [
          _c(
            "el-button",
            { attrs: { slot: "reference" }, slot: "reference" },
            [
              _c("vab-remix-icon", { attrs: { icon: _vm.icon } }),
              _vm._v(" 图标选择器 "),
              _c("vab-remix-icon", { attrs: { icon: "arrow-down-s-line" } }),
            ],
            1
          ),
          _c("icon-table", { on: { "handle-icon": _vm.handleIcon } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }