<template>
  <div>
    <el-dialog :modal="false" :visible.sync="show" width="70%">
      <el-descriptions>
        <el-descriptions-item label="客户名称">
          {{ queryForm.cust_name }}
        </el-descriptions-item>
        <el-descriptions-item label="联系人">
          {{ queryForm.boss }}
        </el-descriptions-item>
        <el-descriptions-item label="电话">
          {{ queryForm.mobile }}
        </el-descriptions-item>
        <el-descriptions-item label="归属业务员">
          {{ queryForm.user_name }}
        </el-descriptions-item>
        <el-descriptions-item label="时间">
          {{ queryForm.start_time }}至{{ queryForm.end_time }}
        </el-descriptions-item>
      </el-descriptions>
      <el-table v-loading="listLoading" :data="tabledata" style="width: 100%">
        <el-table-column
          v-for="(list, index) in columns"
          :key="index"
          :prop="list.prop"
          :label="list.label"
          :width="list.width"
          align="center"
        >
          <template #default="{ row }">
            <div v-if="list.label == '内容/单号'">
              <span style="cursor: pointer" @click="handlerinfo(row)">
                {{ row[list.prop] }}
              </span>
            </div>
            <div v-else>
              <span>{{ row[list.prop] }}</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="queryForm.pageNo"
        :layout="layout"
        :page-size="queryForm.pageSize"
        :total="total"
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      ></el-pagination>
      <check-order ref="checkOrder"></check-order>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handlerExpotr">导出</el-button>
        <el-button @click="show = !show">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { downloadFile, postAction } from '@/api/Employee'
  import CheckOrder from '@/views/project/sale/bills/advanceOrder/components/checkOrder'

  export default {
    name: 'LinkInfo',
    components: {
      CheckOrder,
    },
    data() {
      return {
        show: false,
        total: 0,
        listLoading: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
        },
        tabledata: [],
        columns: [
          {
            order: 2,
            label: '操作',
            width: '160',
            prop: 'type',

            sortable: false,
          },
          {
            order: 3,
            label: '内容/单号',
            width: '160',
            prop: 'con',
            sortable: false,
          },
          {
            order: 4,
            label: '金额',
            width: '',
            prop: 'order_money',
            sortable: false,
          },
          {
            order: 5,
            label: '时间',
            width: '',
            prop: 'create_at',
            sortable: false,
          },
        ],
      }
    },
    methods: {
      handlerExpotr() {
        downloadFile(
          '/shopAdmin/operate/shop-visit-log-detail-export',
          '浏览记录详情.xlsx',
          this.queryForm
        )
      },
      fetchData(res) {
        Object.assign(this.queryForm, res)
        console.log(this.queryForm)
        postAction('/shopAdmin/operate/shop-visit-log-detail', this.queryForm)
          .then((res) => {
            console.log(res)
            this.tabledata = res.data
            this.total = Number(res.totalCount)
          })
          .catch((err) => {
            console.error(err)
          })
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      handlerinfo(row) {
        if (row.order_id) {
          this.$refs['checkOrder'].showDialog()
          let text = '完成'

          this.$refs['checkOrder'].orderStatus2 = text
          // this.$refs['checkOrder'].orderStatus = row.bill_status_text
          this.$refs['checkOrder'].id = row.order_id
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
    },
  }
</script>

<style scoped></style>
