var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: _vm.title,
        visible: _vm.showDialog,
        width: "1000px",
        top: "5vh",
        "close-on-click-modal": false,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "orderTest-container",
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                inline: "",
                "label-position": "right",
                "label-width": "80px",
              },
            },
            [
              _c(
                "div",
                { staticStyle: { "margin-left": "20px" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "方案名称:", prop: "title" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        model: {
                          value: _vm.form.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "title", $$v)
                          },
                          expression: "form.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "el-form-item",
                    { attrs: { label: "提成规则:", prop: "type" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "提成规则" },
                          model: {
                            value: _vm.form.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type",
                          },
                        },
                        _vm._l(_vm.rulesList, function (i, idx) {
                          return _c("el-option", {
                            key: idx,
                            attrs: { value: i.id, label: i.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                { attrs: { stripe: "", data: _vm.form.scheme_list } },
                [
                  _vm._l(_vm.finallyColumns, function (item, tableIndex) {
                    return _c("el-table-column", {
                      key: tableIndex,
                      attrs: {
                        prop: item.prop,
                        label: item.label,
                        width: "",
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              var $index = ref.$index
                              return [
                                item.prop == "a"
                                  ? _c("div", [
                                      _c("span", [_vm._v(_vm._s(row.more))]),
                                      _vm._v(" <数值< "),
                                      _c("span", [_vm._v(_vm._s(row.less))]),
                                      _c("i", {
                                        staticClass: "el-icon-edit-outline",
                                        staticStyle: {
                                          "margin-left": "10px",
                                          "font-size": "15px",
                                          cursor: "pointer",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeNum(row, $index)
                                          },
                                        },
                                      }),
                                    ])
                                  : _c(
                                      "div",
                                      [
                                        _c("el-input", {
                                          model: {
                                            value: row[item.prop],
                                            callback: function ($$v) {
                                              _vm.$set(row, item.prop, $$v)
                                            },
                                            expression: "row[item.prop]",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var $index = ref.$index
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.handleAddRow($index, row)
                                  },
                                },
                              },
                              [_vm._v(" 添加 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.handleDelete($index, row)
                                  },
                                },
                              },
                              [_vm._v(" 删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c("div", { staticClass: "list" }, [
            _c("div", { staticClass: "df" }, [
              _c("div", { staticStyle: { width: "150px" } }, [
                _vm._v("限定品类"),
              ]),
              _c(
                "div",
                { staticStyle: { width: "100%" } },
                _vm._l(_vm.form.class_list, function (item, index) {
                  return _c(
                    "span",
                    { key: index, staticStyle: { "margin-right": "5px" } },
                    [_vm._v(" " + _vm._s(item.sort_name) + " ")]
                  )
                }),
                0
              ),
              _c(
                "div",
                { staticStyle: { width: "250px" } },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "text" }, on: { click: _vm.handleSetB } },
                    [_vm._v("设置")]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "df",
                staticStyle: { "border-top": "1px solid #ebeef5" },
              },
              [
                _c("div", { staticStyle: { width: "150px" } }, [
                  _vm._v("限定品牌"),
                ]),
                _c(
                  "div",
                  { staticStyle: { width: "100%" } },
                  _vm._l(_vm.form.brand_list, function (item, index) {
                    return _c("span", { key: index }, [
                      _vm._v(" " + _vm._s(item.sort_name) + " "),
                    ])
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticStyle: { width: "250px" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: { click: _vm.handleSetB },
                      },
                      [_vm._v("设置")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _vm.form.user_list != undefined
            ? _c("div", { staticStyle: { "margin-top": "20px" } }, [
                _c("span", [_vm._v("限定员工:")]),
                _c(
                  "span",
                  {
                    staticStyle: {
                      margin: "0 10px",
                      "text-decoration": "underline",
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.form.user_list.length) + " ")]
                ),
                _c(
                  "span",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.setStaff },
                      },
                      [_vm._v("设置")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-top": "15px", "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
                [_vm._v("保存")]
              ),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            title: "数值范围",
            visible: _vm.showDialog2,
            width: "400px",
            top: "18vh",
            "close-on-click-modal": false,
            "destroy-on-close": "",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog2 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "orderTest-container" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form2,
                    inline: "",
                    "label-position": "right",
                    "label-width": "70px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "大于", prop: "" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form2.da,
                          callback: function ($$v) {
                            _vm.$set(_vm.form2, "da", $$v)
                          },
                          expression: "form2.da",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "小于", prop: "" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form2.xiao,
                          callback: function ($$v) {
                            _vm.$set(_vm.form2, "xiao", $$v)
                          },
                          expression: "form2.xiao",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  staticStyle: { "margin-top": "5px", "text-align": "right" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSaveNum },
                    },
                    [_vm._v("保存")]
                  ),
                  _c("el-button", { on: { click: _vm.close2 } }, [
                    _vm._v("取 消"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("set-b", { ref: "setB", on: { "set-brand-class": _vm.setBC } }),
      _c("set-staff", {
        ref: "setStaff",
        attrs: {
          zidingyi: false,
          showbumen: false,
          showzhineng: true,
          title: "绑定员工",
        },
        on: { rtd: _vm.setStaffList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }