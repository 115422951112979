<template>
  <el-dialog
    :modal="false"
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="用户名" prop="username">
        <el-input v-model.trim="form.username"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input v-model.trim="form.password" type="password"></el-input>
      </el-form-item>
      <el-form-item label="昵称" prop="nickname">
        <el-input v-model.trim="form.nickname"></el-input>
      </el-form-item>

      <el-form-item label="真实姓名" prop="truename">
        <el-input v-model.trim="form.truename"></el-input>
      </el-form-item>
      <el-form-item label="角色" prop="roles">
        <el-checkbox-group v-model="form.roles">
          <el-checkbox
            v-for="(role, index) in rolesList"
            :key="index"
            :label="role.authorityName"
          >
            {{ role.authorityName }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <!-- <el-form-item label="角色" prop="roles">
        <el-checkbox-group v-model="form.roles">
          <el-checkbox
            v-for="role in rolesList"
            :key="role.authorityId"
            :label="role.authorityName"
          >
            {{ role.authorityName }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item> -->
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { doEdit } from '@/api/userManagement'
  import { getList } from '@/api/roleManagement'

  export default {
    name: 'UserManagementEdit',
    data() {
      return {
        test: {},
        form: {
          roles: [],
          nickname: '',
          truename: '',
          username: '',
          password: '',
        },
        rules: {
          username: [
            { required: true, trigger: 'blur', message: '请输入用户名' },
          ],
          password: [
            { required: true, trigger: 'blur', message: '请输入密码' },
          ],
          email: [{ required: true, trigger: 'blur', message: '请输入邮箱' }],
          roles: [{ required: true, trigger: 'blur', message: '请选择角色' }],
        },
        title: '',
        dialogFormVisible: false,
        rolesList: [],
      }
    },
    watch: {
      dialogFormVisible(val) {
        if (val) {
          this.fetchData()
        }
      },
    },
    mounted() {
      // this.fetchData()
    },
    methods: {
      async fetchData() {
        const { data } = await getList({ pageSize: 100 })
        console.log(data)
        this.rolesList = data
      },
      showEdit(row) {
        console.log(row)
        if (row.id) {
          this.title = '编辑'
          this.form = Object.assign({ edit: 1, roles: [] }, row)
        } else {
          this.title = '添加'
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const { msg } = await doEdit(this.form)
            this.$baseMessage(msg, 'success')
            this.$emit('fetch-data')
            this.close()
          } else {
            return false
          }
        })
      },
    },
  }
</script>
