var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h5", [_vm._v("车存变化：")]),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { stripe: "", data: _vm.chelists.mx_list },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "品牌",
              align: "center",
              prop: "brand_name",
              width: "120",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "商品名称",
              align: "center",
              prop: "goods_name",
              width: "120",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "上次车存",
              align: "center",
              prop: "last_num",
              width: "120",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "调入",
              align: "center",
              prop: "in_num",
              width: "120",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "客户退货",
              align: "center",
              prop: "return_num",
              width: "120",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "调出",
              align: "center",
              prop: "out_num",
              width: "120",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "还货",
              align: "center",
              prop: "pre_goods_num",
              width: "120",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "预存款",
              align: "center",
              prop: "pre_num",
              width: "120",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "兑奖",
              align: "center",
              prop: "prize_num",
              width: "120",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "赠送",
              align: "center",
              prop: "gift_num",
              width: "120",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "销售",
              align: "center",
              prop: "sale_num",
              width: "120",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "应剩车存",
              align: "center",
              prop: "left_num",
              width: "120",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "当前车存",
              align: "center",
              prop: "now_num",
              width: "120",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }