var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "自定义客户组",
        visible: _vm.showDialog,
        width: "1100px",
        top: "10vh",
        "close-on-click-modal": false,
        "destroy-on-close": "",
        modal: false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c("div", { staticClass: "orderTest-container" }, [
        _c("div", { staticClass: "wrap" }, [
          _c(
            "div",
            { staticClass: "left" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { float: "right", "margin-bottom": "10px" },
                  attrs: { icon: "el-icon-edit", type: "primary" },
                },
                [_vm._v(" 添加分组 ")]
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading1,
                      expression: "loading1",
                    },
                  ],
                  attrs: { border: "", stripe: "", data: _vm.list1 },
                  on: { "cell-click": _vm.cellClick },
                },
                [
                  _vm._l(_vm.columns1, function (item, tableIndex) {
                    return _c("el-table-column", {
                      key: tableIndex,
                      attrs: {
                        prop: item.prop,
                        label: item.label,
                        width: item.width,
                        align: "center",
                      },
                    })
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "",
                      align: "center",
                      label: "操作",
                      fixed: "right",
                      width: "60px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          var $index = ref.$index
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.tableDelete(
                                      row,
                                      _vm.list1,
                                      $index
                                    )
                                  },
                                },
                              },
                              [_vm._v(" 删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c("div", { staticClass: "r-top" }, [
                _c(
                  "div",
                  [
                    _c("span", { staticStyle: { "margin-right": "10px" } }, [
                      _vm._v("客户组名称:"),
                    ]),
                    _c("el-input", { staticStyle: { width: "200px" } }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      { attrs: { icon: "el-icon-edit", type: "primary" } },
                      [_vm._v("添加客户")]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading2,
                      expression: "loading2",
                    },
                  ],
                  attrs: { border: "", stripe: "", data: _vm.list2 },
                },
                [
                  _vm._l(_vm.columns2, function (item, tableIndex) {
                    return _c("el-table-column", {
                      key: tableIndex,
                      attrs: {
                        prop: item.prop,
                        label: item.label,
                        width: item.width,
                        align: "center",
                      },
                    })
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "",
                      align: "center",
                      label: "操作",
                      fixed: "right",
                      width: "60px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          var $index = ref.$index
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.tableDelete(
                                      row,
                                      _vm.list2,
                                      $index
                                    )
                                  },
                                },
                              },
                              [_vm._v(" 删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }