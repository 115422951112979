var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-form",
        { attrs: { model: _vm.form, inline: "" } },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "class_id" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "120px" },
                  attrs: {
                    clearable: "",
                    "popper-class": "select-idx",
                    placeholder: "请选择品类",
                  },
                  model: {
                    value: _vm.form.class_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "class_id", $$v)
                    },
                    expression: "form.class_id",
                  },
                },
                _vm._l(_vm.classlist, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.class_name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "brand_id" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "120px" },
                  attrs: { clearable: "", placeholder: "请选择品牌" },
                  model: {
                    value: _vm.form.brand_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "brand_id", $$v)
                    },
                    expression: "form.brand_id",
                  },
                },
                _vm._l(_vm.brandlist, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.brand_name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "keyword" } },
            [
              _c("goodslist", {
                attrs: { "f-key": _vm.form.keyword },
                on: {
                  "select-goods-all": function ($event) {
                    return _vm.selectGoods($event)
                  },
                  "choose-goods-keyword": _vm.keyname,
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlerQuery } },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleraddreturn },
                },
                [_vm._v("添加")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          attrs: { data: _vm.tabledata, "row-key": _vm.getRowKey },
          on: { "selection-change": _vm.setSelectRows },
        },
        [
          _c(
            "el-table-column",
            { attrs: { align: "center", type: "index", width: "50" } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        "popper-class": "custom-table-checkbox",
                        trigger: "hover",
                      },
                    },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.checkList,
                            callback: function ($$v) {
                              _vm.checkList = $$v
                            },
                            expression: "checkList",
                          },
                        },
                        _vm._l(_vm.columns, function (item, index) {
                          return _c("el-checkbox", {
                            key: index,
                            attrs: { label: item.label },
                          })
                        }),
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { slot: "reference", type: "text" },
                          slot: "reference",
                        },
                        [
                          _c("vab-remix-icon", {
                            attrs: { icon: "settings-line" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "reserve-selection": true,
              type: "selection",
              width: "50",
            },
          }),
          _vm._l(_vm.finallyColumns, function (item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: {
                align: "center",
                label: item.label,
                prop: item.prop,
                sortable: item.sortable,
                width: item.width,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        item.label == "关联商品1"
                          ? _c(
                              "div",
                              [
                                _c("goods-search", {
                                  attrs: {
                                    goods_keyword: row.goods_name1,
                                    "f-key": row.goods_name1,
                                    "goods-id": row.goods_id1,
                                    search: false,
                                  },
                                  on: {
                                    "select-goods-all": function ($event) {
                                      return _vm.handlerselectGoods(
                                        $event,
                                        row,
                                        1
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : item.label == "关联商品2"
                          ? _c(
                              "div",
                              [
                                _c("goods-search", {
                                  attrs: {
                                    goods_keyword: row.goods_name2,
                                    "f-key": row.goods_name2,
                                    "goods-id": row.goods_id2,
                                    search: false,
                                  },
                                  on: {
                                    "select-goods-all": function ($event) {
                                      return _vm.handlerselectGoods(
                                        $event,
                                        row,
                                        2
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : item.label == "关联商品3"
                          ? _c(
                              "div",
                              [
                                _c("goods-search", {
                                  attrs: {
                                    goods_keyword: row.goods_name3,
                                    "f-key": row.goods_name3,
                                    "goods-id": row.goods_id3,
                                    search: false,
                                  },
                                  on: {
                                    "select-goods-all": function ($event) {
                                      return _vm.handlerselectGoods(
                                        $event,
                                        row,
                                        3
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : item.label == "关联商品4"
                          ? _c(
                              "div",
                              [
                                _c("goods-search", {
                                  attrs: {
                                    goods_keyword: row.goods_name4,
                                    "f-key": row.goods_name4,
                                    "goods-id": row.goods_id4,
                                    search: false,
                                  },
                                  on: {
                                    "select-goods-all": function ($event) {
                                      return _vm.handlerselectGoods(
                                        $event,
                                        row,
                                        4
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : item.label == "关联商品5"
                          ? _c(
                              "div",
                              [
                                _c("goods-search", {
                                  attrs: {
                                    goods_keyword: row.goods_name5,
                                    "f-key": row.goods_name5,
                                    "goods-id": row.goods_id5,
                                    search: false,
                                  },
                                  on: {
                                    "select-goods-all": function ($event) {
                                      return _vm.handlerselectGoods(
                                        $event,
                                        row,
                                        5
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handlerdelete(row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.form.pageNo,
          layout: _vm.layout,
          "page-size": _vm.form.pageSize,
          total: _vm.total,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("goods-search-dialog", {
        ref: "goodsSearchDialog",
        on: { "add-rows": _vm.addRows, "change-goods": _vm.setInput },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }