import request from '@/utils/request'

export function getList(data) {
  return request({
    url: '/auth/user/index',
    method: 'get',
    data,
  })
}

export function doEdit(data) {
  return request({
    url: '/auth/user/edit',
    method: 'post',
    data,
  })
}

export function doDelete(data) {
  return request({
    url: '/auth/user/delete',
    method: 'post',
    data,
  })
}
