var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: "限定品类、品牌",
        visible: _vm.showDialog,
        width: "800px",
        top: "15vh",
        "close-on-click-modal": false,
        "destroy-on-close": "",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c("div", { staticClass: "orderTest-container" }, [
        _c(
          "div",
          {
            staticStyle: { display: "flex", "justify-content": "space-around" },
          },
          [
            _c(
              "div",
              { staticStyle: { width: "262px" } },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.lTableLoding,
                        expression: "lTableLoding",
                      },
                    ],
                    ref: "leftTable",
                    attrs: {
                      stripe: "",
                      data: _vm.classList,
                      border: "",
                      height: "500px",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "selection",
                        align: "center",
                        width: "50",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "class_name",
                        label: "品类",
                        align: "center",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { width: "380px" } },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.rTableLoding,
                        expression: "rTableLoding",
                      },
                    ],
                    ref: "rightTable",
                    attrs: {
                      stripe: "",
                      data: _vm.brandList,
                      border: "",
                      height: "500px",
                      "row-key": "id",
                      "default-expand-all": "",
                      indent: 35,
                      "tree-props": {
                        children: "children",
                        hasChildren: "hasChildren",
                      },
                    },
                    on: { select: _vm.rightTableSelect },
                  },
                  [
                    _vm._v(" > "),
                    _c("el-table-column", {
                      attrs: {
                        type: "selection",
                        align: "center",
                        width: "50",
                      },
                    }),
                    _c(
                      "el-table-column",
                      {
                        attrs: {
                          prop: "brand_name",
                          label: "品牌",
                          align: "center",
                        },
                      },
                      [
                        _c("template", { slot: "header" }, [
                          _c("span", { staticStyle: { cursor: "pointer" } }, [
                            _vm._v("品牌"),
                          ]),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
            [_vm._v("保存")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }