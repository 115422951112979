var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-checkbox",
        {
          attrs: { indeterminate: _vm.isIndeterminate },
          on: { change: _vm.handleCheckAllChange },
          model: {
            value: _vm.checkAll,
            callback: function ($$v) {
              _vm.checkAll = $$v
            },
            expression: "checkAll",
          },
        },
        [_vm._v(" 全选 ")]
      ),
      _c(
        "el-checkbox-group",
        {
          staticClass: "bumen-col",
          on: { change: _vm.handleCheckedCitiesChange },
          model: {
            value: _vm.checkedCities,
            callback: function ($$v) {
              _vm.checkedCities = $$v
            },
            expression: "checkedCities",
          },
        },
        _vm._l(_vm.cities, function (city, index) {
          return _c(
            "el-checkbox",
            {
              key: index,
              staticStyle: { padding: "20px 0px" },
              attrs: { label: city.id },
            },
            [_vm._v(" " + _vm._s(city.depot_name) + " ")]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }