<!--
 * @Author: your name
 * @Date: 2020-12-29 19:00:16
 * @LastEditTime: 2020-12-30 15:51:20
 * @LastEditors: Please set LastEditors
 * @Description: 派送明细
 * @FilePath: \sd-vue-admin\src\views\project\sale\control\sale\components\components\DeliveryDetails.vue
-->
<template>
  <div>
    <h5>销售收款：</h5>
    <el-table stripe :data="paisonglist.mx_list" style="width: 100%">
      <el-table-column
        label="客户名称"
        align="center"
        prop="cust_name"
      ></el-table-column>
      <el-table-column
        label="商品名称"
        align="center"
        prop="goods_name"
      ></el-table-column>
      <el-table-column
        label="单位"
        align="center"
        prop="unit_name"
      ></el-table-column>
      <el-table-column
        label="数量"
        align="center"
        prop="goods_num"
      ></el-table-column>
      <el-table-column
        label="价格"
        align="center"
        prop="goods_price"
      ></el-table-column>
      <el-table-column
        label="金额"
        align="center"
        prop="goods_money"
      ></el-table-column>
    </el-table>
  </div>
</template>

<script>
  export default {
    props: {
      paisonglist: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        shoukuan: [],
      }
    },
    methods: {},
  }
</script>

<style></style>
