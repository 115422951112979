var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            "close-on-click-modal": false,
            visible: _vm.showDialog,
            title: "存货单详情",
            top: "5vh",
            width: "1160px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
            close: _vm.close,
          },
        },
        [
          _c("el-alert", {
            staticStyle: {
              width: "86%",
              "margin-top": "-70px",
              "margin-left": "110px",
            },
            attrs: { closable: false, title: _vm.titleTip, type: "success" },
          }),
          _c("div", { staticClass: "orderStatus" }, [
            _vm._v(_vm._s(_vm.orderStatus)),
          ]),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.form, "label-width": "80px", inline: true },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户名称", prop: "cust_name" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.cust_name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "cust_name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.cust_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系人", prop: "boss" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.boss,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "boss",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.boss",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "dz",
                  attrs: { label: "地址", prop: "address" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "380px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.address,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "address",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.address",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "活动名称", prop: "chck" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.chck,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "chck",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.chck",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "业务员", prop: "deliver_name" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.deliver_name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "deliver_name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.deliver_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "签单金额", prop: "staff_name" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.staff_name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "staff_name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.staff_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "已收款", prop: "allot_code" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.allot_code,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "allot_code",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.allot_code",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "sale_time" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.sale_time,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "sale_time",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.sale_time",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "table-wrapper" }, [
            _c(
              "div",
              { staticClass: "table1" },
              [
                _c("div", { staticClass: "tit" }, [_vm._v("存货详情")]),
                _c("el-table"),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "table2" },
              [
                _c("div", { staticClass: "tit" }, [_vm._v("余货一览")]),
                _c("el-table"),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "table3" },
              [
                _c("div", { staticClass: "tit" }, [_vm._v("还货记录")]),
                _c("el-table"),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "table4" },
              [
                _c("div", { staticClass: "tit" }, [_vm._v("收款记录")]),
                _c("el-table"),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.print } }, [_vm._v("打印")]),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }