var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: "查看采购入库单",
        visible: _vm.showDialog,
        width: "1000px",
        top: "5vh",
        "close-on-click-modal": false,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form, inline: "" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "供应商:", prop: "supp_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "180px" },
                      attrs: { clearable: "", placeholder: "请选择供应商" },
                      model: {
                        value: _vm.form.supp_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "supp_id", $$v)
                        },
                        expression: "form.supp_id",
                      },
                    },
                    _vm._l(_vm.gysList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.supp_name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "经办人:", prop: "operator_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "180px" },
                      attrs: { clearable: "", placeholder: "请选择经办人" },
                      model: {
                        value: _vm.form.operator_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "operator_id", $$v)
                        },
                        expression: "form.operator_id",
                      },
                    },
                    _vm._l(_vm.jbrList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.user_name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "入库仓库:", prop: "depot_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "180px" },
                      attrs: { clearable: "", placeholder: "入库仓库" },
                      model: {
                        value: _vm.form.depot_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "depot_id", $$v)
                        },
                        expression: "form.depot_id",
                      },
                    },
                    _vm._l(_vm.ckList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "303px" },
                    attrs: { placeholder: "使用扫码器添加商品" },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.minUnit,
                        callback: function ($$v) {
                          _vm.minUnit = $$v
                        },
                        expression: "minUnit",
                      },
                    },
                    [_vm._v("按小单位采购")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "303px" },
                    attrs: { placeholder: "备注" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "tableSort",
              attrs: { stripe: "", data: _vm.list, height: "360px" },
            },
            [
              _c(
                "el-table-column",
                { attrs: { align: "center", type: "index", width: "50" } },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            "popper-class": "custom-table-checkbox",
                            trigger: "hover",
                          },
                        },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.checkList,
                                callback: function ($$v) {
                                  _vm.checkList = $$v
                                },
                                expression: "checkList",
                              },
                            },
                            _vm._l(_vm.columns, function (item, index) {
                              return _c("el-checkbox", {
                                key: index,
                                attrs: { label: item.label },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { slot: "reference", type: "text" },
                              slot: "reference",
                            },
                            [
                              _c("vab-remix-icon", {
                                attrs: { icon: "settings-line" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._l(_vm.finallyColumns, function (item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    label: item.label,
                    prop: item.prop,
                    width: "",
                    align: "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      item.prop == "goods_type"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "el-select",
                                  {
                                    model: {
                                      value: row.goods_type,
                                      callback: function ($$v) {
                                        _vm.$set(row, "goods_type", $$v)
                                      },
                                      expression: "row.goods_type",
                                    },
                                  },
                                  _vm._l(
                                    _vm.typeSelect,
                                    function (typeItem, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: typeItem.value,
                                          value: typeItem.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ]
                            },
                          }
                        : item.prop == "goods_name"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("goods-search", {
                                  ref: "goodsSearch",
                                  refInFor: true,
                                  attrs: { "f-key": row.goods_name },
                                  on: {
                                    "add-rows": _vm.addRows,
                                    "select-goods": function ($event) {
                                      return _vm.selectGoods($event, row)
                                    },
                                  },
                                }),
                              ]
                            },
                          }
                        : item.prop == "unit_name"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [_vm._v(" " + _vm._s(row.unit_name) + " ")]
                            },
                          }
                        : item.prop == "goods_production_date"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("el-date-picker", {
                                  staticStyle: { width: "180px" },
                                  attrs: {
                                    type: "date",
                                    placeholder: "选择日期",
                                    format: "yyyy 年 MM 月 dd 日",
                                    "value-format": "yyyy-MM-dd",
                                  },
                                  model: {
                                    value: row.goods_production_date,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        row,
                                        "goods_production_date",
                                        $$v
                                      )
                                    },
                                    expression: "row.goods_production_date",
                                  },
                                }),
                              ]
                            },
                          }
                        : item.prop == "goods_price"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("el-input", {
                                  model: {
                                    value: row.goods_price,
                                    callback: function ($$v) {
                                      _vm.$set(row, "goods_price", $$v)
                                    },
                                    expression: "row.goods_price",
                                  },
                                }),
                              ]
                            },
                          }
                        : item.prop == "goods_num"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("el-input", {
                                  model: {
                                    value: row.goods_num,
                                    callback: function ($$v) {
                                      _vm.$set(row, "goods_num", $$v)
                                    },
                                    expression: "row.goods_num",
                                  },
                                }),
                              ]
                            },
                          }
                        : item.prop == "remark"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("el-input", {
                                  model: {
                                    value: row.remark,
                                    callback: function ($$v) {
                                      _vm.$set(row, "remark", $$v)
                                    },
                                    expression: "row.remark",
                                  },
                                }),
                              ]
                            },
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                })
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "操作",
                  fixed: "right",
                  width: "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var $index = ref.$index
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.copyRow($index, row)
                              },
                            },
                          },
                          [_vm._v(" 复制 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.deleteRow($index, row)
                              },
                            },
                          },
                          [_vm._v(" 删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }