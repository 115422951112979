import Vue from 'vue'

const focusTracker = Vue.directive('focus-tracker', {
  update(el, binding, vnode) {
    const onFocus = () => {
      console.log('focus')
      el.value = binding.value.focusValue
    }

    const onBlur = () => {
      console.log('blur')
      el.value = binding.value.blurValue || el.value
    }

    el.addEventListener('focus', onFocus)
    el.addEventListener('blur', onBlur)

    // const removeListeners = () => {
    //   el.removeEventListener('focus', onFocus);
    //   el.removeEventListener('blur', onBlur);
    // };
    //
    // return removeListeners;
  },
})

export { focusTracker }
