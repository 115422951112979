var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "remix-icon-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c("el-alert", {
                attrs: {
                  closable: false,
                  title:
                    "pro版小清新图标，点击图标即可复制源码使用，无需下载到本地，pro版已移除常规图标，故router中必须配置remixIcon方能显示菜单图标",
                  "show-icon": "",
                  type: "success",
                },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form",
                {
                  attrs: { inline: true, "label-width": "80px" },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "图标名称" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.queryForm.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "title", $$v)
                          },
                          expression: "queryForm.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { "label-width": "0" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { "native-type": "submit", type: "primary" },
                          on: { click: _vm.queryData },
                        },
                        [_vm._v(" 搜索 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._l(_vm.queryIcon, function (item, index) {
            return _c(
              "el-col",
              { key: index, attrs: { xs: 6, sm: 8, md: 3, lg: 2, xl: 2 } },
              [
                _c(
                  "el-card",
                  {
                    attrs: { shadow: "hover" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.handleCopyIcon(item, $event)
                      },
                    },
                  },
                  [_c("vab-remix-icon", { attrs: { icon: item } })],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "icon-text",
                    on: {
                      click: function ($event) {
                        return _vm.handleCopyText(item, $event)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(item) + " ")]
                ),
              ],
              1
            )
          }),
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.queryForm.pageNo,
                  layout: _vm.layout,
                  "page-size": _vm.queryForm.pageSize,
                  "page-sizes": [72, 144, 216, 288],
                  total: _vm.total,
                  background: "",
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "size-change": _vm.handleSizeChange,
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }