var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "orderTest-container" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "input" },
      [
        _c(
          "el-form",
          { attrs: { inline: "", model: _vm.form } },
          [
            _c(
              "el-form-item",
              { attrs: { prop: "name" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "商品名称、商品分组名称" },
                  model: {
                    value: _vm.form.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "name", $$v)
                    },
                    expression: "form.name",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      placeholder: "请选择状态",
                      "popper-class": "select-idx",
                    },
                    model: {
                      value: _vm.form.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "status", $$v)
                      },
                      expression: "form.status",
                    },
                  },
                  _vm._l(_vm.statusList, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.query } },
                  [_vm._v("查询")]
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.add } },
                  [_vm._v("添加分组")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "table-wrapper" },
          [
            _c(
              "el-table",
              {
                staticStyle: { width: "100%", "margin-top": "10px" },
                attrs: { stripe: "", data: _vm.list, "row-key": "id" },
              },
              [
                _vm._v(" > "),
                _c("el-table-column", {
                  attrs: { align: "center", type: "index", width: "50" },
                }),
                _c("el-table-column", {
                  attrs: { align: "center", type: "selection", width: "50" },
                }),
                _vm._l(_vm.finallyColumns, function (colItem, index) {
                  return _c("el-table-column", {
                    key: index,
                    attrs: {
                      width: colItem.width,
                      prop: colItem.prop,
                      label: colItem.label,
                      "show-overflow-tooltip": "",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        colItem.label === "排序"
                          ? {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c("el-input", {
                                    on: {
                                      change: function ($event) {
                                        return _vm.blur_(row.id, row.sort)
                                      },
                                    },
                                    model: {
                                      value: row.sort,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          row,
                                          "sort",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "row.sort",
                                    },
                                  }),
                                ]
                              },
                            }
                          : {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(row[colItem.prop])),
                                  ]),
                                ]
                              },
                            },
                      ],
                      null,
                      true
                    ),
                  })
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "操作",
                    width: "120",
                    align: "center",
                    fixed: "right",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var $index = ref.$index
                        var row = ref.row
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.edit($index, row)
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.edit($index, row)
                                },
                              },
                            },
                            [_vm._v("停用")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.edit($index, row)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tip" }, [
      _c("i", { staticClass: "el-icon-question" }),
      _c("span", { staticStyle: { "margin-left": "5px" } }, [
        _vm._v("什么是分组"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }