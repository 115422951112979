var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-table-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-top-panel",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    inline: true,
                    model: _vm.queryForm,
                    "label-width": "80px",
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "标题" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "标题" },
                        model: {
                          value: _vm.queryForm.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "title", $$v)
                          },
                          expression: "queryForm.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "XXX" } },
                    [_c("el-input", { attrs: { placeholder: "XXX" } })],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "XXX" } },
                    [_c("el-input", { attrs: { placeholder: "XXX" } })],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "XXX" } },
                    [_c("el-input", { attrs: { placeholder: "XXX" } })],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-search",
                            "native-type": "submit",
                            type: "primary",
                          },
                          on: { click: _vm.handleQuery },
                        },
                        [_vm._v(" 查询 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "vab-query-form-left-panel",
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-plus", type: "primary" },
                  on: { click: _vm.handleAdd },
                },
                [_vm._v(" 添加 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-delete", type: "danger" },
                  on: { click: _vm.handleDelete },
                },
                [_vm._v(" 删除 ")]
              ),
            ],
            1
          ),
          _c(
            "vab-query-form-right-panel",
            [
              _c(
                "el-popover",
                {
                  attrs: {
                    "popper-class": "custom-table-checkbox",
                    trigger: "hover",
                  },
                },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.checkList,
                        callback: function ($$v) {
                          _vm.checkList = $$v
                        },
                        expression: "checkList",
                      },
                    },
                    _vm._l(_vm.columns, function (item, index) {
                      return _c("el-checkbox", {
                        key: index,
                        attrs: { label: item.label },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-button",
                    { attrs: { slot: "reference" }, slot: "reference" },
                    [
                      _c("vab-remix-icon", {
                        attrs: { icon: "settings-line" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          ref: "tableSort",
          attrs: { stripe: "", data: _vm.list },
          on: { "selection-change": _vm.setSelectRows },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", type: "selection", width: "55" },
          }),
          _vm._l(_vm.finallyColumns, function (item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: {
                align: "center",
                label: item.label,
                sortable: item.sortable,
                width: "",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var $index = ref.$index
                      var row = ref.row
                      return [
                        item.label === "序号"
                          ? _c("span", [_vm._v(_vm._s($index + 1))])
                          : item.label === "评级"
                          ? _c(
                              "span",
                              [
                                _c("el-rate", {
                                  attrs: { disabled: "" },
                                  model: {
                                    value: row.rate,
                                    callback: function ($$v) {
                                      _vm.$set(row, "rate", $$v)
                                    },
                                    expression: "row.rate",
                                  },
                                }),
                              ],
                              1
                            )
                          : item.label === "头像"
                          ? _c(
                              "span",
                              [
                                _c("el-image", {
                                  attrs: {
                                    "preview-src-list": _vm.imageList,
                                    src: row.img,
                                  },
                                }),
                              ],
                              1
                            )
                          : _c("span", [_vm._v(_vm._s(row[item.prop]))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              "show-overflow-tooltip": "",
              width: "85",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.queryForm.pageNo,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          total: _vm.total,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("table-edit", { ref: "edit", on: { "fetch-data": _vm.fetchData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }