var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("input", { attrs: { placeholder: "Input Trace", type: "text" } }),
      _c("span", { staticClass: "bottom" }),
      _c("span", { staticClass: "right" }),
      _c("span", { staticClass: "top" }),
      _c("span", { staticClass: "left" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }