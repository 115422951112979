<template>
  <div style="overflow: hidden">
    <div v-for="(item, index) in list2" :key="index" style="position: relative">
      <div v-if="isEdit" class="icons">
        <i class="el-icon-document-copy" @click="copyPlan2(index)">复制</i>
        <i class="el-icon-close" @click="deletePlan2(index)">删除</i>
      </div>
      <el-card shadow="hover">
        <el-table stripe :data="item.goods_info" style="margin-top: 15px">
          <el-table-column
            width="220px"
            prop="goods_name"
            label="兑付商品"
            align="center"
          >
            <template #default="{ row }">
              <goods-search
                ref="goodsSearch"
                :f-key="row.goods_name"
                :search="isEdit"
                :disab="!isEdit"
                @select-goods-all="selectGoods2($event, row, index)"
                @add-rows="addRows2($event, index)"
              ></goods-search>
            </template>
          </el-table-column>
          <el-table-column
            prop="unit_id"
            label="单位"
            width="110px"
            align="center"
          >
            <template #default="{ row }">
              <el-select
                v-model="row.unit_id"
                style="width: 100px"
                placeholder="单位"
                :disabled="!isEdit"
                @change="unitChange2($event, row)"
              >
                <el-option
                  v-for="(i, idx) in row.arr_unit"
                  :key="idx"
                  :value="i.id"
                  :label="i.unit_name"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="goods_price" label="价格" align="center">
            <template #default="{ row }">
              {{ row.goods_price || 0 }}
            </template>
          </el-table-column>
          <el-table-column prop="number" label="数量" align="center">
            <template #default="{ row }">
              <el-input
                v-model="row.number"
                type="number"
                placeholder="数量"
                :min="0"
                :disabled="!isEdit"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="" label="金额" align="center">
            <template #default="{ row }">
              {{ Number(row.number * row.goods_price).toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="备注" align="center">
            <template #default="{ row }">
              <el-input
                v-model="row.remark"
                placeholder="备注"
                :disabled="!isEdit"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column v-if="isEdit" prop="" label="操作" align="center">
            <template #default="{ $index, row }">
              <el-button
                type="text"
                @click.native.prevent="handleCopyRow2($index, row, index)"
              >
                复制
              </el-button>
              <el-button
                type="text"
                @click.native.prevent="handleDeleteRow2($index, row, index)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div
          style="display: flex; justify-content: space-between; margin: 10px 0"
        >
          <div>
            兑付现金:
            <el-input
              v-model="item.total_amount"
              style="width: 200px"
              :disabled="!isEdit"
            ></el-input>
          </div>
          <div>
            备注:
            <el-input
              v-model="item.remark"
              style="width: 200px"
              :disabled="!isEdit"
            ></el-input>
          </div>
        </div>
        <div>
          兑付日期:
          <el-date-picker
            v-model="item.time_text"
            type="date"
            placeholder="选择日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            style="width: 200px"
            :disabled="!isEdit"
          ></el-date-picker>
        </div>
      </el-card>
    </div>
    <el-button v-if="isEdit" type="primary" style="float: right" @click="add2">
      添加新兑付
    </el-button>
  </div>
</template>
<script>
  import GoodsSearch from '@/baseComponents/goodsSearch'
  export default {
    name: 'Table2',
    components: { GoodsSearch },
    props: {
      data: {
        type: Array,
        default: () => [],
      },
      isEdit: {
        type: Boolean,
        default: () => false,
      },
    },
    data() {
      return {
        list2: [
          {
            title: '',
            time: '',
            total_amount: '',
            remark: '',
            goods_info: [
              {
                arr_unit: [],
                goods_name: '',
                goods_id: '',
                goods_price: '0',
                unit_id: '',
                number: '0',
                remark: '',
              },
            ],
          },
        ],
      }
    },
    computed: {},
    watch: {
      data(val) {
        let that = this
        this.list = JSON.parse(JSON.stringify(this.data))
        console.log('as', this.list[0].goods_info)
        if (this.list.length == 0) {
          console.log('list = 0')
          return false
        } else {
          setTimeout(() => {
            console.log('开始list')
            console.log(that.list)
            if (that.list[0].goods_info.length == 0 && that.isEdit == true) {
              that.addRow2()
            }
          }, 2000)
        }
      },
    },
    created() {},
    mounted() {
      // this.list = JSON.parse(JSON.stringify(this.data))
    },
    methods: {
      selectGoods2(val, row, index) {
        console.log(row)
        row.goods_name = val.goods_name
        row.goods_id = val.goods_id
        row.arr_unit = val.arr_unit
        if (
          this.list2[index].goods_info[this.list2[index].goods_info.length - 1]
            .goods_id !== ''
        ) {
          this.addRow2(index)
        }
      },
      addRow2(index) {
        this.list2[index].goods_info.push({
          goods_name: '',
          goods_id: '',
          unit_id: '',
          number: '0',
          remark: '',
          goods_price: '0',
          arr_unit: [],
        })
      },

      addRows2(val, index) {
        val.forEach((item) => {
          this.list2[index].goods_info.push({
            goods_name: item.goods_name,
            goods_id: item.goods_id,
            number: '0',
            unit_id: '',
            remark: '',
            arr_unit: item.arr_unit,
          })
        })
      },
      // 表格操作
      handleCopyRow2(index, row, idx) {
        this.list2[idx].goods_info.splice(
          index,
          0,
          JSON.parse(JSON.stringify(row))
        )
      },
      handleDeleteRow2(index, row, idx) {
        if (this.list2[idx].goods_info.length == 1) {
          this.$message.error('不能删除最后一行')
        } else {
          this.list2[idx].goods_info.splice(index, 1)
        }
      },
      add2() {
        this.list2.push({
          title: '',
          time: '',
          total_amount: '',
          remark: '',
          goods_info: [
            {
              arr_unit: [],
              goods_name: '',
              goods_id: '',
              goods_price: '0',
              unit_id: '',
              number: '0',
              remark: '',
            },
          ],
        })
      },
      copyPlan2(index) {
        this.list2.splice(
          index,
          0,
          JSON.parse(JSON.stringify(this.list2[index]))
        )
      },
      deletePlan2(index) {
        if (this.list2.length == 1) {
          this.$message.error('不能删除最后一项')
        } else {
          this.list2.splice(index, 1)
        }
      },
      unitChange2(val, row) {
        console.log(val)
        row.goods_price = row.arr_unit.filter(
          (item) => item.id == val
        )[0].old_sell_price
      },
      clearForm2() {
        this.list2 = {
          title: '',
          time: '',
          total_amount: '',
          remark: '',
          goods_info: [
            {
              arr_unit: [],
              goods_name: '',
              goods_id: '',
              goods_price: '0',
              unit_id: '',
              number: '0',
              remark: '',
            },
          ],
        }
      },
      save() {
        let data = JSON.parse(JSON.stringify(this.list2))
        data.forEach((item) => {
          item.time = item.time_text
          item.goods_info.forEach((item2, index2) => {
            if (item2.goods_id < 0) {
              item.goods_info.splice(index2, 1)
            }
          })
        })
        console.log('table2:', data)
        return JSON.stringify(data)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .icons {
    position: absolute;
    top: 10px;
    right: 10px;
    i {
      margin-right: 5px;
      cursor: pointer;
    }
  }
</style>
