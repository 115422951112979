<template>
  <div class="wrapper">
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      :visible.sync="showDialog"
      title="存货单详情"
      top="5vh"
      width="1160px"
      @close="close"
    >
      <el-alert
        :closable="false"
        :title="titleTip"
        type="success"
        style="width: 86%; margin-top: -70px; margin-left: 110px"
      ></el-alert>
      <div class="orderStatus">{{ orderStatus }}</div>
      <el-form ref="form" :model="form" label-width="80px" :inline="true">
        <el-form-item label="客户名称" prop="cust_name">
          <el-input v-model.trim="form.cust_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="boss">
          <el-input v-model.trim="form.boss" disabled></el-input>
        </el-form-item>
        <el-form-item label="地址" prop="address" class="dz">
          <el-input
            v-model.trim="form.address"
            disabled
            style="width: 380px"
          ></el-input>
        </el-form-item>

        <el-form-item label="活动名称" prop="chck">
          <el-input v-model.trim="form.chck" disabled></el-input>
        </el-form-item>

        <el-form-item label="业务员" prop="deliver_name">
          <el-input v-model.trim="form.deliver_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="签单金额" prop="staff_name">
          <el-input v-model.trim="form.staff_name" disabled></el-input>
        </el-form-item>

        <el-form-item label="已收款" prop="allot_code">
          <el-input v-model.trim="form.allot_code" disabled></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="sale_time">
          <el-input v-model.trim="form.sale_time" disabled></el-input>
        </el-form-item>
      </el-form>
      <!-- 表格 -->
      <div class="table-wrapper">
        <div class="table1">
          <div class="tit">存货详情</div>
          <el-table></el-table>
        </div>

        <div class="table2">
          <div class="tit">余货一览</div>
          <el-table></el-table>
        </div>

        <div class="table3">
          <div class="tit">还货记录</div>
          <el-table></el-table>
        </div>

        <div class="table4">
          <div class="tit">收款记录</div>
          <el-table></el-table>
        </div>
      </div>
      <!-- 底部按钮 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="print">打印</el-button>
        <el-button @click="close">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    name: 'Check',
    components: {},
    data() {
      return {
        showDialog: true,
        id: 0,
        // 单号
        orderCode: '',
        // 下单时间
        orderTime: '',
        form: {},
        orderStatus: '状态',
        checkList1: [
          '商品名称',
          '规格',
          '类型',
          '单位',
          '存货价',
          '存货数量',
          '金额',
        ],
        checkList2: ['商品名称', '规格', '类型', '存货数量', '余货数量'],
        checkList3: ['单号/时间', '商品', '规格', '还货数量', '余货数量'],
      }
    },
    computed: {
      titleTip() {
        return '单号:' + this.orderCode + ' 下单时间： ' + this.form.sale_time
      },
    },
    watch: {},
    methods: {
      close() {
        this.showDialog = false
      },
      print() {
        console.log('打印')
      },
    },
  }
</script>
<style lang="scss" scoped></style>
