<!--
 * @Author: 候怀烨
 * @Date: 2020-11-30 14:19:39
 * @LastEditTime: 2020-12-10 16:23:23
 * @LastEditors: Please set LastEditors
 * @Description: 头部
 * @FilePath: \sd-vue-admin\src\views\project\movablepin\predeposit\depositactivity\components\ActivityHeard.vue
-->
<template>
  <div>
    <el-row type="flex" class="row-bg" justify="space-between">
      <el-col :span="550">
        <el-form ref="form" :model="form" inline>
          <el-form-item>
            <el-date-picker
              v-model="form.start_time"
              type="date"
              placeholder="开始时间"
              style="width: 130px"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="form.end_time"
              type="date"
              placeholder="结束时间"
              style="width: 130px"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="form.name"
              placeholder="请输入活动名称"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="form.activity_status"
              style="width: 130px"
              placeholder="请选择状态"
            >
              <el-option
                v-for="item in zhuaangtai"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submit">查 询</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2">
        <activity-ding @refresh="handlerData"></activity-ding>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import ActivityDing from './ActivityDing.vue'
  import { postAction } from '@/api/Employee'
  export default {
    components: {
      ActivityDing,
    },
    data() {
      return {
        form: {
          pageNo: 1,
          pageSize: 10,
          start_time: '',
          end_time: '',
          name: '',
          activity_status: 0,
        },
        url: {
          actionslist: '/promoteAdmin/deposit',
        },
        zhuaangtai: [
          {
            id: 0,
            name: '全部状态',
          },
          {
            id: 1,
            name: '进行中',
          },
          {
            id: 2,
            name: '已停止',
          },
          {
            id: 3,
            name: '未开始',
          },
          {
            id: 4,
            name: '已结束',
          },
        ],
      }
    },
    mounted() {
      this.handlerData()
      this.$EventBus.$on('pagesize', this.handlerpagesize)
      this.$EventBus.$on('pageon', this.hanlerpageon)
    },
    methods: {
      submit() {
        this.form.pageNo = 1
        this.handlerData()
      },
      handlerData() {
        postAction(this.url.actionslist, this.form)
          .then((res) => {
            console.log(res, '列表成功')
            res.pageNo = this.form.pageNo
            this.$EventBus.$emit('actionlist', res)
          })
          .catch((err) => {
            console.log(err, '列表失败')
          })
      },
      hanlerpageon(pageon) {
        this.form.pageNo = pageon
        this.handlerData()
      },
      handlerpagesize(pagesize) {
        this.form.pageSize = pagesize
        this.form.pageNo = 1
        this.handlerData()
      },
    },
  }
</script>

<style></style>
