<!--
 * @Author: your name
 * @Date: 2021-01-26 15:12:22
 * @LastEditTime: 2021-01-26 15:12:56
 * @LastEditors: Please set LastEditors
 * @Description: app设置
 * @FilePath: \sd-vue-admin\src\views\setting\featuresManagement\appFeatures\index.vue
-->
<template>
  <div>add设置</div>
</template>

<script>
  export default {}
</script>

<style></style>
