var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "test-container" },
    [
      _c("el-button", { on: { click: _vm.handleLink1 } }, [
        _vm._v("params无问号传参"),
      ]),
      _c("el-button", { on: { click: _vm.handleLink2 } }, [
        _vm._v("query有问号传参"),
      ]),
      _c("el-button", { on: { click: _vm.handleLink3 } }, [
        _vm._v("有参数的设计器"),
      ]),
      _c("el-button", { on: { click: _vm.handleLink4 } }, [
        _vm._v("弹窗设计器"),
      ]),
      _c("el-button", { on: { click: _vm.handleLink5 } }, [
        _vm._v("弹窗打印预览"),
      ]),
      _c("p", [_vm._v(_vm._s(_vm.user_name))]),
      _c("el-input", {
        model: {
          value: _vm.user_name,
          callback: function ($$v) {
            _vm.user_name = $$v
          },
          expression: "user_name",
        },
      }),
      _c("ruilang-design-dialog", {
        ref: "RuilangDesignDialog",
        attrs: { add: _vm.ruilangAdd },
        on: { "design-save-callback": _vm.designCallback },
      }),
      _c("ruilang-display-dialog", {
        ref: "RuilangDisplayDialog",
        attrs: { bill_type: _vm.bill_type, type: 1, data_id: _vm.data_id },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }