<template>
  <div>
    <el-form
      v-show="type === 'node'"
      ref="dataForm"
      :model="node"
      label-width="80px"
    >
      <el-form-item label="类型">
        <el-input v-model="node.type" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="名称">
        <el-input v-model="node.name"></el-input>
      </el-form-item>
      <el-form-item label="left坐标">
        <el-input v-model="node.left" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="top坐标">
        <el-input v-model="node.top" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="ico图标">
        <el-input v-model="node.ico"></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="node.state" placeholder="请选择">
          <el-option
            v-for="item in stateList"
            :key="item.state"
            :label="item.label"
            :value="item.state"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-close">重置</el-button>
        <el-button type="primary" icon="el-icon-check" @click="save">
          保存
        </el-button>
      </el-form-item>
    </el-form>

    <el-form
      v-show="type === 'line'"
      ref="dataForm"
      :model="line"
      label-width="80px"
    >
      <el-form-item label="条件">
        <el-input v-model="line.label"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-close">重置</el-button>
        <el-button type="primary" icon="el-icon-check" @click="saveLine">
          保存
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import { cloneDeep } from 'lodash'

  export default {
    data() {
      return {
        visible: true,
        // node 或 line
        type: 'node',
        node: {},
        line: {},
        data: {},
        stateList: [
          {
            state: 'success',
            label: '成功',
          },
          {
            state: 'warning',
            label: '警告',
          },
          {
            state: 'error',
            label: '错误',
          },
          {
            state: 'running',
            label: '运行中',
          },
        ],
      }
    },
    methods: {
      /**
       * 表单修改，这里可以根据传入的ID进行业务信息获取
       * @param data
       * @param id
       */
      nodeInit(data, id) {
        this.type = 'node'
        this.data = data
        data.nodeList.filter((node) => {
          if (node.id === id) {
            this.node = cloneDeep(node)
          }
        })
      },
      lineInit(line) {
        this.type = 'line'
        this.line = line
      },
      // 修改连线
      saveLine() {
        this.$emit(
          'set-line-label',
          this.line.from,
          this.line.to,
          this.line.label
        )
      },
      save() {
        this.data.nodeList.filter((node) => {
          if (node.id === this.node.id) {
            node.name = this.node.name
            node.left = this.node.left
            node.top = this.node.top
            node.ico = this.node.ico
            node.state = this.node.state
            this.$emit('repaint-everything')
          }
        })
      },
    },
  }
</script>
