<template>
  <div class="wrapper">
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      :visible.sync="showDialog"
    ></el-dialog>
  </div>
</template>
<script>
  export default {
    name: 'AddGroup',
    components: {},
    data() {
      return {
        showDialog: true,
        form: {
          name: '',
        },
        checkList: ['', '', ''],
        list: [],
      }
    },
    computed: {},
    watch: {},
    methods: {},
  }
</script>
<style lang="scss" scoped></style>
