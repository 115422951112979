var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            visible: _vm.showAdd,
            top: "5vh",
            title: _vm.title,
            center: "",
            width: "90%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAdd = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                { attrs: { gutter: 40 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 5 } },
                    [
                      _c(
                        "el-form",
                        { attrs: { model: _vm.form } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "移出仓库：" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "220px" },
                                  attrs: {
                                    disabled:
                                      _vm.outData.length > 0 ||
                                      _vm.inData.length > 0,
                                    placeholder: "移出仓库",
                                  },
                                  model: {
                                    value: _vm.form.out_id,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "out_id", $$v)
                                    },
                                    expression: "form.out_id",
                                  },
                                },
                                _vm._l(_vm.warehouse, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.depot_name,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "移入仓库：" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "220px" },
                                  attrs: {
                                    disabled:
                                      _vm.outData.length > 0 ||
                                      _vm.inData.length > 0,
                                    placeholder: "移出仓库",
                                  },
                                  model: {
                                    value: _vm.form.in_id,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "in_id", $$v)
                                    },
                                    expression: "form.in_id",
                                  },
                                },
                                _vm._l(_vm.warehouse, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.depot_name,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 4,
                                  placeholder: "备注：",
                                },
                                model: {
                                  value: _vm.form.remark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "remark", $$v)
                                  },
                                  expression: "form.remark",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "商品：" } },
                            [
                              _c("goods-search", {
                                attrs: { "depot-id": String(_vm.form.out_id) },
                                on: {
                                  "select-goods-all": function ($event) {
                                    return _vm.selectGoods($event)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-table",
                                {
                                  attrs: {
                                    stripe: "",
                                    data: _vm.goodsunit.unit_data,
                                  },
                                },
                                _vm._l(_vm.colemd, function (list, index) {
                                  return _c("el-table-column", {
                                    key: index,
                                    attrs: {
                                      label: list.label,
                                      align: list.align,
                                      prop: list.prop,
                                      width: "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        list.label == "数量"
                                          ? {
                                              key: "default",
                                              fn: function (ref) {
                                                var row = ref.row
                                                return [
                                                  _c("el-input", {
                                                    model: {
                                                      value: row.unit_num,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          row,
                                                          "unit_num",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "row.unit_num",
                                                    },
                                                  }),
                                                ]
                                              },
                                            }
                                          : list.label == "单价"
                                          ? {
                                              key: "default",
                                              fn: function (ref) {
                                                var row = ref.row
                                                return [
                                                  _c("el-input", {
                                                    model: {
                                                      value: row.goods_price,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          row,
                                                          "goods_price",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "row.goods_price",
                                                    },
                                                  }),
                                                ]
                                              },
                                            }
                                          : null,
                                      ],
                                      null,
                                      true
                                    ),
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-row",
                                {
                                  staticClass: "row-bg",
                                  attrs: {
                                    type: "flex",
                                    justify: "space-around",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: { click: _vm.handlersates },
                                    },
                                    [_vm._v(" 添加到拆装前 ")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "warning" },
                                      on: { click: _vm.handlerend },
                                    },
                                    [_vm._v(" 添加到拆装后 ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 19 } },
                    [
                      _c(
                        "el-row",
                        { staticStyle: { height: "50%" } },
                        [
                          _c(
                            "div",
                            { staticClass: "grid-content bg-purple-dark" },
                            [
                              _c("span", { staticClass: "text" }, [
                                _vm._v("移出仓库：拆装前商品明细"),
                              ]),
                            ]
                          ),
                          _c(
                            "el-table",
                            {
                              attrs: {
                                stripe: "",
                                data: _vm.outData,
                                height: "250px",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  width: "50",
                                  align: "center",
                                  type: "index",
                                },
                              }),
                              _vm._l(_vm.colemd1, function (list, index) {
                                return _c("el-table-column", {
                                  key: index,
                                  attrs: {
                                    label: list.label,
                                    align: list.align,
                                    prop: list.prop,
                                    width: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      list.label == "单价"
                                        ? {
                                            key: "default",
                                            fn: function (ref) {
                                              var row = ref.row
                                              return _vm._l(
                                                row.unit_data,
                                                function (item) {
                                                  return _c(
                                                    "span",
                                                    { key: item.unit_id },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.goods_price
                                                          ) +
                                                          "/" +
                                                          _vm._s(
                                                            item.unit_name
                                                          ) +
                                                          "; "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              )
                                            },
                                          }
                                        : list.label == "数量"
                                        ? {
                                            key: "default",
                                            fn: function (ref) {
                                              var row = ref.row
                                              return _vm._l(
                                                row.unit_data,
                                                function (item) {
                                                  return _c(
                                                    "span",
                                                    { key: item.unit_id },
                                                    [
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "80px",
                                                          "text-align":
                                                            "center",
                                                        },
                                                        model: {
                                                          value: item.unit_num,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "unit_num",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.unit_num",
                                                        },
                                                      }),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.unit_name
                                                          ) +
                                                          " "
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              )
                                            },
                                          }
                                        : null,
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              }),
                              _c("el-table-column", {
                                attrs: { align: "center", label: "操作" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var $index = ref.$index
                                      var row = ref.row
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handlerdeleteout(
                                                  $index,
                                                  row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 删除 ")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticStyle: { height: "50%" } },
                        [
                          _c(
                            "div",
                            { staticClass: "grid-content bg-purple-yel" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "text",
                                  staticStyle: {
                                    "background-color": "transparent",
                                    border: "none",
                                  },
                                },
                                [_vm._v(" 移入仓库：拆装后商品明细 ")]
                              ),
                            ]
                          ),
                          _c(
                            "el-table",
                            {
                              attrs: {
                                stripe: "",
                                data: _vm.inData,
                                height: "250px",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  width: "50",
                                  align: "center",
                                  type: "index",
                                },
                              }),
                              _vm._l(_vm.colemd2, function (list, index) {
                                return _c("el-table-column", {
                                  key: index,
                                  attrs: {
                                    label: list.label,
                                    align: list.align,
                                    prop: list.prop,
                                    width: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      list.label == "单价"
                                        ? {
                                            key: "default",
                                            fn: function (ref) {
                                              var row = ref.row
                                              return _vm._l(
                                                row.unit_data,
                                                function (item) {
                                                  return _c(
                                                    "span",
                                                    { key: item.unit_id },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.goods_price
                                                          ) +
                                                          "/" +
                                                          _vm._s(
                                                            item.unit_name
                                                          ) +
                                                          "; "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              )
                                            },
                                          }
                                        : list.label == "数量"
                                        ? {
                                            key: "default",
                                            fn: function (ref) {
                                              var row = ref.row
                                              return _vm._l(
                                                row.unit_data,
                                                function (item) {
                                                  return _c(
                                                    "span",
                                                    { key: item.unit_id },
                                                    [
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "80px",
                                                          "text-align":
                                                            "center",
                                                        },
                                                        model: {
                                                          value: item.unit_num,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "unit_num",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.unit_num",
                                                        },
                                                      }),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.unit_name
                                                          ) +
                                                          " "
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              )
                                            },
                                          }
                                        : null,
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              }),
                              _c("el-table-column", {
                                attrs: { align: "center", label: "操作" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var $index = ref.$index
                                      var row = ref.row
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handlerdeletein(
                                                  $index,
                                                  row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 删除 ")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
                [_vm._v(" 提交并生成拆装出入库单 ")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showAdd = !_vm.showAdd
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }