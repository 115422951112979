<template>
  <div class="orderTest-container">
    <el-form ref="form" :model="queryForm" label-width="80px" inline>
      <el-form-item>
        <el-cascader
          v-model="queryForm.area_id"
          :options="areaList"
          placeholder="选择区域"
          :props="{
            checkStrictly: true,
            value: 'id',
            label: 'name',
            emitPath: false,
          }"
          clearable
          style="width: 140px"
        ></el-cascader>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="queryForm.channel_id"
          placeholder="选择渠道类型"
          :popper-class="'select-idx'"
          filterable
          clearable
          style="width: 140px"
        >
          <el-option
            v-for="item in cust_channel"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="queryForm.class_id"
          placeholder="等级"
          :popper-class="'select-idx'"
          filterable
          clearable
          style="width: 140px"
        >
          <el-option
            v-for="item in cust_class"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="queryForm.staff_id"
          filterable
          clearable
          :popper-class="'select-idx'"
          placeholder="归属业务员"
          style="width: 140px"
        >
          <el-option
            v-for="item in saleManList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <client-search
          :popper-class="'select-idx selectDC'"
          @select-id="selectId"
          @select-val-input="custInput"
        ></client-search>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="time"
          :popper-class="'select-idx'"
          type="datetimerange"
          range-separator="-"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          icon="el-icon-search"
          native-type="submit"
          type="primary"
          @click="btnQuery"
        >
          查询
        </el-button>
        <el-button @click="btnExpotr">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="listLoading" :data="tabledata" style="width: 100%">
      <el-table-column
        v-for="(list, index) in columns"
        :key="index"
        :prop="list.prop"
        :label="list.label"
        :width="list.width"
        align="center"
      >
        <template #default="{ row }">
          <div v-if="list.label == '浏览量'">
            <span
              style="border-bottom: solid 1px #000000; cursor: pointer"
              @click="handlerinfo(row)"
            >
              {{ row[list.prop] }}
            </span>
          </div>
          <div v-else>
            <span>{{ row[list.prop] }}</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>

    <link-info ref="info" />
  </div>
</template>

<script>
  import { getSelect } from '@/api/saleOrder'
  import ClientSearch from '@/baseComponents/clientSearch'
  import { getStatisticsOnline } from '@/api/advanceOrder'
  import linkInfo from '@/views/project/marketing/statistics/onlinecount/components/linkInfo'
  import { downloadFile } from '@/api/Employee'

  export default {
    name: 'Onlinecount',
    components: {
      ClientSearch,
      linkInfo,
    },
    data() {
      return {
        total: 0,
        listLoading: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        areaList: [],
        cust_channel: [],
        cust_class: [],
        saleManList: [],
        tabledata: [],
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          start_time: '',
          end_time: '',
        },
        time: [],
        columns: [
          {
            order: 2,
            label: '客户名称',
            width: '160',
            prop: 'cust_name',

            sortable: false,
          },
          {
            order: 3,
            label: '联系人',
            width: '160',
            prop: 'boss',
            sortable: false,
          },
          {
            order: 4,
            label: '电话',
            width: '',
            prop: 'mobile',
            sortable: false,
          },
          {
            order: 5,
            label: '归属业务员',
            width: '',
            prop: 'user_name',
            sortable: false,
          },
          {
            order: 6,
            label: '浏览量',
            width: '',
            prop: 'num',
            sortable: false,
          },
          {
            order: 7,
            label: '订单金额',
            width: '',
            prop: 'order_money',
            sortable: false,
          },
        ],
      }
    },
    watch: {
      time(val) {
        if (val) {
          this.queryForm.start_date = val[0]
          this.queryForm.end_date = val[1]
        } else {
          this.queryForm.start_date = ''
          this.queryForm.end_date = ''
        }
      },
    },
    mounted() {
      getSelect().then((res) => {
        let data = res.data
        this.saleManList = data.staff // 业务员
        this.areaList = data.cust_area //区域
        this.cust_channel = data.cust_channel //渠道类型
        this.cust_class = data.cust_class // 等级
      })
    },
    created() {
      this.fetchData()
    },
    methods: {
      selectId(val) {
        console.log('selectId')
        console.log(val)
        if (val.cust_id) {
          this.queryForm.cust_id = val
          this.queryForm.cust_name = ''
        } else {
          this.queryForm.cust_id = val
          this.queryForm.cust_name = ''
        }
      },
      handlerinfo(row) {
        console.log((row.start_time = this.queryForm.start_time))
        row.start_time = this.queryForm.start_time
        row.end_time = this.queryForm.end_time
        this.$refs.info.fetchData(row)
        this.$refs.info.show = true
      },
      custInput(v) {
        this.queryForm.cust_name = v
      },
      btnQuery() {
        this.fetchData()
      },
      async fetchData() {
        console.log('fetchData()')
        this.listLoading = true
        const { data, totalCount, msg } = await getStatisticsOnline(
          this.queryForm
        )
        console.log(data)
        this.total = Number(totalCount) || 0
        this.totalAll = msg
        this.tabledata = data
        this.listLoading = false
      },
      btnExpotr() {
        downloadFile(
          '/shopAdmin/operate/shop-visit-log-export',
          '浏览记录.xlsx',
          this.queryForm
        )
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
    },
  }
</script>

<style scoped></style>
