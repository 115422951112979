var mixin = {
  data() {
    return {
      show: false,
    }
  },
  created() {
    this.show = !this.show
    this.ff()
  },

  methods: {
    ff() {
      console.log('mixin', this.show)
      if (this.show) {
        alert(1)
      }
    },
  },
}
export default mixin
