var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    { staticClass: "tab", attrs: { stripe: "" } },
    _vm._l(_vm.tabColumn_, function (item, index) {
      return _c("el-table-column", {
        key: index,
        attrs: { label: item.label, align: item.align, width: "" },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }