<!--
 * @Author: your name
 * @Date: 2020-11-20 20:24:30
 * @LastEditTime: 2020-11-23 15:16:29
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \sd-vue-admin\src\views\project\baseOrder\aboutSatff\departmentDetail\components\checkbox.vue
-->
<template>
  <div>
    <el-checkbox
      v-model="checkAll"
      :indeterminate="isIndeterminate"
      @change="handleCheckAllChange"
    >
      全选
    </el-checkbox>

    <el-checkbox-group
      v-model="checkedCities"
      class="bumen-col"
      @change="handleCheckedCitiesChange"
    >
      <el-checkbox
        v-for="(city, index) in cities"
        :key="index"
        style="padding: 20px 0px"
        :label="city.id"
      >
        {{ city.depot_name }}
      </el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  export default {
    props: {
      depot: {
        type: Array,
        default() {
          return []
        },
      },
    },
    data() {
      return {
        cities: [],
        checkAll: false,
        isIndeterminate: true,
        checkedCities: [],
      }
    },
    computed: {},
    mounted() {
      this.handlerdata()
    },

    methods: {
      handlerdata() {
        postAction('baseAdmin/common/depot-list', {})
          .then((res) => {
            console.log(res, '仓库成功')
            this.cities = res.data
          })
          .catch((err) => {})
      },
      handleCheckAllChange(val) {
        console.log(val)
        var b = []
        this.cities.forEach((itme) => {
          b.push(itme.id)
        })
        this.checkedCities = val ? b : []
        this.isIndeterminate = false
        this.$emit('checdid', this.checkedCities)
      },
      handleCheckedCitiesChange(value) {
        console.log(value, '成功')
        let checkedCount = value.length
        this.checkAll = checkedCount === this.cities.length
        this.isIndeterminate =
          checkedCount > 0 && checkedCount < this.cities.length
        this.$emit('checdid', this.checkedCities)
      },
    },
  }
</script>

<style></style>
