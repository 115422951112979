<template>
  <div>
    <div class="clearflex">
      <el-button class="fl-right" size="small" type="primary" @click="relation">
        确 定
      </el-button>
    </div>
    <el-tree
      ref="menuTree"
      :data="menuTreeData"
      :default-checked-keys="menuTreeIds"
      :props="menuDefaultProps"
      default-expand-all
      highlight-current
      node-key="ID"
      show-checkbox
      @check="nodeChange"
      @check-change="checkChange"
    ></el-tree>
  </div>
</template>
<script>
  import {
    addMenuAuthority,
    getMenuAuthority,
    getTree,
  } from '@/api/menuManagement'

  export default {
    name: 'Menus',
    props: {
      row: {
        default: function () {
          return {}
        },
        type: Object,
      },
    },
    data() {
      return {
        checkTree: [],
        menuTreeData: [],
        menuTreeIds: [],
        needConfirm: false,
        menuDefaultProps: {
          children: 'children',
          label: function (data) {
            return data.meta.title
          },
        },
      }
    },
    async created() {
      const res = await getTree()
      this.menuTreeData = res.data
      const res1 = await getMenuAuthority({ authorityId: this.row.authorityId })
      const menus = res1.data.menus
      const arr = []
      console.log(menus)

      menus.map((item) => {
        // 防止直接选中父级造成全选
        if (!menus.some((same) => same.parentId === item.menuId)) {
          arr.push(Number(item.menuId))
        }
      })
      // console.log(arr)
      this.menuTreeIds = arr
    },
    methods: {
      checkChange(val, checked) {
        // console.log(val)
        // console.log(checked)
        if (!checked) {
          this.checkTree.pop(val.ID)
        } else {
          this.checkTree.push(val.ID)
        }
        // console.log(this.checkTree)
      },
      nodeChange(val) {
        // console.log(val)
        //
        // this.checkTree.push(val.ID)
        // console.log(this.checkTree)
        // this.needConfirm = true
        // var aKey = this.getCheckedKeys(
        //   this.menuTreeData,
        //   this.$refs.menuTree.getCheckedKeys(),
        //   'ID'
        // )
        // console.log(aKey)
      },
      // 暴露给外层使用的切换拦截统一方法
      enterAndNext() {
        this.relation()
      },
      // 关联树 确认方法
      async relation() {
        const checkArr = this.$refs.menuTree.getCheckedNodes(false, true)
        //console.log(checkArr)
        const res = await addMenuAuthority({
          menus: this.checkTree,
          authorityId: this.row.authorityId,
        })
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '菜单权限设置成功!',
          })
        } else {
          this.$message({
            type: 'error',
            message: '菜单权限设置失败!',
          })
        }
      },
    },
  }
</script>

<style scoped></style>
