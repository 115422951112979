<!--
 * @Author: 候怀烨
 * @Date: 2020-11-30 15:16:20
 * @LastEditTime: 2020-12-10 10:51:38
 * @LastEditors: Please set LastEditors
 * @Description: table表格
 * @FilePath: \sd-vue-admin\src\views\project\movablepin\predeposit\depositactivity\components\ActivityTable.vue
-->
<template>
  <div>
    <!-- 表格 -->
    <el-table
      stripe
      border
      :data="list"
      show-summary
      :summary-method="summaryFunction"
    >
      <el-table-column
        align="center"
        label="顺序"
        type="index"
        width="50"
        :index="indexMethod"
      ></el-table-column>
      <el-table-column
        v-for="item in colme"
        :key="item.id"
        :align="item.align"
        :label="item.label"
        :prop="item.prop"
        width=""
      ></el-table-column>
      <el-table-column align="center" label="状态" prop="status" width="80">
        <template slot-scope="scope">
          <span v-show="scope.row.activity_status == 1">正常</span>
          <span v-show="scope.row.activity_status == 2">停止</span>
          <span v-show="scope.row.activity_status == 3">未开始</span>
          <span v-show="scope.row.activity_status == 4">已结束</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" fixed="right" width="200">
        <template slot-scope="scope">
          <el-button type="text" @click="link(scope.row)">查看</el-button>
          <el-button type="text">修改</el-button>
          <el-button type="text" @click="handlercopy(scope.row)">
            复制
          </el-button>
          <el-button
            v-show="scope.row.status == 1"
            type="text"
            @click="ting(scope.row)"
          >
            停止
          </el-button>
          <el-button
            v-show="scope.row.status == 0"
            type="text"
            @click="ting(scope.row)"
          >
            正常
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-row type="flex" class="row-bg" justify="end">
      <el-pagination
        :current-page="pageNo"
        :page-sizes="[10, 15, 20]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </el-row>
    <view-details ref="view"></view-details>
  </div>
</template>

<script>
  import ViewDetails from './ViewDetails.vue'
  import { postAction } from '@/api/Employee'
  export default {
    components: {
      ViewDetails,
    },
    data() {
      return {
        list: [],
        colme: [
          {
            align: 'center',
            label: '活动ID',
            prop: 'id',
            width: '130',
          },
          {
            align: 'center',
            label: '活动名称',
            prop: 'name',
            width: '130',
          },
          {
            align: 'center',
            label: '活动说明',
            prop: 'desc',
            width: '220',
          },
          {
            align: 'center',
            label: '开始时间',
            prop: 'join_start_at',
            width: '130',
          },
          {
            align: 'center',
            label: '结束时间',
            prop: 'join_end_at',
            width: '130',
          },
          {
            align: 'center',
            label: '参与数量',
            prop: 'count',
            width: '80',
          },
          {
            align: 'center',
            label: '收款金额',
            prop: 'receive_amount',
            width: '130',
          },
          {
            align: 'center',
            label: '签单金额',
            prop: 'total_amount',
            width: '130',
          },
        ],
        totalCount: 0,
        pageNo: 1,
        url: {
          gaizhuang: '/promoteAdmin/deposit/status',
          copy: '/promoteAdmin/deposit/copy',
        },
      }
    },
    created() {
      this.$EventBus.$on('actionlist', this.handlerlist)
    },
    methods: {
      handlercopy(row) {
        console.log(row)
        postAction(this.url.copy, { id: row.id })
          .then((res) => {
            console.log(res, '')
            this.$message(res.msg)
            this.$emit('tabl')
            this.$nextTick(() => {
              this.$EventBus.$on('actionlist', this.handlerlist)
            })
          })
          .catch((err) => {
            console.log(err)
          })
      },
      link(row) {
        console.log(row, '')
        this.$refs.view.dialogVisible = true
        this.$refs.view.handlerData(row.id)
      },
      // 停用，正常共同使用
      ting(row) {
        postAction(this.url.gaizhuang, { id: row.id })
          .then((res) => {
            console.log(res, '')
            if (res.code == 200) {
              this.$message({
                message: res.msg,
                type: 'success',
              })
              this.$emit('tabl')
              this.$nextTick(() => {
                this.$EventBus.$on('actionlist', this.handlerlist)
              })
            }
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      indexMethod(index) {
        return index + 1
      },
      handlerlist(list) {
        console.log(list, '')
        this.list = list.data
        this.totalCount = list.totalCount
        this.pageNo = list.pageNo
        console.log(this.list, '')
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`)
        this.$EventBus.$emit('pagesize', val)
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`)
        this.$EventBus.$emit('pageon', val)
      },
      // 表格合计fun
      summaryFunction(param) {
        const { columns, data } = param
        // 需要合计的下标
        let i = [6, 7, 8]
        let sums = []
        // let val = data.map((item) => {
        //   console.log(item)
        // })
        columns.forEach((col, idx) => {
          i.forEach((n) => {
            if (idx == n) {
              console.log(col.property)
              const val = data.map((item) => Number(item[col.property]))
              console.log(val)
              if (!val.every((value) => isNaN(value))) {
                const a = val.reduce((total, num) => {
                  if (!isNaN(total)) {
                    return total + num
                  }
                })
                if (idx == 6) {
                  sums[n] = a
                } else {
                  sums[n] = a.toFixed(2) + ' 元'
                }
              }
            }
          })
          if (idx == 0) {
            sums[idx] = '合计'
          }
        })
        console.log('sss', sums)
        return sums
      },
    },
  }
</script>

<style scoped>
  .text {
    text-align: center;
  }
</style>
