var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          attrs: {
            stripe: "",
            border: "",
            data: _vm.list,
            "show-summary": "",
            "summary-method": _vm.summaryFunction,
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "顺序",
              type: "index",
              width: "50",
              index: _vm.indexMethod,
            },
          }),
          _vm._l(_vm.colme, function (item) {
            return _c("el-table-column", {
              key: item.id,
              attrs: {
                align: item.align,
                label: item.label,
                prop: item.prop,
                width: "",
              },
            })
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "状态",
              prop: "status",
              width: "80",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: scope.row.activity_status == 1,
                            expression: "scope.row.activity_status == 1",
                          },
                        ],
                      },
                      [_vm._v("正常")]
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: scope.row.activity_status == 2,
                            expression: "scope.row.activity_status == 2",
                          },
                        ],
                      },
                      [_vm._v("停止")]
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: scope.row.activity_status == 3,
                            expression: "scope.row.activity_status == 3",
                          },
                        ],
                      },
                      [_vm._v("未开始")]
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: scope.row.activity_status == 4,
                            expression: "scope.row.activity_status == 4",
                          },
                        ],
                      },
                      [_vm._v("已结束")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              fixed: "right",
              width: "200",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.link(scope.row)
                          },
                        },
                      },
                      [_vm._v("查看")]
                    ),
                    _c("el-button", { attrs: { type: "text" } }, [
                      _vm._v("修改"),
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handlercopy(scope.row)
                          },
                        },
                      },
                      [_vm._v(" 复制 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: scope.row.status == 1,
                            expression: "scope.row.status == 1",
                          },
                        ],
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.ting(scope.row)
                          },
                        },
                      },
                      [_vm._v(" 停止 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: scope.row.status == 0,
                            expression: "scope.row.status == 0",
                          },
                        ],
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.ting(scope.row)
                          },
                        },
                      },
                      [_vm._v(" 正常 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c(
        "el-row",
        { staticClass: "row-bg", attrs: { type: "flex", justify: "end" } },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.pageNo,
              "page-sizes": [10, 15, 20],
              "page-size": 10,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.totalCount,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("view-details", { ref: "view" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }