<template>
  <el-dialog :modal="false" center :visible.sync="showadd" width="30%">
    <div>
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="科目名称">
          <el-input
            v-model="form.name"
            placeholder="科目名称"
            style="width: 220px"
          />
        </el-form-item>
        <el-form-item label="排序">
          <el-input-number v-model="form.sort_order" :min="1" label="排序" />
        </el-form-item>
        <el-form-item label="是否显示">
          <el-switch
            v-model="form.app_display"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0"
          />
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="showadd = false">取 消</el-button>
      <el-button v-show="type == 1" type="primary" @click="handlersave">
        确 定
      </el-button>
      <el-button v-show="type == 2" type="primary" @click="handlersave1">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { postAction } from '@/api/Employee'

  export default {
    name: 'Add',
    data() {
      return {
        type: 1, //1：创建2编辑
        showadd: false,
        form: {
          app_display: 1,
          sort_order: '',
          name: '',
          id: '',
        },
      }
    },
    watch: {
      showadd(val) {
        if (!val) {
          this.form = {
            app_display: 1,
            sort_order: '',
            name: '',
            id: '',
          }
        }
      },
    },
    methods: {
      handlerinfo(row) {
        this.form = Object.assign(this.form, row)
      },
      handlersave() {
        postAction('/cashAdmin/subject/funds-add', this.form).then((res) => {
          this.$message({ type: 'success', message: res.msg })
          this.$emit('getlist')
          this.showadd = false
        })
      },
      handlersave1() {
        postAction('/cashAdmin/subject/funds-edit', this.form).then((res) => {
          this.$message({ type: 'success', message: res.msg })
          this.$emit('getlist')
          this.showadd = false
        })
      },
    },
  }
</script>

<style scoped></style>
