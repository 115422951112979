<!--
 * @Author: your name
 * @Date: 2021-01-26 15:07:30
 * @LastEditTime: 2021-01-26 15:16:58
 * @LastEditors: Please set LastEditors
 * @Description: 功能设置
 * @FilePath: \sd-vue-admin\src\views\setting\featuresManagement\index.vue
-->
<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
  export default {}
</script>

<style></style>
