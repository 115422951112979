var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-card", { attrs: { shadow: "hover" } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c(
          "span",
          [
            _c("vab-remix-icon", { attrs: { icon: "github-line" } }),
            _vm._v(" 系统消息 "),
          ],
          1
        ),
        _c("el-tag", { staticClass: "card-header-tag" }, [_vm._v("更多消息")]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }