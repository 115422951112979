var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            inline: "",
            "label-position": "right",
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("el-cascader", {
                staticStyle: { width: "140px" },
                attrs: {
                  options: _vm.areaList,
                  placeholder: "选择区域",
                  props: {
                    checkStrictly: true,
                    value: "id",
                    label: "name",
                    emitPath: false,
                  },
                  clearable: "",
                },
                model: {
                  value: _vm.form.area_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "area_id", $$v)
                  },
                  expression: "form.area_id",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: {
                    placeholder: "选择渠道类型",
                    "popper-class": "select-idx",
                    filterable: "",
                    clearable: "",
                  },
                  model: {
                    value: _vm.form.channel_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "channel_id", $$v)
                    },
                    expression: "form.channel_id",
                  },
                },
                _vm._l(_vm.cust_channel, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("client-search", {
                attrs: { "popper-class": "select-idx selectDC" },
                on: {
                  "select-id": _vm.selectId,
                  "select-val-input": _vm.custInput,
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "240px" },
                attrs: {
                  type: "daterange",
                  "range-separator": "-",
                  "value-format": "yyyy-MM-dd",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerInquire },
                },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-button", { on: { click: _vm.handleExport } }, [
                _vm._v("导出"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { "margin-top": "15px" },
          attrs: {
            stripe: "",
            data: _vm.list,
            "show-summary": "",
            "summary-method": _vm.getSummaries,
            "row-key": _vm.getRowKey,
            "default-sort": _vm.sortRule,
          },
          on: {
            "selection-change": _vm.setSelectRows,
            "sort-change": _vm.sortChange,
          },
        },
        [
          _c(
            "el-table-column",
            { attrs: { align: "center", type: "index", width: "50" } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        "popper-class": "custom-table-checkbox",
                        trigger: "hover",
                      },
                    },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.checkList,
                            callback: function ($$v) {
                              _vm.checkList = $$v
                            },
                            expression: "checkList",
                          },
                        },
                        _vm._l(_vm.columns, function (item, index) {
                          return _c("el-checkbox", {
                            key: index,
                            attrs: { label: item.label },
                          })
                        }),
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { slot: "reference", type: "text" },
                          slot: "reference",
                        },
                        [
                          _c("vab-remix-icon", {
                            attrs: { icon: "settings-line" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "reserve-selection": true,
              type: "selection",
              width: "50",
            },
          }),
          _vm._l(_vm.finallyColumns, function (item, tableIndex) {
            return _c("el-table-column", {
              key: tableIndex,
              attrs: {
                prop: item.prop,
                label: item.label,
                sortable: item.sort,
                "column-key": item.prop,
                width: "",
                align: "center",
              },
            })
          }),
        ],
        2
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.form.pageNo,
          layout: _vm.layout,
          "page-size": _vm.form.pageSize,
          total: _vm.total,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }