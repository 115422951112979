var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("el-dialog", {
        attrs: {
          modal: false,
          "close-on-click-modal": false,
          visible: _vm.showDialog,
        },
        on: {
          "update:visible": function ($event) {
            _vm.showDialog = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }