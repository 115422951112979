var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticClass: "chart2", attrs: { shadow: "hover" } },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c(
            "span",
            [
              _c("vab-remix-icon", { attrs: { icon: "line-chart-line" } }),
              _vm._v(" 授权数 "),
            ],
            1
          ),
          _c(
            "el-tag",
            { staticClass: "card-header-tag", attrs: { type: "warning" } },
            [_vm._v("周")]
          ),
        ],
        1
      ),
      _c("vab-chart", {
        attrs: {
          autoresize: true,
          options: _vm.options,
          theme: "vab-echarts-theme",
        },
      }),
      _c("div", { staticClass: "bottom" }, [
        _c(
          "span",
          [
            _vm._v(" 授权数: "),
            _c("vab-count", {
              attrs: {
                decimals: _vm.countConfig.decimals,
                duration: _vm.countConfig.duration,
                "end-val": _vm.countConfig.endVal,
                prefix: _vm.countConfig.prefix,
                separator: _vm.countConfig.separator,
                "start-val": _vm.countConfig.startVal,
                suffix: _vm.countConfig.suffix,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }