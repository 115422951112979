<!--
 * @Author: your name
 * @Date: 2021-02-01 15:47:50
 * @LastEditTime: 2021-02-20 10:05:35
 * @LastEditors: Please set LastEditors
 * @Description: 新建商品拆装
 * @FilePath: \sd-vue-admin\src\views\project\treasury\depotOperation\disassembly\components\AddDisassembly.vue
-->
<template>
  <div>
    <el-dialog
      :modal="false"
      :visible.sync="showAdd"
      top="5vh"
      :title="title"
      center
      width="90%"
    >
      <div>
        <el-row :gutter="40">
          <el-col :span="5">
            <el-form :model="form">
              <el-form-item label="移出仓库：">
                <el-select
                  v-model="form.out_id"
                  :disabled="outData.length > 0 || inData.length > 0"
                  placeholder="移出仓库"
                  style="width: 220px"
                >
                  <el-option
                    v-for="(item, index) in warehouse"
                    :key="index"
                    :label="item.depot_name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="移入仓库：">
                <el-select
                  v-model="form.in_id"
                  :disabled="outData.length > 0 || inData.length > 0"
                  placeholder="移出仓库"
                  style="width: 220px"
                >
                  <el-option
                    v-for="(item, index) in warehouse"
                    :key="index"
                    :label="item.depot_name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-input
                  v-model="form.remark"
                  type="textarea"
                  :rows="4"
                  placeholder="备注："
                />
              </el-form-item>
              <el-form-item label="商品：">
                <!-- @add-rows="addRows" -->
                <goods-search
                  :depot-id="String(form.out_id)"
                  @select-goods-all="selectGoods($event)"
                ></goods-search>
              </el-form-item>
              <el-form-item>
                <el-table stripe :data="goodsunit.unit_data">
                  <el-table-column
                    v-for="(list, index) in colemd"
                    :key="index"
                    :label="list.label"
                    :align="list.align"
                    :prop="list.prop"
                    width=""
                  >
                    <template v-if="list.label == '数量'" #default="{ row }">
                      <el-input v-model="row.unit_num" />
                    </template>
                    <template
                      v-else-if="list.label == '单价'"
                      #default="{ row }"
                    >
                      <el-input v-model="row.goods_price" />
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
              <el-form-item>
                <el-row type="flex" class="row-bg" justify="space-around">
                  <el-button type="primary" @click="handlersates">
                    添加到拆装前
                  </el-button>
                  <el-button type="warning" @click="handlerend">
                    添加到拆装后
                  </el-button>
                </el-row>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="19">
            <el-row style="height: 50%">
              <div class="grid-content bg-purple-dark">
                <span class="text">移出仓库：拆装前商品明细</span>
              </div>
              <el-table stripe :data="outData" height="250px">
                <!-- :show-header="false" -->
                <el-table-column
                  width="50"
                  align="center"
                  type="index"
                ></el-table-column>
                <el-table-column
                  v-for="(list, index) in colemd1"
                  :key="index"
                  :label="list.label"
                  :align="list.align"
                  :prop="list.prop"
                  width=""
                >
                  <template v-if="list.label == '单价'" #default="{ row }">
                    <span v-for="item in row.unit_data" :key="item.unit_id">
                      {{ item.goods_price }}/{{ item.unit_name }};
                    </span>
                  </template>
                  <template v-else-if="list.label == '数量'" #default="{ row }">
                    <span v-for="item in row.unit_data" :key="item.unit_id">
                      <el-input
                        v-model="item.unit_num"
                        style="width: 80px; text-align: center"
                      />
                      {{ item.unit_name }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                  <template #default="{ $index, row }">
                    <el-button
                      type="text"
                      @click="handlerdeleteout($index, row)"
                    >
                      删除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
            <el-row style="height: 50%">
              <div class="grid-content bg-purple-yel">
                <span
                  class="text"
                  style="background-color: transparent; border: none"
                >
                  移入仓库：拆装后商品明细
                </span>
              </div>
              <el-table stripe :data="inData" height="250px">
                <!-- :show-header="false"  -->
                <el-table-column
                  width="50"
                  align="center"
                  type="index"
                ></el-table-column>
                <el-table-column
                  v-for="(list, index) in colemd2"
                  :key="index"
                  :label="list.label"
                  :align="list.align"
                  :prop="list.prop"
                  width=""
                >
                  <template v-if="list.label == '单价'" #default="{ row }">
                    <span v-for="item in row.unit_data" :key="item.unit_id">
                      {{ item.goods_price }}/{{ item.unit_name }};
                    </span>
                  </template>
                  <template v-else-if="list.label == '数量'" #default="{ row }">
                    <span v-for="item in row.unit_data" :key="item.unit_id">
                      <el-input
                        v-model="item.unit_num"
                        style="width: 80px; text-align: center"
                      />
                      {{ item.unit_name }}
                    </span>
                  </template>
                </el-table-column>

                <el-table-column align="center" label="操作">
                  <template #default="{ $index, row }">
                    <el-button
                      type="text"
                      @click="handlerdeletein($index, row)"
                    >
                      删除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handlersave">
          提交并生成拆装出入库单
        </el-button>
        <el-button @click="showAdd = !showAdd">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import { postAction } from '@/api/Employee'
  export default {
    components: {
      GoodsSearch,
    },
    data() {
      return {
        showAdd: false,
        title: '商品拆装',
        form: {
          in_id: '', //移入仓库
          out_id: '', //移出仓库
          remark: '', //备注
          goods_data: '',
        },
        goodsunit: {
          activity_count: 0,
          activity_id: 0,
          arr_goods_type: [],
          type: '',
          unit_data: [],
          arr_unit: [],
          balance_count: '',
          brand_id: '',
          brand_name: '',
          class_name: '',
          depot_stock_num: '',
          goods_child_id: '',
          goods_id: '',
          goods_money: '',
          goods_name: '',
          goods_price: '',
          goods_production_date: '',
          goods_type: '',
          id: '',
          info: '',
          is_close_text: '',
          is_img: '',
          quantity: '',
          sale_type_text: '',
          specs: '',
          unit_cv: '',
          unit_id: '',
          unit_name: '',
        },
        warehouse: [],
        url: {
          warehouse: '/baseAdmin/common/depot-list',
          add: '/depotAdmin/split/add',
        },
        colemd: [
          {
            order: 1,
            label: '数量',
            align: 'center',
            prop: 'unit_num',
            width: '',
          },
          {
            order: 2,
            label: '单位',
            align: 'center',
            prop: 'unit_name',
            width: '',
          },
          {
            order: 3,
            label: '单价',
            align: 'center',
            prop: 'goods_price',
            width: '',
          },
        ],
        outData: [],
        colemd1: [
          {
            label: '商品名称',
            align: 'center',
            prop: 'goods_name',
            width: '',
          },
          {
            label: '规格',
            align: 'center',
            prop: 'specs',
            width: '',
          },
          {
            label: '单位换算',
            align: 'center',
            prop: 'unit_cv',
            width: '',
          },
          {
            label: '可用库存',
            align: 'center',
            prop: 'depot_stock_num',
            width: '',
          },
          {
            label: '单价',
            align: 'center',
            prop: '',
            width: '',
          },
          {
            label: '数量',
            align: 'center',
            prop: '',
            width: '',
          },

          {
            label: '金额',
            align: 'center',
            prop: 'menoy',
            width: '',
          },
        ],
        inData: [],
        colemd2: [
          {
            label: '商品名称',
            align: 'center',
            prop: 'goods_name',
            width: '',
          },
          {
            label: '规格',
            align: 'center',
            prop: 'specs',
            width: '',
          },
          {
            label: '单位换算',
            align: 'center',
            prop: 'unit_cv',
            width: '',
          },
          {
            label: '可用库存',
            align: 'center',
            prop: 'depot_stock_num',
            width: '',
          },
          {
            label: '单价',
            align: 'center',
            prop: 'create_at',
            width: '',
          },
          {
            label: '数量',
            align: 'center',
            prop: 'add_name',
            width: '',
          },

          {
            label: '金额',
            align: 'center',
            prop: 'menoy',
            width: '',
          },
        ],
      }
    },
    mounted() {
      this.handlerwarehouse()
    },
    methods: {
      handlersave() {
        var Data = [...this.inData, ...this.outData]
        var endshuju = new Array()
        Data.forEach((list) => {
          var goods = {
            goods_id: list.goods_id,
            type: list.type,
            unit_data: list.unit_data,
          }
          endshuju.push(goods)
        })
        Data = endshuju
        this.form.goods_data = JSON.stringify(Data)
        postAction(this.url.add, this.form)
          .then((res) => {
            console.log(res)
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.showAdd = false
            this.$emit('getlist')
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlerdeleteout(index, row) {
        this.outData.splice(index, 1)
      },
      handlerdeletein(index, row) {
        this.inData.splice(index, 1)
      },
      handlersates() {
        this.goodsunit.type = '1'
        var menoy = 0
        this.goodsunit.unit_data.forEach((list) => {
          menoy += parseInt(list.goods_price) * parseInt(list.unit_num)
        })
        this.goodsunit.menoy = menoy
        this.outData.push(JSON.parse(JSON.stringify(this.goodsunit)))
      },
      handlerend() {
        this.goodsunit.type = '2'
        var menoy = 0
        this.goodsunit.unit_data.forEach((list) => {
          menoy += parseInt(list.goods_price) * parseInt(list.unit_num)
        })
        this.goodsunit.menoy = menoy
        this.inData.push(JSON.parse(JSON.stringify(this.goodsunit)))
      },
      selectGoods(event) {
        console.log(event, '1')
        // Object.assign(row, event)
        var unit_data = []

        this.goodsunit = event
        this.goodsunit.arr_unit.forEach((list) => {
          var unit = {
            unit_id: list.unit_id,
            unit_num: '',
            goods_price: list.sell_price,
            unit_name: list.unit_name,
          }
          unit_data.push(unit)
        })
        this.goodsunit.unit_data = unit_data
        this.goodsunit.type = ''
      },

      // addRows(val) {
      //   console.log('添加rows', val)
      //   console.log(val)
      //   if (val.length !== 0) {
      //     val.forEach((item) => {
      //       var unit_data = []
      //       item.arr_unit.forEach((list) => {
      //         var unit = {
      //           unit_id: list.unit_id,
      //           unit_num: '',
      //           goods_price: list.sell_price,
      //           nuit_name: list.unit_name,
      //         }
      //         unit_data.push(unit)
      //       })

      //       val.unit_data = unit_data
      //     })
      //     this.goodsunit = val
      //     this.$message.success('添加成功')
      //   }
      // },
      // 表格内单位切换
      // unitChange(e, row) {
      //   console.log(e, row)
      //   let price = row.arr_unit.filter((item) => item.id == e)[0].sell_price
      //   let name = row.arr_unit.filter((item) => item.id == e)[0].unit_name
      //   row.goods_price = price
      //   row.unit_name = name
      // },
      handlerwarehouse() {
        postAction(this.url.warehouse, { pageSize: -1 })
          .then((res) => {
            console.log(res, 'lalal')
            this.warehouse = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
    },
  }
</script>

<style scoped>
  .border {
    width: 100%;
    border: 1px #000000, solid;
  }
  .grid-content {
    min-height: 36px;
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #639ef1;
  }
  .bg-purple-yel {
    background: #f38b02;
  }
  .text {
    margin-left: 10px;
    line-height: 36px;
    color: #ffffff;
  }
</style>
