<template>
  <div class="detail-container">
    <el-alert :closable="false" show-icon type="success">
      详情页菜单高亮模拟
    </el-alert>
  </div>
</template>

<script>
  export default {
    name: 'Detail',
  }
</script>
