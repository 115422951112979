<!--
 * @Author: your name
 * @Date: 2020-12-29 18:59:22
 * @LastEditTime: 2020-12-30 16:31:42
 * @LastEditors: Please set LastEditors
 * @Description: 销售明细
 * @FilePath: \sd-vue-admin\src\views\project\sale\control\sale\components\components\SalesDetails.vue
-->
<template>
  <div>
    <el-table stripe :data="sales.mx_list" style="width: 100%">
      <el-table-column
        label="客户名称"
        align="center"
        prop="cust_name"
      ></el-table-column>
      <el-table-column
        label="商品名称"
        align="center"
        prop="goods_name"
      ></el-table-column>
      <el-table-column
        label="规格"
        align="center"
        prop="goods_spec"
      ></el-table-column>
      <el-table-column
        label="单位"
        align="center"
        prop="unit_name"
      ></el-table-column>
      <el-table-column
        label="价格"
        align="center"
        prop="goods_price"
      ></el-table-column>
      <el-table-column
        label="数量"
        align="center"
        prop="goods_num"
      ></el-table-column>
      <el-table-column
        label="金额"
        align="center"
        prop="goods_money"
      ></el-table-column>
      <el-table-column
        label="类型"
        align="center"
        prop="goods_type"
      ></el-table-column>
    </el-table>
  </div>
</template>

<script>
  export default {
    props: {
      sales: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {}
    },
    methods: {},
  }
</script>

<style></style>
