<template>
  <!-- 提成明细 -->
  <el-dialog
    :modal="false"
    title=""
    :visible.sync="showDialog"
    width="1000px"
    top="15vh"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div class="orderTest-container">
      <div class="tip">
        <el-select
          v-model="phase_value"
          placeholder="往期提成"
          clearable
          @change="selectChange"
        >
          <el-option
            v-for="item in phaseList"
            :key="item.phase_id"
            :label="item.value"
            :value="item.key"
          ></el-option>
        </el-select>
        <span style="margin-left: 20px">提成周期:</span>
        <span>{{ tip }}</span>
      </div>
      <div class="table">
        <el-table v-loading="loading" stripe :data="list">
          <el-table-column
            v-for="(item, tableIndex) in finallyColumns"
            :key="tableIndex"
            :prop="item.prop"
            :label="item.label"
            width=""
            align="center"
          ></el-table-column>
          <el-table-column
            prop=""
            align="center"
            label="操作"
            fixed="right"
            min-width="110px"
          >
            <template #default="{ $index, row }">
              <el-button
                type="text"
                @click.native.prevent="handleCheckRow($index, row)"
              >
                查看明细
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 底部按钮 -->
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button v-if="!isCheck" type="primary" @click="handleAccounting">
        核算本期提成
      </el-button>
      <el-button>打印</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
    <detail ref="detail"></detail>
  </el-dialog>
</template>
<script>
  import _ from 'lodash'
  import {
    checkAccounting,
    accountingList,
    accountingCommission,
  } from '@/api/performance'
  import Detail from './detail'
  export default {
    name: 'Check',
    components: { Detail },
    data() {
      return {
        id: 0,
        isCheck: true,
        tip: '',
        phase_value: '',
        loading: false,
        list: [],
        checkList: ['名单', '销售提成', '退货提成', '送货提成', '提成合计'],
        columns: [
          {
            order: 1,
            label: '名单',
            prop: 'user_name',
            width: '',
          },
          {
            order: 2,
            label: '销售提成',
            prop: 'sale_money',
            width: '',
          },
          {
            order: 3,
            label: '退货提成',
            prop: 'back_money',
            width: '',
          },
          {
            order: 4,
            label: '送货提成',
            prop: 'deliver_money',
            width: '',
          },
          {
            order: 5,
            label: '提成合计',
            prop: 'total_money',
            width: '',
          },
        ],
        showDialog: false,
        phaseList: [], //提成期数 下拉
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      showDialog(val) {
        if (val) {
          this.fetchData()
        } else {
          this.phase_value = ''
          this.list = []
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      // 下拉  tip 获取
      async fetchData() {
        console.log(this.phase_value, this.id)
        let { data } = await checkAccounting({
          phase_value: this.phase_value,
          comm_id: this.id,
        })
        this.phaseList = data.phase_info
        this.tip = data.last_time
        console.log(data)
      },
      async getTableData() {
        this.loading = true

        let { data } = await accountingList({
          comm_id: this.id,
          phase_value: this.phase_value,
        })
        console.log(data)
        this.list = data
        this.loading = false
      },
      close() {
        this.showDialog = false
      },
      // 下拉改变
      selectChange(val) {
        this.getTableData()
      },
      handleAccounting() {
        accountingCommission({ comm_id: this.id }).then((res) => {
          this.fetchData()
          this.getTableData()
          this.$message.success('核算成功')
          console.log(res)
        })
      },
      // 查看明细
      handleCheckRow(index, row) {
        console.log(row.user_id)
        this.$refs.detail.id = row.user_id
        this.$refs.detail.isCheck = this.isCheck
        this.$refs.detail.showDialog = true
      },
    },
  }
</script>
<style lang="scss" scoped>
  .table {
    margin-top: 20px;
  }
</style>
