<template>
  <div class="wrapper">
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="选择商品"
      top="15vh"
      :visible.sync="tableInputWindow"
      width="1050px"
      @close="close"
    >
      <el-input
        v-model="filterText"
        placeholder="输入关键字进行查询"
        style="width: 250px; margin: 5px 10px 5px 0"
      ></el-input>
      <el-button style="margin-right: 10px" @click="filter">查询</el-button>
      <el-checkbox v-model="checked">只显示有库存商品</el-checkbox>
      <el-row :gutter="10">
        <el-col :span="6">
          <div class="tit">商品类别</div>
          <div class="grid-content bg-purple">
            <!-- tree -->
            <el-tree
              ref="tree"
              class="filter-tree"
              :data="data"
              :props="defaultProps"
              default-expand-all
              :filter-node-method="filterNode"
              @node-click="treeClick"
            ></el-tree>
          </div>
        </el-col>
        <el-col :span="18">
          <div class="grid-content bg-purple">
            <!-- 右侧表格 -->
            <el-table
              stripe
              :data="list"
              height="310"
              @selection-change="setSelectRows"
              @cell-click="cellClick"
            >
              <!-- 选择框 -->
              <el-table-column
                align="center"
                type="selection"
                width="60"
              ></el-table-column>
              <!-- 序号 -->
              <el-table-column
                prop=""
                align="center"
                label="序号"
                min-width="50px"
              >
                <template #default="{ $index }">
                  <span>{{ $index + 1 }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="goods_name"
                align="center"
                label="商品名称"
                min-width="auto"
              ></el-table-column>
              <el-table-column
                prop="code"
                align="center"
                label="小单位条码"
                min-width="120px"
              ></el-table-column>
              <el-table-column
                prop="classes"
                align="center"
                label="类别"
                min-width="auto"
              ></el-table-column>
              <el-table-column
                prop="brand"
                align="center"
                label="品牌"
                min-width="auto"
              ></el-table-column>
              <el-table-column
                prop="inventory"
                align="center"
                label="可用库存"
                min-width="auto"
              ></el-table-column>
              <el-table-column
                prop="units"
                align="center"
                label="单位换算"
                min-width="auto"
              ></el-table-column>
              <el-table-column
                prop="status"
                align="center"
                label="状态"
                min-width="auto"
              ></el-table-column>
            </el-table>
          </div>
        </el-col>
      </el-row>
      <!-- 底部按钮 -->
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="save">确认</el-button>
        <el-button @click="close">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'TableInputSearchWindow',
    data() {
      return {
        tableInputWindow: false,
        filterText: '',
        data: [
          {
            id: 1,
            label: '全部',
            children: [
              {
                id: 11,
                label: '一级 1',
                children: [
                  {
                    id: 4,
                    label: '二级 1-1',
                    children: [
                      {
                        id: 9,
                        label: '三级 1-1-1',
                      },
                      {
                        id: 10,
                        label: '三级 1-1-2',
                      },
                    ],
                  },
                ],
              },
              {
                id: 2,
                label: '一级 2',
                children: [
                  {
                    id: 5,
                    label: '二级 2-1',
                  },
                  {
                    id: 6,
                    label: '二级 2-2',
                  },
                ],
              },
              {
                id: 3,
                label: '一级 3',
                children: [
                  {
                    id: 7,
                    label: '二级 3-1',
                  },
                  {
                    id: 8,
                    label: '二级 3-2',
                  },
                ],
              },
            ],
          },
        ],
        defaultProps: {
          children: 'children',
          label: 'label',
        },
        checked: false,
        // 表格
        list: [
          {
            id: 1,
            goods_name: 'XXX超市',
            code: '',
            classes: '酒水1',
            brand: '',
            inventory: '****',
            units: '1箱12听',
            status: '正常',
          },
          {
            id: 1,
            goods_name: 'XXX超市123',
            code: '',
            classes: '酒水1111',
            brand: '',
            inventory: '****',
            units: '1箱12听',
            status: '1正常1',
          },
          {
            id: 1,
            goods_name: 'XXX烟酒',
            code: '',
            classes: '酒水221',
            brand: '',
            inventory: '****',
            units: '1箱12听',
            status: '正常',
          },
        ],
      }
    },
    watch: {
      // filterText(val) {
      //   this.$refs.tree.filter(val)
      // },
    },
    methods: {
      close() {
        this.tableInputWindow = false
        // this.$refs['addRowFrom'].resetFields() // 重置表单
      },
      treeClick(val) {
        console.log(val)
      },
      save() {
        this.tableInputWindow = false
      },
      filter() {
        this.$refs.tree.filter(this.filterText)
      },
      filterNode(value, data) {
        if (!value) return true
        return data.label.indexOf(value) !== -1
      },
      setSelectRows() {},
      cellClick() {},
    },
  }
</script>

<style lang="scss" scoped>
  .tit {
    padding: 0 0 10px 0;
    text-align: center;
  }
</style>
