<template>
  <div class="wrapper">
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="选择客户"
      top="15vh"
      :visible.sync="inputWindow"
      width="1250px"
      @close="close"
    >
      <el-form
        ref="form"
        label-width="80px"
        :inline="true"
        style="margin-bottom: 10px"
      >
        <el-form-item>
          <el-input
            v-model="filterText"
            placeholder="输入门店,编号"
            style="width: 250px; margin: 0px 10px 0px 0"
          ></el-input>
        </el-form-item>
        <el-form-item label="片区">
          <el-cascader v-model="area" :options="areaOpt"></el-cascader>
        </el-form-item>
        <el-form-item label="渠道" style="margin: 0 0 0 10px">
          <el-select v-model="qudao" placeholder="请选择">
            <el-option
              v-for="item in qudaoList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button style="margin: 0 10px" @click="filter">查询</el-button>
        </el-form-item>
        <!-- <el-form-item>
          <el-button @click="add">添加</el-button>
        </el-form-item> -->
      </el-form>

      <el-table
        stripe
        :data="list"
        height="310"
        @selection-change="setSelectRows"
        @cell-click="cellClick"
      >
        <!-- 选择框 -->
        <el-table-column
          align="center"
          type="selection"
          width="60"
        ></el-table-column>
        <!-- 序号 -->
        <el-table-column prop="" align="center" label="序号" min-width="50px">
          <template #default="{ $index }">
            <span>{{ $index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="goods_name"
          align="center"
          label="商品名称"
          min-width="auto"
        ></el-table-column>
        <el-table-column
          prop="code"
          align="center"
          label="小单位条码"
          min-width="120px"
        ></el-table-column>
        <el-table-column
          prop="classes"
          align="center"
          label="类别"
          min-width="auto"
        ></el-table-column>
        <el-table-column
          prop="brand"
          align="center"
          label="品牌"
          min-width="auto"
        ></el-table-column>
        <el-table-column
          prop="inventory"
          align="center"
          label="可用库存"
          min-width="auto"
        ></el-table-column>
        <el-table-column
          prop="units"
          align="center"
          label="单位换算"
          min-width="auto"
        ></el-table-column>
        <el-table-column
          prop="status"
          align="center"
          label="状态"
          min-width="auto"
        ></el-table-column>
      </el-table>
      <!-- 底部按钮 -->
      <div slot="footer" class="dialog-footer">
        <!-- <el-button type="primary" @click="save">确认</el-button> -->
        <el-button @click="close">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'InputSearchWindow',
    data() {
      return {
        inputWindow: false,
        filterText: '',
        area: [],
        areaOpt: [
          {
            label: '全部',
            value: '全部',
            children: [
              {
                label: 'A地区',
                value: 'A地区',
                children: [
                  {
                    label: 'A地区子区域1',
                    value: 'A地区子区域1',
                  },
                  {
                    label: 'A地区子区域2',
                    value: 'A地区子区域2',
                  },
                ],
              },
              {
                label: 'B地区',
                value: 'B地区',
                children: [
                  {
                    label: 'B地区子区域1',
                    value: 'B地区子区域1',
                  },
                  {
                    label: 'B地区子区域2',
                    value: 'B地区子区域2',
                  },
                  {
                    label: 'B地区子区域3',
                    value: 'B地区子区域3',
                  },
                ],
              },
              {
                label: 'C地区',
                value: 'C地区',
                children: [
                  {
                    label: 'C地区子区域1',
                    value: 'C地区子区域1',
                  },
                  {
                    label: 'C地区子区域2',
                    value: 'C地区子区域2',
                  },
                  {
                    label: 'C地区子区域3',
                    value: 'C地区子区域3',
                  },
                ],
              },
            ],
          },
        ],
        qudao: '',
        qudaoList: [
          {
            value: 'A渠道',
            label: 'A渠道',
          },
          {
            value: 'B渠道',
            label: 'B渠道',
          },
          {
            value: 'C渠道',
            label: 'C渠道',
          },
        ],

        // 表格
        list: [
          {
            id: 1,
            goods_name: 'XXX超市',
            code: '',
            classes: '酒水1',
            brand: '',
            inventory: '****',
            units: '1箱12听',
            status: '正常',
          },
          {
            id: 1,
            goods_name: 'XXX超市123',
            code: '',
            classes: '酒水1111',
            brand: '',
            inventory: '****',
            units: '1箱12听',
            status: '1正常1',
          },
          {
            id: 1,
            goods_name: 'XXX烟酒',
            code: '',
            classes: '酒水221',
            brand: '',
            inventory: '****',
            units: '1箱12听',
            status: '正常',
          },
        ],
      }
    },
    watch: {
      // filterText(val) {
      //   this.$refs.tree.filter(val)
      // },
      inputWindow(val) {
        if (!val) {
        }
      },
    },
    methods: {
      close() {
        this.inputWindow = false
        // this.$refs['addRowFrom'].resetFields() // 重置表单
      },
      treeClick(val) {
        console.log(val)
      },
      // save() {
      //   this.inputWindow = false
      // },
      filter() {
        // this.$refs.tree.filter(this.filterText)
        console.log(111)
      },
      filterNode(value, data) {
        if (!value) return true
        return data.label.indexOf(value) !== -1
      },
      setSelectRows() {},
      cellClick(row) {
        console.log(row)
        this.$emit('change-client', row)
        this.inputWindow = false
      },
      add() {},
    },
  }
</script>

<style lang="scss" scoped>
  .tit {
    padding: 0 0 10px 0;
    text-align: center;
  }
</style>
