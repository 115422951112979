import { render, staticRenderFns } from "./index.vue?vue&type=template&id=3f446310&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=3f446310&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f446310",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/.jenkins/workspace/sd4-test-admin/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3f446310')) {
      api.createRecord('3f446310', component.options)
    } else {
      api.reload('3f446310', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=3f446310&scoped=true&", function () {
      api.rerender('3f446310', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/mall/goods/index.vue"
export default component.exports