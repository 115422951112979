<!--
 * @Author: your name
 * @Date: 2020-12-29 18:58:42
 * @LastEditTime: 2020-12-29 19:19:29
 * @LastEditors: Please set LastEditors
 * @Description: 车存核对
 * @FilePath: \sd-vue-admin\src\views\project\sale\control\sale\components\components\Check.vue
-->
<template>
  <div>
    <h5>车存变化：</h5>
    <el-table stripe :data="chelists.mx_list" style="width: 100%">
      <el-table-column
        label="品牌"
        align="center"
        prop="brand_name"
        width="120"
      ></el-table-column>
      <el-table-column
        label="商品名称"
        align="center"
        prop="goods_name"
        width="120"
      ></el-table-column>
      <el-table-column
        label="上次车存"
        align="center"
        prop="last_num"
        width="120"
      ></el-table-column>
      <el-table-column
        label="调入"
        align="center"
        prop="in_num"
        width="120"
      ></el-table-column>
      <el-table-column
        label="客户退货"
        align="center"
        prop="return_num"
        width="120"
      ></el-table-column>
      <el-table-column
        label="调出"
        align="center"
        prop="out_num"
        width="120"
      ></el-table-column>
      <el-table-column
        label="还货"
        align="center"
        prop="pre_goods_num"
        width="120"
      ></el-table-column>
      <el-table-column
        label="预存款"
        align="center"
        prop="pre_num"
        width="120"
      ></el-table-column>
      <el-table-column
        label="兑奖"
        align="center"
        prop="prize_num"
        width="120"
      ></el-table-column>
      <el-table-column
        label="赠送"
        align="center"
        prop="gift_num"
        width="120"
      ></el-table-column>
      <el-table-column
        label="销售"
        align="center"
        prop="sale_num"
        width="120"
      ></el-table-column>
      <el-table-column
        label="应剩车存"
        align="center"
        prop="left_num"
        width="120"
      ></el-table-column>
      <el-table-column
        label="当前车存"
        align="center"
        prop="now_num"
        width="120"
      ></el-table-column>
    </el-table>
  </div>
</template>

<script>
  export default {
    props: {
      chelists: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {}
    },
    methods: {},
  }
</script>

<style></style>
