var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { modal: false, visible: _vm.show, width: "70%" },
          on: {
            "update:visible": function ($event) {
              _vm.show = $event
            },
          },
        },
        [
          _c(
            "el-descriptions",
            [
              _c("el-descriptions-item", { attrs: { label: "客户名称" } }, [
                _vm._v(" " + _vm._s(_vm.queryForm.cust_name) + " "),
              ]),
              _c("el-descriptions-item", { attrs: { label: "联系人" } }, [
                _vm._v(" " + _vm._s(_vm.queryForm.boss) + " "),
              ]),
              _c("el-descriptions-item", { attrs: { label: "电话" } }, [
                _vm._v(" " + _vm._s(_vm.queryForm.mobile) + " "),
              ]),
              _c("el-descriptions-item", { attrs: { label: "归属业务员" } }, [
                _vm._v(" " + _vm._s(_vm.queryForm.user_name) + " "),
              ]),
              _c("el-descriptions-item", { attrs: { label: "时间" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.queryForm.start_time) +
                    "至" +
                    _vm._s(_vm.queryForm.end_time) +
                    " "
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tabledata },
            },
            _vm._l(_vm.columns, function (list, index) {
              return _c("el-table-column", {
                key: index,
                attrs: {
                  prop: list.prop,
                  label: list.label,
                  width: list.width,
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          list.label == "内容/单号"
                            ? _c("div", [
                                _c(
                                  "span",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlerinfo(row)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(row[list.prop]) + " ")]
                                ),
                              ])
                            : _c("div", [
                                _c("span", [_vm._v(_vm._s(row[list.prop]))]),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.queryForm.pageNo,
              layout: _vm.layout,
              "page-size": _vm.queryForm.pageSize,
              total: _vm.total,
              background: "",
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
          _c("check-order", { ref: "checkOrder" }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handlerExpotr } }, [
                _vm._v("导出"),
              ]),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.show = !_vm.show
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }