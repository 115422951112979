<template>
  <div class="export-excel-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="24">
        <el-form :inline="true" @submit.native.prevent>
          <el-form-item label="文件名">
            <el-input v-model="filename" placeholder="请输出要导出文件的名称" />
          </el-form-item>
          <el-form-item label="文件类型">
            <el-select v-model="bookType">
              <el-option
                v-for="item in options"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="自动列宽">
            <el-radio-group v-model="autoWidth">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="handleDownload">
              导出 Excel
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-left-panel>
    </vab-query-form>

    <el-table v-loading="listLoading" stripe :data="list">
      <el-table-column align="center" label="序号" width="55">
        <template #default="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="客户名称">
        <template #default="{ row }">
          {{ row.cust_name }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="时间">
        <template #default="{ row }">
          <span>{{ row.create_at }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="手机号">
        <template #default="{ row }">
          {{ row.mobile }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="用户名">
        <template #default="{ row }">
          <el-input v-model="row.user_name"></el-input>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import { getList } from '@/api/table'

  export default {
    name: 'ExportExcel',
    data() {
      return {
        list: [],
        listLoading: true,
        downloadLoading: false,
        filename: '导出文件名',
        autoWidth: true,
        bookType: 'xlsx',
        options: ['xlsx', 'csv', 'txt'],
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      fetchData() {
        this.listLoading = true
        getList().then((response) => {
          console.log(response)
          this.list = response.data.rows
          this.listLoading = false
        })
      },
      handleDownload() {
        this.downloadLoading = true
        import('@/utils/excel').then((excel) => {
          console.log(excel)
          const tHeader = ['序号', '客户名称', '时间', '手机号', '用户名']
          const filterVal = ['cust_name', 'create_at', 'mobile', 'user_name']
          const list = this.list
          const data = this.formatJson(filterVal, list)

          console.log(data)
          excel.export_json_to_excel({
            header: tHeader,
            data,
            filename: this.filename,
            autoWidth: this.autoWidth,
            bookType: this.bookType,
          })
          this.downloadLoading = false
        })
      },
      formatJson(filterVal, jsonData) {
        return jsonData.map((v) => {
          return filterVal.map((j) => {
            return v[j]
          })
        })
      },
    },
  }
</script>
