<template>
  <el-dialog
    :modal="false"
    title="提成明细"
    :visible.sync="showDialog"
    width="900px"
    top="14vh"
    :close-on-click-modal="false"
    destroy-on-close
    center
  >
    <div v-loading="loading" class="orderTest-container">
      <el-form
        ref="form"
        :model="form"
        inline
        label-position="right"
        label-width="100px"
      >
        <el-form-item label="姓名:" prop="user_name" style="width: 40%">
          <el-input v-model="form.user_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="累计提成:" prop="total" style="width: 40%">
          <el-input v-model="form.total" disabled></el-input>
        </el-form-item>
        <el-form-item label="电话:" prop="mobile" style="width: 40%">
          <el-input v-model="form.mobile" disabled></el-input>
        </el-form-item>
        <el-form-item label="累计已发放:" prop="yes_total" style="width: 40%">
          <el-input v-model="form.yes_total" disabled></el-input>
        </el-form-item>
        <el-form-item label="部门:" prop="depart_name" style="width: 40%">
          <el-input v-model="form.depart_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="未发放:" prop="no_total" style="width: 40%">
          <el-input v-model="form.no_total" disabled></el-input>
        </el-form-item>
        <el-form-item label="方案:" prop="title" style="width: 40%">
          <el-input v-model="form.title" disabled></el-input>
        </el-form-item>
        <el-form-item label="提成期数" prop="">
          <el-select
            v-model="phase_value"
            clearable
            style="width: 194px"
            placeholder="提成期数"
            @change="selectChange"
          >
            <el-option
              v-for="(i, idx) in phaseList"
              :key="idx"
              :value="i.key"
              :label="i.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="table">
        <el-table v-loading="loading2" stripe :data="list" height="400px">
          <el-table-column
            v-for="(item, tableIndex) in finallyColumns"
            :key="tableIndex"
            :prop="item.prop"
            :label="item.label"
            width=""
            align="center"
          ></el-table-column>
        </el-table>
      </div>
      <el-form
        ref="form2"
        :model="form2"
        inline
        label-position="right"
        label-width="80px"
      >
        <el-form-item label="本次发放:" prop="amount">
          <el-input v-model="form2.amount" style="width: 194px"></el-input>
        </el-form-item>
        <div></div>
        <el-form-item label="发放账户:" prop="payment_account">
          <el-select
            v-model="form2.payment_account"
            clearable
            style="width: 194px"
            placeholder="发放账户"
          >
            <el-option
              v-for="(i, idx) in selectList2"
              :key="idx"
              :value="i.id"
              :label="i.name"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <!-- 底部按钮 -->
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button type="primary" @click="sub">提交</el-button>

      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import _ from 'lodash'
  import { accountingDetail, accountingList, submit } from '@/api/performance'
  export default {
    name: '',
    components: {},
    data() {
      return {
        phaseList: [],
        selectList2: [],
        phase_value: '',
        loading: false,
        loading2: false,
        id: 0,
        showDialog: false,
        form: {},
        form2: {
          amount: '',
          payment_account: '',
        },
        list: [],
        checkList: ['销售单号', '订单金额', '提成', '时间'],
        columns: [
          {
            order: 1,
            label: '销售单号',
            prop: 'code',
            width: '',
          },
          {
            order: 2,
            label: '订单金额',
            prop: 'order_money',
            width: '',
          },
          {
            order: 3,
            label: '提成',
            prop: 'money',
            width: '',
          },
          {
            order: 4,
            label: '时间',
            prop: 'order_time',
            width: '',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      showDialog(val) {
        if (val) {
          this.fetchData()
        } else {
          this.phase_value = ''
          this.$refs.form.resetFields()
          this.$refs.form2.resetFields()
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data } = await accountingDetail({ user_id: this.id })
        console.log(data)
        this.form = data.info
        this.phaseList = data.phase_info
        // 发放账号下拉
        this.selectList2 = data.payment_account
        this.list = data.bill_list.lists
        this.loading = false
      },
      // 下拉改变
      selectChange(val) {
        this.loading2 = true
        console.log(val)
        accountingList({
          comm_id: this.id,
          phase_value: val,
        }).then((res) => {
          console.log(res)
          this.list = res.data
          this.loading2 = false
        })
      },
      close() {
        this.showDialog = false
      },
      sub() {
        submit({
          user_id: this.id,
          amount: this.form2.amount,
          payment_account: this.form2.payment_account,
        }).then((res) => {
          this.$message.success(res.msg)
          this.close()
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .table {
    margin-bottom: 15px;
  }
</style>
