var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "100px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "付款账户", prop: "payAccount" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.payAccount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "payAccount", $$v)
                  },
                  expression: "form.payAccount",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "收款账户", prop: "gatheringAccount" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.gatheringAccount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "gatheringAccount", $$v)
                  },
                  expression: "form.gatheringAccount",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "收款人姓名", prop: "gatheringName" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.gatheringName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "gatheringName", $$v)
                  },
                  expression: "form.gatheringName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "转账金额", prop: "price" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.price,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "price", $$v)
                  },
                  expression: "form.price",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pay-button-group" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
            [_vm._v("下一步")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }