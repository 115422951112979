var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "player-container" },
    [
      _c("el-alert", {
        attrs: {
          closable: false,
          title: "m3u8、flv演示环境暂不支持，请在正式环境查看效果",
          "show-icon": "",
          type: "success",
        },
      }),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { lg: 12, md: 24, sm: 24, xl: 12, xs: 24 } },
            [
              _c(
                "el-card",
                { attrs: { shadow: "hover" } },
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _vm._v("播放传统MP4"),
                  ]),
                  _c("vab-player-mp4", {
                    attrs: { config: _vm.config1 },
                    on: {
                      player: function ($event) {
                        _vm.Player1 = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 12, md: 24, sm: 24, xl: 12, xs: 24 } },
            [
              _c(
                "el-card",
                { attrs: { shadow: "hover" } },
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _vm._v("播放m3u8，且不暴露视频地址"),
                  ]),
                  _c("vab-player-hls", {
                    attrs: { config: _vm.config2 },
                    on: {
                      player: function ($event) {
                        _vm.Player2 = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }