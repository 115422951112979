var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-container" },
    [
      _c("el-alert", {
        attrs: {
          closable: false,
          title: "演示环境可能无法模拟上传",
          "show-icon": "",
          type: "success",
        },
      }),
      _c("vab-upload", {
        ref: "vabUpload",
        attrs: { limit: 50, size: 2, name: "file", url: "/upload" },
      }),
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              return _vm.handleShow({ key: "value" })
            },
          },
        },
        [_vm._v(" 模拟上传 ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }