<template>
  <div class="test-container">
    <el-button @click="handleLink1">params无问号传参</el-button>
    <el-button @click="handleLink2">query有问号传参</el-button>

    <el-button @click="handleLink3">有参数的设计器</el-button>

    <el-button @click="handleLink4">弹窗设计器</el-button>
    <el-button @click="handleLink5">弹窗打印预览</el-button>
    <p>{{ user_name }}</p>
    <el-input v-model="user_name"></el-input>
    <ruilang-design-dialog
      ref="RuilangDesignDialog"
      :add="ruilangAdd"
      @design-save-callback="designCallback"
    ></ruilang-design-dialog>
    <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      :bill_type="bill_type"
      :type="1"
      :data_id="data_id"
    ></ruilang-display-dialog>
  </div>
</template>
<script>
  import { uuid } from '@/utils'
  import RuilangDesignDialog from '@/baseComponents/ruiLangDesign/ruilangDesignDialog'
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'
  export default {
    name: 'Test',
    components: {
      RuilangDisplayDialog,
      RuilangDesignDialog,
    },
    data() {
      return {
        user_name: 'xxx',
        ruilangAdd: {},
        bill_type: 'XD',
        data_id: 1117,
      }
    },
    methods: {
      handleLink1() {
        this.$router.push(`/vab/dynamicSegment/test1/${uuid()}`)
      },
      handleLink2() {
        this.$router.push(`/vab/dynamicSegment/test2?id=${uuid()}`)
      },
      handleLink3() {
        this.$router.push(
          `/ruilangDisplay?params={xx:'aa',bb:'cc'}&bill_type=XD&type=1&cust_id=xx&data_id=1182`
        )
      },
      designCallback() {
        alert(1)
      },

      handleLink5() {
        this.$refs.RuilangDisplayDialog.dialogFormVisible = true
      },

      handleLink4() {
        this.ruilangAdd = {
          tpl_id: 1,
          depart_id: 1,
        }
        this.$refs.RuilangDesignDialog.dialogFormVisible = true
      },
    },
  }
</script>
