<!--
 * @Author: 候怀烨
 * @Date: 2020-12-29 09:36:54
 * @LastEditTime: 2021-02-24 15:33:27
 * @LastEditors: Please set LastEditors
 * @Description: 对账
 * @FilePath: \sd-vue-admin\src\views\project\sale\control\sale\components\Reconciliation.vue
-->
<template>
  <div>
    <el-dialog
      :modal="false"
      :title="title"
      :visible.sync="dialogVisible"
      center
      width="95%"
      style="margin-top: -10vh"
    >
      <div>
        <el-row :gutter="20">
          <!-- <span>对账时间段：{{ 2020 - 12 - 29 }}</span>
          <span style="padding: 0px 20px">——</span>
          <el-date-picker
            v-model="time"
            style="width: 190px"
            type="datetime"
            placeholder="选择日期时间"
          ></el-date-picker>

          <el-button type="primary" style="margin-left: 20px">
            更新数据
          </el-button> -->
          <el-button type="primary">打印</el-button>
          <el-button type="primary">导出</el-button>
          <el-button type="primary" @click="dialogVisible = false">
            返回对账列表
          </el-button>
          <el-date-picker
            v-model="endTime"
            type="date"
            placeholder="选择日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-row>

        <el-row style="margin-top: 40px">
          <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="对账交账" name="first">
              <account-reconciliation
                ref="reconciliation"
                :account="account"
                :row="rowdata"
                :paydata="pay_data"
              ></account-reconciliation>
            </el-tab-pane>
            <el-tab-pane label="单据详情" name="second">
              <document-details :list="list"></document-details>
            </el-tab-pane>
            <el-tab-pane label="车存核对" name="third">
              <check :chelists="chelist"></check>
            </el-tab-pane>
            <el-tab-pane label="销售明细" name="fourth">
              <sales-details :sales="sales"></sales-details>
            </el-tab-pane>
            <el-tab-pane label="派送明细" name="5">
              <delivery-details :paisonglist="paisonglist"></delivery-details>
            </el-tab-pane>
          </el-tabs>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="success" @click="handlersave">保 存</el-button>
        <el-button type="primary" @click="handlerwancheng">完成入账</el-button>
        <el-button type="info" @click="dialogVisible = false">
          对账作废
        </el-button>
        <el-button @click="dialogVisible = false">返回列表</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { dateForm } from '@/utils/Time'
  import { postAction } from '@/api/Employee'
  import AccountReconciliation from './components/AccountReconciliation.vue'
  import DocumentDetails from './components/DocumentDetails.vue'
  import Check from './components/Check.vue'
  import DeliveryDetails from './components/DeliveryDetails.vue'
  import SalesDetails from './components/SalesDetails.vue'
  export default {
    components: {
      AccountReconciliation,
      DocumentDetails,
      Check,
      DeliveryDetails,
      SalesDetails,
    },
    props: {
      row: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        endTime: '',
        title: '对账明细',
        dialogVisible: false,
        time: dateForm(new Date()),
        activeName: 'first',
        url: {
          duizhang: '/saleAdmin/duizhang/un-base-detail',
          // fanghsi: '/promoteAdmin/deposit-order/pay-type',
          fanghsi: '/baseAdmin/common/account',
        },
        account: {},
        //------
        labelPosition: 'right',
        rowdata: {},
        list: {},
        chelist: {},
        paisonglist: {},
        sales: {},
        show: '',
        pay_data: [],
        duizhangID: '',
      }
    },
    watch: {
      show(val) {
        if (val == 2) {
        }
      },
      dialogVisible(val) {
        if (val == false) {
          this.$emit('handlersuaxin')
          this.duizhangID = ''
          this.$refs.reconciliation.tableData = [
            {
              id: '',
              money: '',
              add_time: dateForm(new Date()),
            },
          ]
        }
      },
    },
    methods: {
      //完成对账
      handlerwancheng() {
        var data = {
          user_id: this.rowdata.id,
          end_time: this.time,
          pay_data: JSON.stringify(this.$refs.reconciliation.tableData),
        }
        postAction('/saleAdmin/duizhang/add', data)
          .then((res) => {
            this.duizhangID = res.data.id
            this.ruzhang()
          })
          .catch((err) => {})
      },
      ruzhang() {
        postAction('/saleAdmin/duizhang/finish', {
          duizhang_id: this.duizhangID,
        })
          .then((res) => {})
          .catch((err) => {})
        this.dialogVisible = false
      },
      // 保存
      handlersave() {
        console.log(this.$refs.reconciliation.tableData)
        var data = {
          user_id: this.rowdata.id,
          end_time: this.time,
          pay_data: JSON.stringify(this.$refs.reconciliation.tableData),
        }
        postAction('/saleAdmin/duizhang/add', data)
          .then((res) => {
            cosnole.log(res)
            this.duizhangID = res.data.id
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleClick(tab) {
        console.log(tab.label)
        if (tab.label == '单据详情') {
          this.duizhang()
        } else if (tab.label == '车存核对') {
          this.handlerche()
        } else if (tab.label == '销售明细') {
          this.handlersales()
        } else if (tab.label == '派送明细') {
          this.handlerpaisong()
        }
      },
      //对账的入账列表
      handlerruzhang() {
        postAction('/saleAdmin/duizhang/ruzhang-list', {
          duizhang_id: this.rowdata.duizhang_id,
        })
          .then((res) => {
            console.log(res, '')
            this.$refs.reconciliation.handlerlist(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
      },
      //派送明细
      handlerpaisong() {
        if (this.show == 1) {
          postAction('/saleAdmin/duizhang/un-send-detail', {
            user_id: this.rowdata.id,
            end_time: this.time,
          })
            .then((res) => {
              console.log(res, '')
              this.paisonglist = res.data
            })
            .catch((err) => {
              console.log(err, '')
            })
        } else {
          postAction('/saleAdmin/duizhang/send-detail', {
            duizhang_id: this.rowdata.duizhang_id,
          })
            .then((res) => {
              console.log(res, '')
              this.paisonglist = res.data
            })
            .catch((err) => {
              console.log(err, '')
            })
        }
      },
      //销售明细
      handlersales() {
        if (this.show == 1) {
          postAction('/saleAdmin/duizhang/un-sale-detail', {
            user_id: this.rowdata.id,
            end_time: this.time,
          })
            .then((res) => {
              console.log(res, '')
              this.sales = res.data
            })
            .catch((err) => {
              cosnole.log(err)
            })
        } else {
          postAction('/saleAdmin/duizhang/sale-detail', {
            duizhang_id: this.rowdata.duizhang_id,
          })
            .then((res) => {
              console.log(res, '')
              this.sales = res.data
            })
            .catch((err) => {
              cosnole.log(err)
            })
        }
      },
      //车存核对
      handlerche() {
        if (this.show == 1) {
          postAction('/saleAdmin/duizhang/un-car-detail', {
            user_id: this.rowdata.id,
            end_time: this.time,
          })
            .then((res) => {
              console.log(res, '')
              this.chelist = res.data
            })
            .catch((err) => {
              console.log(err, '')
            })
        } else {
          postAction('/saleAdmin/duizhang/car-detail', {
            duizhang_id: this.rowdata.duizhang_id,
          })
            .then((res) => {
              console.log(res, '')
              this.chelist = res.data
            })
            .catch((err) => {
              console.log(err, '')
            })
        }
      },
      //对账单单据详情
      duizhang() {
        if (this.show == 1) {
          postAction('/saleAdmin/duizhang/un-bill-detail', {
            user_id: this.rowdata.id,
            end_time: this.time,
          })
            .then((res) => {
              console.log(res, '对账单单据详情')
              this.list = res.data
            })
            .catch((err) => {
              console.log(err, '')
            })
        } else {
          postAction('/saleAdmin/duizhang/bill-detail', {
            duizhang_id: this.rowdata.duizhang_id,
          })
            .then((res) => {
              console.log(res, '对账单单据详情')
              this.list = res.data
            })
            .catch((err) => {
              console.log(err, '')
            })
        }
      },
      handlerData(data, row, dui) {
        this.account = data
        this.rowdata = row
        this.show = dui
        postAction(this.url.fanghsi, {})
          .then((res) => {
            // this.$refs.reconciliation.handlertype(res.data)
            this.pay_data = res.data
          })
          .catch((err) => {})
        if (dui == 2) {
          this.handlerruzhang()
        }
      },
    },
  }
</script>

<style scoped>
  .text {
    text-align: center;
  }
  .icon {
    font-size: 30px;
    color: #9bf804;
  }
</style>
