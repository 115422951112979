var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h5", [_vm._v(_vm._s(_vm.list.receiv_bk[0].type_text))]),
      _c(
        "el-table",
        {
          staticStyle: { width: "50%" },
          attrs: { stripe: "", data: _vm.list.receiv_bk[0].list },
        },
        [
          _c("el-table-column", {
            attrs: { label: "客户名称", align: "center", prop: "cust_name" },
          }),
          _c("el-table-column", {
            attrs: { label: "单号", align: "center", prop: "order_ori_code" },
          }),
          _c("el-table-column", {
            attrs: { label: "应收", align: "center", prop: "receiv_money" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "收款",
              align: "center",
              prop: "real_receiv_money",
            },
          }),
        ],
        1
      ),
      _c("h5", [_vm._v(_vm._s(_vm.list.receiv_bk[1].type_text))]),
      _c(
        "el-table",
        {
          staticStyle: { width: "50%" },
          attrs: { stripe: "", data: _vm.list.receiv_bk[1].list },
        },
        [
          _c("el-table-column", {
            attrs: { label: "客户名称", align: "center", prop: "cust_name" },
          }),
          _c("el-table-column", {
            attrs: { label: "单号", align: "center", prop: "order_ori_code" },
          }),
          _c("el-table-column", {
            attrs: { label: "应收", align: "center", prop: "receiv_money" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "收款",
              align: "center",
              prop: "real_receiv_money",
            },
          }),
        ],
        1
      ),
      _c("h5", [_vm._v(_vm._s(_vm.list.receiv_bk[2].type_text))]),
      _c(
        "el-table",
        {
          staticStyle: { width: "50%" },
          attrs: { stripe: "", data: _vm.list.receiv_bk[2].list },
        },
        [
          _c("el-table-column", {
            attrs: { label: "客户名称", align: "center", prop: "cust_name" },
          }),
          _c("el-table-column", {
            attrs: { label: "单号", align: "center", prop: "order_ori_code" },
          }),
          _c("el-table-column", {
            attrs: { label: "应收", align: "center", prop: "receiv_money" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "收款",
              align: "center",
              prop: "real_receiv_money",
            },
          }),
        ],
        1
      ),
      _c("h5", [_vm._v(_vm._s(_vm.list.receiv_bk[3].type_text))]),
      _c(
        "el-table",
        {
          staticStyle: { width: "50%" },
          attrs: { stripe: "", data: _vm.list.receiv_bk[3].list },
        },
        [
          _c("el-table-column", {
            attrs: { label: "客户名称", align: "center", prop: "cust_name" },
          }),
          _c("el-table-column", {
            attrs: { label: "单号", align: "center", prop: "order_ori_code" },
          }),
          _c("el-table-column", {
            attrs: { label: "应收", align: "center", prop: "receiv_money" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "收款",
              align: "center",
              prop: "real_receiv_money",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }