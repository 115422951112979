<template>
  <div class="table-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          :model="queryForm"
          label-width="80px"
          @submit.native.prevent
        >
          <el-form-item>
            <el-input
              v-model="queryForm.title"
              prefix-icon="el-icon-search"
              placeholder="客户名称、简拼、电话"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQuery"
            >
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
      <vab-query-form-left-panel>
        <el-button icon="el-icon-plus" type="primary" @click="handleAdd">
          添加
        </el-button>
        <el-button icon="el-icon-delete" type="danger" @click="handleDelete">
          删除
        </el-button>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel>
        <el-popover popper-class="custom-table-checkbox" trigger="hover">
          <el-checkbox-group v-model="checkList">
            <el-checkbox
              v-for="(item, index) in columns"
              :key="index"
              :label="item.label"
            ></el-checkbox>
          </el-checkbox-group>
          <el-button slot="reference">
            <vab-remix-icon icon="settings-line" />
          </el-button>
        </el-popover>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      ref="tableSort"
      v-loading="listLoading"
      stripe
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column
        align="center"
        type="selection"
        width="55"
      ></el-table-column>
      <el-table-column
        v-for="(item, index) in finallyColumns"
        :key="index"
        align="center"
        :label="item.label"
        :sortable="item.sortable"
        width=""
      >
        <template #default="{ $index, row }">
          <span v-if="item.label === '序号'">{{ $index + 1 }}</span>
          <span v-else>{{ row[item.prop] }}</span>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        label="操作"
        fixed="right"
        show-overflow-tooltip
        width="190"
      >
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
          <el-button type="text" @click="handleSendOrder(row)">派单</el-button>
          <el-button type="text" @click="handleCancel(row)">取消</el-button>
          <el-button type="text" @click="handlePrint(row)">打印</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <table-edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import _ from 'lodash'
  import { doDelete, getList } from '@/api/table'
  import TableEdit from './TableEdit'
  import { getAdvanceOrderTableList } from '@/api/advanceOrder'

  export default {
    name: 'CustomTable',
    components: {
      TableEdit,
    },
    data() {
      return {
        checkList: [
          '序号',
          '单号',
          '客户名称',
          '来源',
          '出货仓库',
          '业务员',
          '送货人',
          '订单金额',
          '销售金额',
          '已收款',
          '状态',
          '下单时间',
          '打印数',
        ],
        columns: [
          {
            order: 1,
            label: '序号',
            width: '60',
            prop: '',
            sortable: false,
          },
          {
            order: 2,
            label: '单号',
            width: 'auto',
            prop: 'bill_code',
            sortable: false,
          },
          {
            order: 3,
            label: '客户名称',
            width: 'auto',
            prop: 'cust_name',
            sortable: false,
          },
          {
            order: 4,
            label: '来源',
            width: '100',
            prop: 'bill_source_text',
            sortable: false,
          },
          {
            order: 5,
            label: '出货仓库',
            width: '100',
            prop: 'depot_id',
            sortable: false,
          },
          {
            order: 6,
            label: '业务员',
            width: '90',
            prop: 'staff_id',
            sortable: false,
          },
          {
            order: 7,
            label: '送货人',
            width: '90',
            prop: 'deliver_id',
            sortable: false,
          },
          {
            order: 8,
            label: '订单金额',
            width: '100',
            prop: 'pay_amount',
            sortable: false,
          },
          {
            order: 9,
            label: '销售金额',
            width: '100',
            prop: 'sale_amount',
            sortable: false,
          },
          {
            order: 10,
            label: '已收款',
            width: '100',
            prop: 'total_amount',
            sortable: false,
          },
          {
            order: 11,
            label: '状态',
            width: '90',
            prop: 'check_status',
            sortable: false,
          },
          {
            order: 12,
            label: '下单时间',
            width: 'auto',
            prop: 'sale_time',
            sortable: false,
          },
          {
            order: 13,
            label: '打印数',
            width: '90',
            prop: 'print_num',
            sortable: false,
          },
        ],
        list: [],
        imageList: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          title: '',
        },
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    created() {
      getAdvanceOrderTableList().then((res) => {
        console.log(res)
      })
      this.fetchData()
    },
    mounted() {},
    methods: {
      setSelectRows(val) {
        this.selectRows = val
      },
      handleAdd() {
        this.$refs['edit'].showEdit()
      },
      handleEdit(row) {
        this.$refs['edit'].showEdit(row)
      },
      handleSendOrder(row) {
        console.log('派单', row)
        this.$refs['edit'].showEdit(row)
      },
      handlePrint(row) {
        console.log('打印', row)
        this.$refs['edit'].showEdit(row)
      },
      handleCancel(row) {
        console.log('取消派单', row)
        this.$refs['edit'].showEdit(row)
      },
      handleDelete(row) {
        if (row.id) {
          this.$baseConfirm('你确定要取消派单吗', null, async () => {
            const { msg } = await doDelete({ ids: row.id })
            this.$baseMessage(msg, 'success')
            await this.fetchData()
          })
        } else {
          if (this.selectRows.length > 0) {
            const ids = this.selectRows.map((item) => item.id).join()
            this.$baseConfirm('你确定要删除选中项吗', null, async () => {
              const { msg } = await doDelete({ ids: ids })
              this.$baseMessage(msg, 'success')
              await this.fetchData()
            })
          } else {
            this.$baseMessage('未选中任何行', 'error')
            return false
          }
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      handleQuery() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount } = await getAdvanceOrderTableList(
          this.queryForm
        )
        this.list = data
        const imageList = []
        data.forEach((item) => {
          imageList.push(item.img)
        })
        this.imageList = imageList
        this.total = totalCount
        this.listLoading = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  .custom-table-container {
    ::v-deep {
      i {
        cursor: pointer;
      }
    }
  }
</style>
<style lang="scss">
  .custom-table-checkbox {
    .el-checkbox {
      display: block !important;
      margin: 0 0 $base-padding/4 0;
    }
  }
</style>
