<template>
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="120px"
    >
      <el-form-item label="" prop="">
        <el-cascader
          v-model="form.area_id"
          :options="areaList"
          placeholder="选择区域"
          :props="{
            checkStrictly: true,
            value: 'id',
            label: 'name',
            emitPath: false,
          }"
          clearable
          style="width: 140px"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.channel_id"
          placeholder="选择渠道类型"
          :popper-class="'select-idx'"
          filterable
          clearable
          style="width: 140px"
        >
          <el-option
            v-for="item in cust_channel"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <client-search
          :popper-class="'select-idx selectDC'"
          @select-id="selectId"
          @select-val-input="custInput"
        ></client-search>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width: 240px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleExport">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      stripe
      :data="list"
      style="margin-top: 15px"
      show-summary
      :summary-method="getSummaries"
      :row-key="getRowKey"
      :default-sort="sortRule"
      @selection-change="setSelectRows"
      @sort-change="sortChange"
    >
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <!-- 选择框 -->
      <el-table-column
        align="center"
        :reserve-selection="true"
        type="selection"
        width="50"
      ></el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :sortable="item.sort"
        :column-key="item.prop"
        width=""
        align="center"
      ></el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>

<script>
  import { getSelect } from '@/api/saleOrder'
  import ClientSearch from '@/baseComponents/clientSearch'
  import { downloadFile, postAction } from '@/api/Employee'
  import _ from 'lodash'
  export default {
    name: 'Accountssummary',
    components: {
      ClientSearch,
    },
    data() {
      return {
        loading: false,
        form: {
          start_time: '',
          end_time: '',
          pageNo: 1,
          pageSize: 10,
          area_id: '',
          keyword: '',
          channel_id: '',
          cust_id: '',
        },
        time: [],
        areaList: [],
        cust_channel: [],
        url: {
          list: '/cashAdmin/cash/customer-account',
          export: '/cashAdmin/cash/customer-account-export',
        },
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        list: [],
        checkList: ['客户名称', '预存款', '预存货', '待兑付', '欠款'],
        columns: [
          {
            order: 1,
            label: '客户名称',
            prop: 'cust_name',
            width: '',
          },
          {
            order: 2,
            label: '预存款',
            prop: 'deposit_money',
            width: '',
            sort: 'custom',
          },
          {
            order: 3,
            label: '预存货',
            prop: 'goods_money',
            width: '',
            sort: 'custom',
          },
          {
            order: 4,
            label: '待兑付',
            prop: 'paid_money',
            width: '',
            sort: 'custom',
          },
          {
            order: 5,
            label: '欠款',
            prop: 'arrears_money',
            width: '',
            sort: 'custom',
          },
        ],
        selectRows: '',
        totalAll: [],
        sortRule: { prop: null, order: null },
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_date = val[0]
          this.form.end_date = val[1]
        } else {
          this.form.start_date = ''
          this.form.end_date = ''
        }
      },
    },
    mounted() {
      this.handlerInquire()
      getSelect().then((res) => {
        if (res.code == 200) {
          this.cust_channel = res.data.cust_channel
          this.areaList = res.data.cust_area
        }
      })
    },
    methods: {
      sortChange(row) {
        console.log(row)
        if (row.order == 'ascending') {
          var data = Object.assign(this.form, { order: 'asc', sort: row.prop })
          postAction('/cashAdmin/cash/customer-account', data).then((res) => {
            if (res.code == 200) {
              this.list = res.data.list
              this.total = res.totalCount
              this.totalAll = res.data.zong
            }
          })
        } else {
          var data = Object.assign(this.form, { order: 'desc', sort: row.prop })
          postAction('/cashAdmin/cash/customer-account', data).then((res) => {
            if (res.code == 200) {
              this.list = res.data.list
              this.total = res.totalCount
              this.totalAll = res.data.zong
            }
          })
        }
      },
      custInput(v) {
        this.form.keyword = v
      },
      selectId(val) {
        console.log('selectId')
        console.log(val)
        if (val.cust_id) {
          this.form.cust_id = val
          this.form.keyword = ''
        } else {
          this.form.cust_id = val
          this.form.keyword = ''
        }
      },
      handlerInquire() {
        this.form.pageNo = 1
        this.form.pageSize = 10
        this.loading = true
        this.handlerlist()
      },
      handlerlist() {
        postAction(this.url.list, this.form)
          .then((res) => {
            console.log(res, '打印')
            if (res.code == 200) {
              this.list = res.data.list
              this.total = res.totalCount
              this.totalAll = res.data.zong
            }
            this.loading = false
          })
          .catch((err) => {
            console.log(err, '錯誤')
          })
      },
      handleExport() {
        downloadFile(this.url.export, '客户账目汇总.xlsx', this.form)
      },
      getRowKey(row) {
        return row.cust_id
      },
      setSelectRows(val) {
        console.log(val)
        this.selectRows = val
      },
      getSummaries(param) {
        const { columns, data } = param
        let i = [3, 4, 5, 6]
        let sums = []
        sums[0] = '合计'
        sums[3] = this.totalAll.deposit
        sums[4] = this.totalAll.goods
        sums[5] = this.totalAll.paid
        sums[6] = this.totalAll.arrears
        return sums
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.handlerlist()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.handlerlist()
      },
    },
  }
</script>

<style scoped></style>
