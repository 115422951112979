var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.queryForm, "label-width": "80px", inline: "" },
        },
        [
          _c(
            "el-form-item",
            [
              _c("el-cascader", {
                staticStyle: { width: "140px" },
                attrs: {
                  options: _vm.areaList,
                  placeholder: "选择区域",
                  props: {
                    checkStrictly: true,
                    value: "id",
                    label: "name",
                    emitPath: false,
                  },
                  clearable: "",
                },
                model: {
                  value: _vm.queryForm.area_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryForm, "area_id", $$v)
                  },
                  expression: "queryForm.area_id",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: {
                    placeholder: "选择渠道类型",
                    "popper-class": "select-idx",
                    filterable: "",
                    clearable: "",
                  },
                  model: {
                    value: _vm.queryForm.channel_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryForm, "channel_id", $$v)
                    },
                    expression: "queryForm.channel_id",
                  },
                },
                _vm._l(_vm.cust_channel, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: {
                    placeholder: "等级",
                    "popper-class": "select-idx",
                    filterable: "",
                    clearable: "",
                  },
                  model: {
                    value: _vm.queryForm.class_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryForm, "class_id", $$v)
                    },
                    expression: "queryForm.class_id",
                  },
                },
                _vm._l(_vm.cust_class, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: {
                    filterable: "",
                    clearable: "",
                    "popper-class": "select-idx",
                    placeholder: "归属业务员",
                  },
                  model: {
                    value: _vm.queryForm.staff_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryForm, "staff_id", $$v)
                    },
                    expression: "queryForm.staff_id",
                  },
                },
                _vm._l(_vm.saleManList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("client-search", {
                attrs: { "popper-class": "select-idx selectDC" },
                on: {
                  "select-id": _vm.selectId,
                  "select-val-input": _vm.custInput,
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-date-picker", {
                attrs: {
                  "popper-class": "select-idx",
                  type: "datetimerange",
                  "range-separator": "-",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "default-time": ["00:00:00", "23:59:59"],
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-search",
                    "native-type": "submit",
                    type: "primary",
                  },
                  on: { click: _vm.btnQuery },
                },
                [_vm._v(" 查询 ")]
              ),
              _c("el-button", { on: { click: _vm.btnExpotr } }, [
                _vm._v("导出"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tabledata },
        },
        _vm._l(_vm.columns, function (list, index) {
          return _c("el-table-column", {
            key: index,
            attrs: {
              prop: list.prop,
              label: list.label,
              width: list.width,
              align: "center",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      list.label == "浏览量"
                        ? _c("div", [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "border-bottom": "solid 1px #000000",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handlerinfo(row)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(row[list.prop]) + " ")]
                            ),
                          ])
                        : _c("div", [
                            _c("span", [_vm._v(_vm._s(row[list.prop]))]),
                          ]),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.queryForm.pageNo,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          total: _vm.total,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("link-info", { ref: "info" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }