var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { overflow: "hidden" } },
    [
      _vm._l(_vm.list2, function (item, index) {
        return _c(
          "div",
          { key: index, staticStyle: { position: "relative" } },
          [
            _vm.isEdit
              ? _c("div", { staticClass: "icons" }, [
                  _c(
                    "i",
                    {
                      staticClass: "el-icon-document-copy",
                      on: {
                        click: function ($event) {
                          return _vm.copyPlan2(index)
                        },
                      },
                    },
                    [_vm._v("复制")]
                  ),
                  _c(
                    "i",
                    {
                      staticClass: "el-icon-close",
                      on: {
                        click: function ($event) {
                          return _vm.deletePlan2(index)
                        },
                      },
                    },
                    [_vm._v("删除")]
                  ),
                ])
              : _vm._e(),
            _c(
              "el-card",
              { attrs: { shadow: "hover" } },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { "margin-top": "15px" },
                    attrs: { stripe: "", data: item.goods_info },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        width: "220px",
                        prop: "goods_name",
                        label: "兑付商品",
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("goods-search", {
                                  ref: "goodsSearch",
                                  refInFor: true,
                                  attrs: {
                                    "f-key": row.goods_name,
                                    search: _vm.isEdit,
                                    disab: !_vm.isEdit,
                                  },
                                  on: {
                                    "select-goods-all": function ($event) {
                                      return _vm.selectGoods2(
                                        $event,
                                        row,
                                        index
                                      )
                                    },
                                    "add-rows": function ($event) {
                                      return _vm.addRows2($event, index)
                                    },
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "unit_id",
                        label: "单位",
                        width: "110px",
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100px" },
                                    attrs: {
                                      placeholder: "单位",
                                      disabled: !_vm.isEdit,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.unitChange2($event, row)
                                      },
                                    },
                                    model: {
                                      value: row.unit_id,
                                      callback: function ($$v) {
                                        _vm.$set(row, "unit_id", $$v)
                                      },
                                      expression: "row.unit_id",
                                    },
                                  },
                                  _vm._l(row.arr_unit, function (i, idx) {
                                    return _c("el-option", {
                                      key: idx,
                                      attrs: {
                                        value: i.id,
                                        label: i.unit_name,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "goods_price",
                        label: "价格",
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  " " + _vm._s(row.goods_price || 0) + " "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "number", label: "数量", align: "center" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("el-input", {
                                  attrs: {
                                    type: "number",
                                    placeholder: "数量",
                                    min: 0,
                                    disabled: !_vm.isEdit,
                                  },
                                  model: {
                                    value: row.number,
                                    callback: function ($$v) {
                                      _vm.$set(row, "number", $$v)
                                    },
                                    expression: "row.number",
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "", label: "金额", align: "center" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      Number(
                                        row.number * row.goods_price
                                      ).toFixed(2)
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "remark", label: "备注", align: "center" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "备注",
                                    disabled: !_vm.isEdit,
                                  },
                                  model: {
                                    value: row.remark,
                                    callback: function ($$v) {
                                      _vm.$set(row, "remark", $$v)
                                    },
                                    expression: "row.remark",
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm.isEdit
                      ? _c("el-table-column", {
                          attrs: { prop: "", label: "操作", align: "center" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var $index = ref.$index
                                  var row = ref.row
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        nativeOn: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.handleCopyRow2(
                                              $index,
                                              row,
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" 复制 ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        nativeOn: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.handleDeleteRow2(
                                              $index,
                                              row,
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" 删除 ")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-between",
                      margin: "10px 0",
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _vm._v(" 兑付现金: "),
                        _c("el-input", {
                          staticStyle: { width: "200px" },
                          attrs: { disabled: !_vm.isEdit },
                          model: {
                            value: item.total_amount,
                            callback: function ($$v) {
                              _vm.$set(item, "total_amount", $$v)
                            },
                            expression: "item.total_amount",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _vm._v(" 备注: "),
                        _c("el-input", {
                          staticStyle: { width: "200px" },
                          attrs: { disabled: !_vm.isEdit },
                          model: {
                            value: item.remark,
                            callback: function ($$v) {
                              _vm.$set(item, "remark", $$v)
                            },
                            expression: "item.remark",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  [
                    _vm._v(" 兑付日期: "),
                    _c("el-date-picker", {
                      staticStyle: { width: "200px" },
                      attrs: {
                        type: "date",
                        placeholder: "选择日期",
                        format: "yyyy 年 MM 月 dd 日",
                        "value-format": "yyyy-MM-dd",
                        disabled: !_vm.isEdit,
                      },
                      model: {
                        value: item.time_text,
                        callback: function ($$v) {
                          _vm.$set(item, "time_text", $$v)
                        },
                        expression: "item.time_text",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
      _vm.isEdit
        ? _c(
            "el-button",
            {
              staticStyle: { float: "right" },
              attrs: { type: "primary" },
              on: { click: _vm.add2 },
            },
            [_vm._v(" 添加新兑付 ")]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }