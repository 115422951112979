<template>
  <div class="roleManagement-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="12">
        <el-button icon="el-icon-plus" type="primary" @click="handleEdit">
          添加新角色
        </el-button>
      </vab-query-form-left-panel>
    </vab-query-form>

    <el-table v-loading="listLoading" stripe :data="list">
      <el-table-column
        align="center"
        label="角色ID"
        prop="authorityId"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        label="角色名称"
        prop="authorityName"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="220"
      >
        <template #default="{ row }">
          <el-button size="mini" type="primary" @click="handleAuth(row)">
            权限
          </el-button>
          <el-button size="mini" type="primary" @click="handleEdit(row)">
            编辑
          </el-button>
          <el-button size="mini" type="danger" @click="handleDelete(row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <edit ref="edit" @fetch-data="fetchData"></edit>

    <el-drawer
      v-if="drawer"
      :visible.sync="drawer"
      :with-header="false"
      size="40%"
      title="角色配置"
    >
      <el-tabs :before-leave="autoEnter" class="role-box" type="border-card">
        <el-tab-pane label="角色菜单">
          <Menus ref="menus" :row="activeRow" />
        </el-tab-pane>
      </el-tabs>
    </el-drawer>
  </div>
</template>

<script>
  import { doDelete, getList } from '@/api/roleManagement'
  import Edit from './components/RoleManagementEdit'
  import Menus from './components/menus'

  export default {
    name: 'RoleManagement',
    components: {
      Menus,
      Edit,
    },
    data() {
      return {
        drawer: false,
        activeRow: {},
        tableData: [],
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          role: '',
        },
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      handleAuth(row) {
        console.log(row)
        this.drawer = true
        this.activeRow = row
      },
      autoEnter(activeName, oldActiveName) {
        const paneArr = ['menus']
        if (oldActiveName) {
          if (this.$refs[paneArr[oldActiveName]].needConfirm) {
            this.$refs[paneArr[oldActiveName]].enterAndNext()
            this.$refs[paneArr[oldActiveName]].needConfirm = false
          }
        }
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleEdit(row) {
        if (row.id) {
          this.$refs['edit'].showEdit(row)
        } else {
          this.$refs['edit'].showEdit()
        }
      },
      handleDelete(row) {
        if (row.id) {
          this.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const { msg } = await doDelete({ ids: row.id })
            this.$baseMessage(msg, 'success')
            await this.fetchData()
          })
        } else {
          if (this.selectRows.length > 0) {
            const ids = this.selectRows.map((item) => item.id).join()
            this.$baseConfirm('你确定要删除选中项吗', null, async () => {
              const { msg } = await doDelete({ ids })
              this.$baseMessage(msg, 'success')
              await this.fetchData()
            })
          } else {
            this.$baseMessage('未选中任何行', 'error')
            return false
          }
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount } = await getList(this.queryForm)
        this.list = data
        this.total = totalCount
        this.listLoading = false
      },
    },
  }
</script>
